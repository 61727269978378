/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
// @ts-nocheck
import axios from 'axios';
import * as ACTION_TYPES from './action_types';
import * as API from '../../utils/Constants';
import { toast } from 'react-toastify';
import { axiosApiInstance } from '../../utils/apiConfig';
import { encryptCredentials, loadUser } from './authActions';


  export const showAllAdminAssistant = (close) => async (dispatch, getState) => {
    // 4790
    dispatch(loadUser());
    dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
    const requestTableData = getState().admin.searchParams;
      if (close) {
          close(false);
      }

    const paramsQuery = new URLSearchParams(requestTableData).toString();
    await axiosApiInstance
      .get(API.ALL_ADMINASSIST + '?' + paramsQuery, tokenConfig(getState))
      .then((res) => {
        let admin_data = [];
        let pagination = {};
        let response = [];
        res.data.data.map((res) => {
          admin_data.push({
            name: `${res.first_name} ${res.middle_name || ""} ${res.last_name} ${res.suffix || ""}`,
            username: res.username,
            id: res.id,
            email: res.email,
            user_type: res.user_type,
            status: res.status,
            active: res.active,
          });
        });

        pagination = res.data.pagination;
        response = { data: admin_data, pagination: pagination };
        dispatch({
          type: ACTION_TYPES.SHOW_ADMINASSIST,
          payload: response,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undefx
        console.log(err);
      });
    };


export const allAdmin = (close) => async (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  // eslint-disable-next-line no-undef

  await axiosApiInstance
    .get(API.ALL_ADMINASSIST + '?pageLimit=10&pageNum=1&showAll=true', tokenConfig(getState))
    .then((res) => {
      let admin_data = [];
      res.data.data.map((res) => {
        if (res.user_type === 2) {
         admin_data.push({
         name: `${res.first_name} ${res.middle_name || ""} ${res.last_name} ${res.suffix || ""}`,
         username: res.username,
         id: res.id,
         email: res.email,
         user_type: res.user_type,
         status: res.status,
         active: res.active,
         });
        }

      });

      dispatch({
        type: ACTION_TYPES.SHOW_ADMINASSIST,
        payload: admin_data,
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-undef
      console.log(err);
    });
};

export const getAdminDetails = (id, setOpen, showEdit) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .get(API.ADMINASSIST_DETAILS + '?' + `${id}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.ADMINASSIST_DETAILS,
        payload: response.data.data,
        onShowEdit: showEdit,
      });
      setOpen(true);
    })
    .catch((err) => {
      toast.error(err);
    });
};

export const addNewAdmin = (data, close, setOnLoadCircular) => async (dispatch, getState) => {
  // Request Body
  // 4790
  dispatch(loadUser());
    
  // 4592
  data.username = encryptCredentials(data.username);
  data.password = encryptCredentials(data.password);
  data.first_name = encryptCredentials(data.first_name);
  data.last_name = encryptCredentials(data.last_name);
  data.middle_name = encryptCredentials(data.middle_name);
  data.email = encryptCredentials(data.email);
  
  await axiosApiInstance
    .post(API.ADMINASSIST_REGISTER, data, tokenConfig(getState))
    .then((res) => {
      close(false, res.data.data._id)
      toast.success("Admin Assistant successfully added.");
    })
    .catch((err) => {
      setOnLoadCircular(false)
      toast.error(err.response.data.message);
    });
};

export const editAdmin = (data, close, setOnLoadCircular) => async (dispatch, getState) => {
  // Request Body
  // 4790
  dispatch(loadUser());
  // 4592
  data.first_name = encryptCredentials(data.first_name);
  data.last_name = encryptCredentials(data.last_name);
  data.middle_name = encryptCredentials(data.middle_name);
  data.email = encryptCredentials(data.email);

  await axiosApiInstance
    .post(API.ADMINASSIST_EDIT, data, tokenConfig(getState))
    .then((res) => {
      close(false)
      dispatch(showAllAdminAssistant());
      toast.success("Successfully updated Admin Assistant account.");
    })
    .catch((err) => {
      setOnLoadCircular(false)
      toast.error(err.response.data.message);
    });
};

export const updateAdminUnlock = ({ name, email, username, id, user_type,}) => async (dispatch, getState) => {
  // Request Body
  // 4790
  dispatch(loadUser());
  const body = JSON.stringify({ name, email, username, id, user_type, status: true,  });
  await axiosApiInstance
    .post(API.ADMINASSIST_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      // 205
      dispatch(showAllAdminAssistant());
      toast.success("Admin Assistant account successfully unlocked.");
      dispatch({
        type: ACTION_TYPES.SHOW_ADMINASSIST,
      });
    })
    .catch((err) => {
      //error response
    });
};

export const updateAdminActive = ({ name, email, username, id, user_type, }) => async (dispatch, getState) => {
  // Request Body
  // 4790
  dispatch(loadUser());
  const body = JSON.stringify({ name, email, username, id, user_type, active: true, });
  await axiosApiInstance
    .post(API.ADMINASSIST_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      toast.success("Admin Assistant account successfully activated.");
      dispatch({
        type: ACTION_TYPES.SHOW_ADMINASSIST,
      });
    })
    .catch((err) => {
      //error response
    });
};

export const updateAdminDeactivate = ({ name, email, username, id, user_type, }) => async (dispatch, getState) => {
  // Request Body
  // 4790
  dispatch(loadUser());
  const body = JSON.stringify({ name, email, username, id, user_type, active: false, });
  await axiosApiInstance
    .post(API.ADMINASSIST_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      toast.success("Admin Assistant account successfully deactivated.");
      dispatch({
        type: ACTION_TYPES.SHOW_ADMINASSIST,
      });
    })
    .catch((err) => {
      //error response
    });
};

// Get offices
export const getHimsAuth = () => async (dispatch, getState) => {
  // Request Body
  const username = process.env.HIMS_USERNAME
  const password = process.env.HIMS_PASSWORD
  const body = JSON.stringify({ username, password,});
  axios
  .post(API.ADMINASSIST_LOGIN, body, tokenConfig(getState))
    .then(async(res) => {
      const token = res.data.login.access_token
      const config = {
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
    await axios
    .get(API.ADMINASSIST_OFFICES, config)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ADMINASSIST_OFFICES,
        payload: res.data.body,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });

    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const onSortFilter = (orderBy, request, order) => (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const newData = {
    first_name: 'Leonardo',
    username: 'test test',
    email: '9879 87',
    status: true,
  };

  const data = Object.keys(newData)[orderBy];

  let newOrder;
  let currOrder = order;

  if (!getState().admin.searchParams.sortBy) {
    currOrder = false;
    newOrder = 'asc';
  } else {
    if (data === getState().admin.searchParams.sortBy) {
      if (getState().admin.searchParams.orderBy == 'desc') {
        newOrder = 'asc';
      } else {
        newOrder = 'desc';
      }
    } else {
      currOrder = true;
      newOrder = 'asc';
    }
  }

  dispatch({
    type: ACTION_TYPES.SORT_FILTER,
    payload: data,
    orderBy: newOrder,
    newOrder: currOrder,
  });
  dispatch(showAllAdminAssistant());
};

export const setPaginationPageAdmin = (pageNumber, admin = true) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_NO_ADMIN,
    payload: pageNumber,
  });
  if(admin) dispatch(showAllAdminAssistant());
};

export const showEdit = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_EDIT,
    payload: true,
  });
};
export const getStatusRequestAdmin = (request) => {
  return {
    type: ACTION_TYPES.ADMINASSIST_STATUS,
    payload: request,
  };
};
export const getFilterRequestAdmin = (request) => {
  return {
    type: ACTION_TYPES.FILTER_ADMINASSIST,
    payload: request,
  };
};

// 3434
export const clearFilterAdminAssist = (request) => {
  return {
    type: ACTION_TYPES.CLEAR_ADMINASSIST_FILTER,
    payload: request,
  };
};

export const setPageLimitAdmin = (pageLimit, admin = true) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_LIMIT_ADMIN,
    payload: pageLimit,
  });
  if(admin) dispatch(showAllAdminAssistant());
};

// export const clearAdmin= () => (dispatch) => {
//   dispatch({
//     type: ACTION_TYPES.CLEAR_NEWMESSENGER,
//   });
// };

// export const AdminLoading = (bool) => {
//   return {
//     type: ACTION_TYPES.MESSENGER_LOADING,
//     payload: bool,
//   };
// };

// export const clearAdminFilter = () => (dispatch) => {
//   dispatch({ type: ACTION_TYPES.CLEAR_MESSENGER_FILTER });
// };

// Setup config/Headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = localStorage.getItem('token');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
};
