import React from 'react';
import CommonDialog from '../common/CommonDialog';
import EditCourierComponents from './EditCourierComponent';
import PropTypes from 'prop-types';

const EditCourier = ({ classes, close, open }) => {
  return (
    <>
      <CommonDialog close={close} classesPad="print-pad-none" open={open}>
        <EditCourierComponents close={close} classes={classes} />
      </CommonDialog>
    </>
  );
};

EditCourier.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.any,
  open: PropTypes.any,
};

export default React.memo(EditCourier);
