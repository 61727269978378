/* eslint-disable camelcase */
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import Close from '../../assets/img/close_24px.png'
import { makeValidate, Select } from 'mui-rff'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import CustomEditMultiSelect from '../common/CustomEditMultiSelect'
import * as API from '../../utils/Constants'
import PrimaryButton from '../common/Button/PrimaryButton'
import SecondaryButton from '../common/Button/SecondaryButton'
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextFieldEditAdmin from '../common/TextFieldEditAdmin'
import DialogConfirm from '../common/DialogConfirm/DialogConfirm';
import DialogDeactivateAccount from '../common/DialogDeactivate/DialogDeactivate';
import DialogactiveAccount from '../common/DialogActive/DialogActive';
import DialogUnlockAccount from '../common/DialogUnlockAccount/DialogUnlockAccount';
import PropTypes from 'prop-types'
import * as ADMIN from '../../store/actions/AdminAssistActions'
moment.locale();

function EditAdminComponent({ classes, close }) {
  const dispatch = useDispatch()

  const formSchema = Yup.object().shape({
    // first_name: Yup.string().required('Required.'),
    // last_name: Yup.string().required('Required.'),
  });
  const admin_info = useSelector((state) => state.admin);
  const [location, setLocation] = useState([
    {
      officeItem: admin_info.offices ? admin_info.offices : "No location",
      office: admin_info.modal.assigned_locations[0].office,
      officeLabel: admin_info.modal.assigned_locations[0].label,
    },
  ])
  const [onLoadCircular, setOnLoadCircular] = useState(false)
  const [active, setActive] = React.useState(admin_info.modal.active);
  const [lock, setLock] = React.useState(admin_info.modal.status);
  const [role, setRole] = React.useState('dds_admin');
  const [messagePrompt, setmessagePrompt] = useState(false);
  const [onChange, setonChange] = useState(false);
  const [officeLength, setOfficeLength] = useState(true)
  const [open, setOpen] = useState(false);
  const [openUnlock, setOpenUnlock] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);

  const closeMessagePrompt = () => {
    setmessagePrompt(!messagePrompt);
  };



  const handleClose = () => {
    if (onChange) {
      setOpen(true);
    } else {
      close(false)
    }

  }

  const onReset = () => {
    close(false, admin_info.modal.id, true)
  };

  const onDeactivate = () => {
    //dispatch(ADMIN.updateAdminDeactivate(admin_info.modal));
    //close(false)
    setOpenDeactivate(true);
    setActive(admin_info.modal.active);
  };

  const onActivate = () => {
    //dispatch(ADMIN.updateAdminActive(admin_info.modal));
    //close(false)
    setOpenActivate(true);
    setActive(admin_info.modal.active);
  };
  
  useEffect(() => {
    // ////////////
    // eslint-disable-next-line
    setActive(admin_info.modal.active);
  }, [setActive]);
  
  const itemForOffice = (idx, value) => {
    const info = []
    const infoLabel = []
    location[idx].officeItem.forEach((item) => {
      for (const data of value) {
        if (item.name === data) {
          info.push(item._id)
          infoLabel.push(item.name)
        }
      }
    })


      if (value.length < 1) {
        setOfficeLength(true)
      } else {
      setOfficeLength(false)
      }

    const itemData = location
    itemData[idx].office = info
    itemData[idx].officeLabel = infoLabel
    setLocation([...itemData])
  }


  const onSave = (values) => {
    const newData = []
    for (const data of location) {
      newData.push({ office: data.office, label: data.officeLabel })
    }

    const body = {
      account_type: "dds_admin",
      id: values.id || admin_info.modal.id,
      first_name: values.first_name || admin_info.modal.first_name,
      last_name: values.last_name || admin_info.modal.last_name,
      middle_name: values.middle_name || admin_info.modal.middle_name,
      suffix: values.suffix || admin_info.modal.suffix,
      assigned_locations: newData || admin_info.modal.assigned_locations,
      email: values.email_address || admin_info.modal.email,
      company_id: values.company_id,
      active: active,
      status: lock,
      }
    setOnLoadCircular(true)
    dispatch(ADMIN.editAdmin(body, close, setOnLoadCircular))
  }

  return (
    <>
      {!admin_info.onShowEdit ? (
      <Grid
        id="admin-grid"
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '97px',
          alignItems: 'center',
        }}
        >
          <div className={classes.itemHolder} style={{ maxWidth: '40%'}}>
          <Grid container>
            <Grid item sm={6}>
              <h2 className={classes.header} data-cy="admin_details_title">
                Admin Assistant Details
              </h2>
            </Grid>
          </Grid>

          <Grid container className={classes.itemContainer}>
            <div className={classes.itemWrapper}>
              <h2 className={classes.subTitle}>Admin Assistant</h2>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
              
              <Button style={{ color: '#41B67F' }} onClick={onReset}>Edit</Button>
              { active }
              {active === true ? <Button onClick={onDeactivate}>Deactivate</Button> : 
              <Button style={{ color: '#41B67F' }} onClick={onActivate}>Activate</Button>}
              
              </ButtonGroup>
            </div>
            <Grid container>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_name">
                    Name:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {`${admin_info.modal.first_name} ${' '} ${admin_info.modal.middle_name} ${' '} ${admin_info.modal.last_name} ${' '} ${admin_info.modal.suffix}`} </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_username">
                    Username:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {admin_info.modal.username}</p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_email">
                    Email Address:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {admin_info.modal.email}</p>
                </Grid>
                <Grid container style={{ marginTop: '22px' }}>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_date_created">
                    Date Access Created:
                  </p>
                </Grid>
                <Grid sm={6}>
                <div className={classes.pass}>{moment(admin_info.modal.created_at).format('MMM D, YYYY')}</div>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_status">
                    Account Status:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {active === true ? 'Active' : 'Deactivated'}</p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_access_status">
                    Access Status:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {admin_info.modal.status === true ? 'Unlock' : 'Lock'}</p>
                </Grid>
                </Grid>

                <Grid container style={{ marginTop: '22px' }}>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_company_id">
                    Company ID:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <div className={classes.pass}>{admin_info.modal.company_id}</div>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_details_department">
                    Department:
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}> {admin_info.modal.hims_department_name}</p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_access_status">
                    Job Role: 
                  </p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.description}>Admin Assistant</p>
                </Grid>
                <Grid sm={6}>
                  <p className={classes.title} data-cy="admin_access_status">
                    Office Locations: 
                  </p>
                </Grid>
                <Grid sm={6}>
                {admin_info.modal.assigned_locations[0].label.map((itemText, id) => {
                          return (
                            <p key={id} className={classes.description}>
                              {itemText}
                            </p>
                          );
                })}
                </Grid>
                </Grid>
               
            </Grid>
          </Grid>
          </div>
        </Grid>
      ) : (<Form
          onSubmit={onSave}
          validate={makeValidate(formSchema)}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form
              data-cy="edit_dds_adminassistant_form"
              onSubmit={handleSubmit}
              style={{ width: '100%' }}
              noValidate
              onChange={(event) => {
                setonChange(true)
                }
              }
            >
              <Grid
                container
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                // spacing={3}
              >
                <div className={classes.itemHolder}>
                  <div className={classes.headerWrapper}>
                    <h2 className={classes.header}>Admin Assistant Details</h2>
                    <div className={classes.btnDivider}>
                      <SecondaryButton
                        onClick={handleClose}
                        customClass={classes.btnLeft}
                        datacy="cancel_btn"
                      >
                        Cancel
                      </SecondaryButton>
                    <PrimaryButton
                        disabled={
                          hasValidationErrors ||
                          !onChange
                        }
                        onClick={handleSubmit}
                        className={classes.btnLeft}
                        datacy="save_btn"
                      >
                         {onLoadCircular ?   <CircularProgress classes={{ root: classes.circle2 }} /> :   
                        'Save'}
                      </PrimaryButton>
                   
                    </div>
                  </div>

                  <Grid container>
                    <div className={classes.editItemWrapper}>
                      <div className={classes.editHolder}>
                        <h2 className={classes.subTitle}>Admin Assistant</h2>
                      </div>
                    </div>
                    <div className={classes.editItemContainer}>
                      <Grid container>
                        
                        <Grid item sm={3}>
                          <TextFieldEditAdmin
                            className={classes.input}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={admin_info.modal.username}
                            label="Username"
                            name="username"
                            disabled={true}   
                          />
                        </Grid>
                        <Grid item sm={3}>
                        <FormControl className={classes.formControl}>
                          <Select
                            label="Account Status"
                            name="account_status"
                            variant="outlined"
                            value={active}
                            onChange={(event) => {
                              setActive(event.target.value)
                              if(!active){
                              setOpenActivate(true)
                              }else{
                              setOpenDeactivate(true)
                              }
                             }
                            }
                          >
                            <MenuItem value={true}>Activate</MenuItem>
                            <MenuItem value={false}>Deactivated</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl className={classes.formControl}>
                          <Select
                            label="Access Status"
                            name="accesss_status"
                            variant="outlined"
                            value={lock}
                            onChange={(event) => {
                              setLock(event.target.value)
                              setOpenUnlock(true)
                              }
                            }
                          >
                            <MenuItem value={false} disabled>Lock</MenuItem>
                            <MenuItem value={true}>Unlock</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                        <Grid container className={classes.boxNo}>
                         
                        <Grid item sm={3}>
                          <TextFieldEditAdmin
                            label="First Name"
                            name="first_name"
                            defaultValue={admin_info.modal.first_name}
                            className={classes.input}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldEditAdmin
                            label= "Middle Name (Optional)"
                            name="middle_name"
                            defaultValue={admin_info.modal.middle_name}
                            className={classes.input}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldEditAdmin
                            className={classes.input}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Last Name"
                            name="last_name"
                            defaultValue={admin_info.modal.last_name}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldEditAdmin
                            className={classes.input}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Suffix (Optional)"
                            name="suffix"
                            defaultValue={admin_info.modal.suffix}
                          />
                        </Grid>

                        </Grid>

                       
                        <Grid container className={classes.boxNo}>

                        <Grid item sm={3} variant="filled">
                          <TextFieldEditAdmin
                            label="Email Address"
                            name="email_address"
                            defaultValue={admin_info.modal.email}
                            className={classes.input}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <FormControl className={classes.formControl}>
                            <Select
                              disabled
                              label="Department"
                              name="department"
                              variant="outlined"
                              value={'FPAD'}
                              onChange={(event) =>
                                setDepartment(idx, event.target.value)
                              }
                            >
                              <MenuItem value={'FPAD'}>FPAD</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item sm={3}>
                          <FormControl className={classes.formControl}>
                            <Select
                              disabled
                              label="Job Role"
                              name="job_role"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={role}
                              onChange={(event) =>
                                setRole(event.target.value)
                              }
                            >
                              <MenuItem value={'dds_admin'}>Admin Assistant</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item sm={3}>
                          {location.map((item, idx) => {
                            const info = []
                            
                            // 200
                            if(item.officeItem) {
                              for (const data of item.officeItem) {
                                info.push(data.name)
                              }
                            }

                            return (
                              <div
                                key={idx}
                                style={{
                                  width: '100%',
                                }}
                              >

                                <CustomEditMultiSelect
                                  name="office_locations"
                                  label="Office Locations"
                                  idx={idx}
                                  itemLength={location[idx].office}
                                  loadingBarangay={true}
                                  itemComparison={location[idx].officeItem}
                                  setStateData={itemForOffice}
                                  selectLabel="Locations Selected"
                                  checkList={info}
                                  initLabel={item.officeLabel}
                                  id={idx}
                                  selectProp={true}
                                />

                              </div>
                            )
                          })}

                        </Grid>

                        </Grid>

                        <Grid container className={classes.boxNo}>

                        <Grid item sm={3}>
                           <TextFieldEditAdmin
                             label="Company ID (Optional)"
                             name="company_id"
                             datacy="company_id"
                             defaultValue={admin_info.modal.company_id}
                           />

                        </Grid>

                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </form>
          )}
        />
      )}
      
      <DialogConfirm
        setOpen={setOpen}
        open={open}
        close={close}
      />

      <DialogUnlockAccount
        setOpen={setOpenUnlock}
        open={openUnlock}
        close={close}
        setActive={setActive}
        name={admin_info.modal}
        admin={'Admin Assistant'}
        setLock={setLock}
        setonChange={setonChange}
      />

      <DialogDeactivateAccount
        setOpen={setOpenDeactivate}
        open={openDeactivate}
        edit={admin_info.onShowEdit}
        close={close}
        setActive={setActive}
        admin={'Admin Assistant'}
        name={admin_info.modal}
        setonChange={setonChange}
      />

      <DialogactiveAccount
        setOpen={setOpenActivate}
        open={openActivate}
        edit={admin_info.onShowEdit}
        setActive={setActive}
        close={close}
        admin={'Admin Assistant'}
        name={admin_info.modal}
        setonChange={setonChange}
      />
    </>

    
  )
}

EditAdminComponent.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
}
export default EditAdminComponent
