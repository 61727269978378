import React, { useState } from 'react';
import CommonDialog from '../common/CommonDialog';
import EditAdminComponent from './EditAdminComponent';
import * as ADMINASSIST from '../../store/actions/AdminAssistActions';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'


const EditAdmin = ({ classes, close, open,  }) => {
  const dispatch = useDispatch();
  const admin_info = useSelector((state) => state.admin);
  const onEdit = () => {
    if(!admin_info.onShowEdit){
      close(false)  
    }else{
      dispatch(ADMINASSIST.getAdminDetails(admin_info.modal.id, false, false));
    }
    
  };
  

  return (
    <>
      <CommonDialog close={onEdit} classesPad="print-pad-none" open={open}>
      <EditAdminComponent close={close} classes={classes} />
      </CommonDialog>
    </>
  );
};

EditAdmin.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
  open: PropTypes.bool,
};

export default React.memo(EditAdmin);
