
import * as ACTION_TYPES from './action_types';
import { returnErrors, clearErrors } from './errorActions';
import * as API from '../../utils/Constants';
import { axiosApiInstance } from '../../utils/apiConfig';
import { tokenConfigBlob } from '../actions/action_report';
import { loadUser } from './authActions';
import moment from 'moment';

const fileDownload = require('js-file-download');
moment.locale();

// Setup config/Headers and token
export const tokenConfig = (getState) => {
    // Get token from local storage
    // const token = getState().auth.token;
    const token = localStorage.getItem('token');
  
    // Headers
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
  
    // If token, add to headers
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
  
    return config;
};

export const getAuditLogs = () => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  dispatch(clearErrors());
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const requestTableData = getState().audit.searchParams;
  const newObjStateRequestTable = JSON.parse(JSON.stringify(requestTableData));
  const paramsQuery = new URLSearchParams(newObjStateRequestTable).toString();

  await axiosApiInstance
    .get(API.GET_AUDIT_LOGS_DDS + '?' + paramsQuery, tokenConfig(getState))
    .then((res) => {
        dispatch({
            type: ACTION_TYPES.GET_AUDIT_LOGS_DDS,
            payload: res.data,
        });
    })
  .catch((err) => {
      console.log(err);
      if (err.response) {
          dispatch(returnErrors(err.response.data.errors, err.response.status, err.response.data.message));
          dispatch({
              type: ACTION_TYPES.GET_AUDIT_LOGS_DDS,
          });
      }
  });
};

export const clearAuditLogs = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.CLEAR_CONTACT_SEARCH,
  });
};

export const getFilterAuditName = (name) => {
  return {
    type: ACTION_TYPES.FILTER_AUDIT_NAME,
    payload: name,
  };
};

export const getFilterRequestAudit = (audit) => {
  return {
    type: ACTION_TYPES.FILTER_AUDIT,
    payload: audit,
  };
};

export const getFilterByUser = (user, retrieve) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.FILTER_AUDIT_USER,
    payload: user == "-- Filter by User --" ? "" : user,
  });
  if(retrieve) dispatch(getAuditLogs());
};

export const setPaginationPageAudit = (pageNumber, retrieve) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_NO_AUDIT,
    payload: pageNumber,
  });
  if(retrieve) dispatch(getAuditLogs());
};

export const setPageLimitAudit = (pageLimit, retrieve) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_NO_AUDIT,
    payload: 1,
  });

  dispatch({
    type: ACTION_TYPES.PAGE_LIMIT_AUDIT,
    payload: pageLimit,
  });
  if(retrieve) dispatch(getAuditLogs());
};

export const onSortFilter = (orderBy, request, order) => (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const newData = {
    activity_id: '1',
    person_name: 'VNI',
    action: 'login',
    ip_address: '192.168.1.1',
    date_of_entry: '01/01/1900',
    timestamp: '00:00:00 AM'
  };

  const data = Object.keys(newData)[orderBy];

  let searchParams = getState().audit.searchParams;
  let newOrder;
  let currOrder = order;

  if (!searchParams.sortBy) {
    currOrder = false;
    newOrder = 'asc';
  } else {
    if (data === searchParams.sortBy) {
      if (searchParams.orderBy === 'desc') {
        newOrder = 'asc';
      } else {
        newOrder = 'desc';
      }
    } else {
      currOrder = true;
      newOrder = 'asc';
    }
  }

  dispatch({
    type: ACTION_TYPES.SORT_FILTER,
    payload: data,
    orderBy: newOrder,
    newOrder: currOrder,
  });
  dispatch(getAuditLogs());
};

export const setDateRangeFilter = (start_date, end_date) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.FILTER_DATE_RANGE_SCHEDULE_AUDIT,
    payload: { start_date, end_date },
  });
  dispatch(getAuditLogs());
};


export const getReportDownload = (dataType, setShowPrint, reportType) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  dispatch(clearErrors());

  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const requestTableData = getState().audit.searchParams;
  requestTableData.type = dataType;
  requestTableData.reportType = reportType;
  const newObjStateRequestTable = JSON.parse(JSON.stringify(requestTableData));
  const paramsQuery = new URLSearchParams(newObjStateRequestTable).toString();
  
  await axiosApiInstance
    .get(API.GET_AUDIT_LOGS_DDS_REPORT + '?' + paramsQuery, reportType == "PDF" ? tokenConfig(getState) : tokenConfigBlob())
    .then((res) => {
        if(reportType == "PDF") {
          dispatch({
              type: ACTION_TYPES.GET_AUDIT_LOGS_DDS_REPORT,
              payload: res.data,
          });
        } else {
          // EXCEL
          const dateFormat = moment(new Date()).format('YYYYMMDD');
          const timeFormat = moment(new Date()).format('hhmmss');
          // 4781
          const fileName = `AuditLogReport_${dateFormat}_${timeFormat}`;
          fileDownload(res.data, `${fileName}.xlsx`);
        }
    })
  .catch((err) => {
      console.log(err);
      if (err.response) {
          dispatch(returnErrors(err.response.data.errors, err.response.status, err.response.data.message));
          dispatch({
              type: ACTION_TYPES.GET_AUDIT_LOGS_DDS_REPORT,
          });
      }
  });
};

export const getAuditLogDetails = (id, setOpen) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
  .get(API.AUDIT_LOG_DETAILS + `/${id}`, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.AUDIT_LOG_DETAILS,
          payload: response.data,
        });
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
    });
};
