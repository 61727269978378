/* eslint-disable no-case-declarations */
import * as ACTION from '../actions/action_types';

const initialState = {
    audits: [],
    audit_detail: [],
    audits_report: [],
    table: null,
    tableView: null,
    searchParams: {
      keyword: '',
      user: '',
      name: '',
      dateFrom: '',
      dateTo: '',
      pageLimit: 10,
      pageNum: 1,
      sortBy: 'date_of_entry',
      orderBy: '',
      type: 'all',
      reportType: 'PDF',
    },
    order: false,
    filterUser: [],
    fetchLoading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
      case ACTION.GET_AUDIT_LOGS_DDS:
        return {
          ...state,
          audits: action.payload.data,
          filterUser: action.payload.user,
          table: action.payload.pagination,
          fetchLoading: false,
        };
      case ACTION.AUDIT_LOG_DETAILS:
        return {
          ...state,
          audit_detail: action.payload.data[0],
          fetchLoading: false,
        }
      case ACTION.GET_AUDIT_LOGS_DDS_REPORT:
        return {
          ...state,
          audits_report: action.payload.data,
          fetchLoading: false,
        };
      case ACTION.REQUEST_LOADING:
        return {
          ...state,
          fetchLoading: true,
        };
      case ACTION.FILTER_AUDIT:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            keyword: action.payload,
          },
          fetchLoading: false,
        };
      case ACTION.FILTER_AUDIT_USER:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            user: action.payload,
          },
          fetchLoading: false,
        };
      case ACTION.PAGE_LIMIT_AUDIT:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            pageLimit: action.payload,
          },
          fetchLoading: false,
        };
      case ACTION.FILTER_DATE_RANGE_SCHEDULE_AUDIT:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            start_date: action.payload.start_date,
            end_date: action.payload.end_date,
            pageNum: 1,
          },
          fetchLoading: false,
        };
      case ACTION.PAGE_NO_AUDIT:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            pageNum: action.payload,
          },
          fetchLoading: false,
        };
      case ACTION.FILTER_AUDIT_NAME:
          return {
            ...state,
            searchParams: {
              ...state.searchParams,
              name: action.payload,
            },
            fetchLoading: false,
          };
      case ACTION.SORT_FILTER:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            sortBy: action.payload,
            orderBy: action.orderBy,
          },
          order: action.newOrder,
          fetchLoading: false,
        };
      case ACTION.CLEAR_AUDIT_SEARCH:
        console.log("enter here");
        return {
          ...state,
          audit_detail: [],
          searchParams: {
            keyword: '',
            user: '',
            name: '',
            dateFrom: '',
            dateTo: '',
            pageLimit: 10,
            pageNum: 1,
            sortBy: 'date_of_entry',
            orderBy: '',
            type: 'all',
          },
        }
      default:
        return state;
    };
};