/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
import * as ACTION from '../actions/action_types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  isCheck: false,
  user: null,
  forgotPassword: {
    newPassword: null,
    confirmPassword: null,
  },
  changePassword: {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
    isChangePwdSuccess: false,
  },
  changeEmail: {
    currentEmail: null,
    newEmail: null,
    currentPasswordValue: null,
    isChangeEmailSuccess: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.USER_LOADED:
      return {
        ...state,
        user: action.payload.data,
        isAuthenticated: true,
        isLoading: false,
      };
    case ACTION.LOGIN_SUCCESS:
    case ACTION.REGISTER_SUCCESS:
      const selected = [];
      selected.push(action.payload.data.user.user_type);
      sessionStorage.setItem('reloaded', 'yes');
      localStorage.setItem('token', action.payload.data.token);
      localStorage.setItem('refresh_token', action.payload.data.refresh_token);
      // console.log(action.payload, 'LOAD USER');
      localStorage.setItem('roles', JSON.stringify(selected));
      localStorage.setItem('user_id', action.payload.data.user.id);
      localStorage.setItem('user_type', action.payload.data.user.user_type);
      return {
        ...state,
        ...action.payload.data,
        token: action.payload.data.token,
        isAuthenticated: true,
        isLoading: false,
      };
    case ACTION.AUTH_ERROR:
    case ACTION.FGTPASSWORD_SUCCESS:
    case ACTION.FGTPASSWORD_FAIL:
    case ACTION.LOGIN_FAIL:
    case ACTION.LOGOUT_SUCCESS:
    case ACTION.REGISTER_FAIL:
      localStorage.removeItem('token');
      sessionStorage.removeItem('reloaded');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('roles');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_type');
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case ACTION.FGTPASSWORD_NEW_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          newPassword: action.payload,
        },
      };
    case ACTION.FGTPASSWORD_CONFIRM_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          confirmPassword: action.payload,
        },
      };
    case ACTION.CHANGEPWD_CURRENT_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          currentPassword: action.payload,
        },
      };
    case ACTION.CHANGEPWD_NEW_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          newPassword: action.payload,
        },
      };
    case ACTION.CHANGEPWD_CONFIRM_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          confirmPassword: action.payload,
        },
      };
    case ACTION.CHANGEPWD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isChangePwdSuccess: action.payload,
        },
      };
    case ACTION.CHANGEEMAIL_CURRENT_EMAIL:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          currentEmail: action.payload,
        },
      };
    case ACTION.CHANGEEMAIL_NEW_EMAIL:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          newEmail: action.payload,
        },
      };
    case ACTION.CHANGEEMAIL_CURRENT_PASSWORD:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          currentPasswordValue: action.payload,
        },
      };
    case ACTION.CHANGEEMAIL_SUCCESS:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          isChangeEmailSuccess: action.payload,
        },
      };
    // 4792
    case ACTION.AUTH_TOKEN:
      return {
        ...state,
        isCheck: !state.isCheck,
      }
    default:
      return state;
  }
}
