/* eslint-disable camelcase */
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeValidate, TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { addNewCourier, createNewCourier, allCourier, getAllCouriers, tokenConfig } from '../../store/actions/CourierActions';
import PrimaryButton from '../common/Button/PrimaryButton';
import SecondaryButton from '../common/Button/SecondaryButton';
import CommonInputNumber from '../common/CommonInputNumber';
import CourierCustomMultiSelect from '../common/CourierCustomMultiSelect';
import PasswordField from '../common/PasswordField/PasswordField';
import TextFields from '../common/TextFields';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import './styles/NewCourierComponent.scss';
import * as ACTION_TYPES from '../../store/actions/action_types';
import * as API from '../../utils/Constants';
import { toast } from 'react-toastify';
import { axiosApiInstance } from '../../utils/apiConfig';

const useStyles = makeStyles(() => ({
  input: {
    maxWidth: '250px',
    '& label': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    },
    '& .MuiInput-underline::before': {
      border: 'none',
    },
    '& .MuiInput-underline::after': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '22px',
    },
    '& input': {
      background: '#fff',
      width: '100%',
      height: '40px',
      padding: '0 16px',
      border: '1px solid #A5B0BE',
      borderRadius: '4px',
    },
  },
}));

function NewCourierComponent({ classes, close }) {
  const dispatch = useDispatch();
  const ownClasses = useStyles();
  const [onLoadCircular, setOnLoadCircular] = useState(false);
  const address_info = useSelector((state) => state.address);
  const courier_info = useSelector((state) => state.courier);
  const [box_no, setBoxNo] = useState('');
  const [capacity, setCapacity] = useState('');
  const [provinceLength, setProvinceLength] = useState(true);
  const [courierName, setCourierName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [passWord, setPassWord] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [error, setError] = useState(false);
  const [location, setLocation] = useState({
    provinceIdsChecklist: address_info.courierAddress.initialProvinceIds,
    provinces: address_info.courierAddress.initialProvinceIds,
    provinceLabel: address_info.courierAddress.initialProvinceLabels,
  });

  const formSchema = Yup.object().shape({
    username: Yup.string().min(5, 'Username must be at least 5 characters'),
  });

  const onSave = async (values) => {
    setOnLoadCircular(true);

    let filteredCity = address_info.himsAllCities.filter(obj => location.provinceLabel.includes(obj.province));   
    let cityIds = [];   
    let cityLabels = [];   
    for (const data of filteredCity) {
      cityIds.push(data._id);
      cityLabels.push(data.name);
    }

    // let filteredBarangays = address_info.himsAllBarangays.filter(obj => cityLabels.includes(obj.city));   
    // let barangayIds = [];
    // let barangayLabels = [];
    // for (const data of filteredBarangays) {
    //   barangayIds.push(data._id);
    //   barangayLabels.push(data.name);
    // }

    const newData = [{
      provinces: location.provinces,
      cities: cityIds,
      // barangays: barangayIds,
    }];

    const newDataLabels = [{
      provinces: location.provinceLabel,
      cities: cityLabels,
      // barangays: barangayLabels,
    }];

    const body = {
      courier_name: courierName,
      contact_number: contactNumber,
      first_name: firstName,
      password : passWord,
      last_name: lastName,
      username: values.username,
      box_no: parseInt(box_no),
      capacity: parseInt(capacity),
      assigned_locations: newData,
      assigned_locations_labels: newDataLabels,
      status: 1,
    };

    const apiResponse = await axiosApiInstance
    .post(API.ADD_NEW_COURIER, body, tokenConfig(''))
    .then((response) => response)
    .catch((error) => error.response);

    if (apiResponse.status === 200) {
      dispatch(allCourier(close));
      // 4530
      dispatch(getAllCouriers());
      toast.success(apiResponse.data.message);
    } else {
      setOnLoadCircular(false);
      toast.error(apiResponse.data.message);
    }
    
    // dispatch(addNewCourier(body, close));
  };

  const onChangeCourierName = (e) => {
    setCourierName(e.target.value);
  };

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const onChangeUserName = (value) => {
    setUserName(value);
  };

  const onChangePassWord = (value) => {
    setPassWord(value);
  };

  const onChangeContactNumber = (e) => {
    const re = /^[0-9]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setContactNumber(e.target.value);
    }
  };

  const onCount = (val) => {
    // 4530
    const data = courier_info.box_no.some((item) => {
      return item.box_no === parseInt(val);
    });

    if (data) {
      setError(true);
    } else {
      setError(false);
    }

    setBoxNo(val);
  };

  const onCountCapacity = (val) => {
    setCapacity(val);
  };

  const handleClose = () => {
    close(false);
  };

  const itemForProvince = async (value) => {
    const itemData = location;
    itemData.provinces = [];
    itemData.provinceLabel = [];

    if (value.length) {
      const info = [];
      const infoLabel = [];

      // 4375
      if(address_info.himsProvinces) {
        address_info.himsProvinces.forEach((item) => {
          for (const data of value) {
            if (item._id === data && !info.includes(item._id)) {
              info.push(item._id);
              infoLabel.push(item.name);
            }
          }
        });
      }

      itemData.provinces = info;
      itemData.provinceLabel = infoLabel;

    }

    setLocation({...itemData}); 
  };

  useEffect(() => {
    // 4375
    if(location.provinces) {
      if (location.provinces.length < 1) {
        setProvinceLength(true);
      } else {
        setProvinceLength(false);
      }
    }
  }, [location]);

  return (
    <>
      <Form
        onSubmit={onSave}
        validate={makeValidate(formSchema)}
        render={({ handleSubmit, hasValidationErrors, form }) => (
          <form data-cy="new_courier_form" onSubmit={handleSubmit} noValidate>
            <Grid container className="form-container" >
              <div className={classes.itemHolder}>
                <div className={classes.headerWrapper}>
                  <h2 className={classes.header}>New Courier</h2>
                  <div className={classes.btnDivider}>
                    <SecondaryButton disabled={onLoadCircular} onClick={handleClose} customClass="action-button" datacy="cancel_btn">
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={hasValidationErrors ||
                        capacity < 1 ||
                        box_no < 1 ||
                        provinceLength ||
                        error ||
                        !courierName ||
                        !firstName ||
                        !lastName ||
                        !userName ||
                        !contactNumber ||
                        !passWord ||
                        onLoadCircular
                      }
                      onClick={handleSubmit}
                      className="action-button"
                      datacy="save_btn"
                    >
                      {onLoadCircular ? <CircularProgress classes={{ root: classes.circle2 }} /> : 'Save'}
                    </PrimaryButton>
                  </div>
                </div>
                <Grid container>
                  <div className={classes.editItemWrapper}>
                    <div className={classes.editHolder}>
                      <h2 className={classes.subTitle}>Courier</h2>
                    </div>
                  </div>
                  <div className="fields-container">
                    <Grid container>
                      <Grid item sm={6}>
                        <TextFields label="Courier Name" name="courier_name" datacy="courier_name" value={courierName} onChange={onChangeCourierName}/>
                      </Grid>
                      <Grid item sm={6}></Grid>
                      <Grid item sm={6}>
                        <TextFields label="First Name" name="first_name" datacy="first_name" value={firstName} onChange={onChangeFirstName}/>
                      </Grid>
                      <Grid item sm={6}>
                        <TextFields label="Last Name" name="last_name" datacy="last_name" value={lastName} onChange={onChangeLastName}/>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          className={ownClasses.input}
                          label="Username"
                          name="username"
                          onChange={(e) => {
                            onChangeUserName(e.target.value);
                            form.change('username', e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ "data-cy": "username" }}
                        />
                      </Grid>
            
                      <Grid item sm={6}>
                        <PasswordField
                          label="Password"
                          name="password"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="password"
                          autoComplete="current-password"
                          onChange={(e) => {
                            onChangePassWord(e.target.value);
                          }}
                          inputProps={{ 'data-cy': 'password_login' }}
                        />
                      </Grid>   
                      <Grid item sm={6}>
                        <TextFields label="Contact Number" name="contact_number" datacy="contact_number" value={contactNumber} onChange={onChangeContactNumber}/>
                      </Grid>
                      <Grid container className="box-no">
                        <Grid item sm={6}>
                          <div className={classes.boxError}>
                            <CommonInputNumber
                              label="Box No."
                              name="box_no"
                              datacy="box_no"
                              value={box_no}
                              setItem={onCount}
                            />
                            <p className={error ? "error-box" : classes.errorBoxNone}>Box No. must be unique</p>
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <CommonInputNumber
                            label="Capacity"
                            name="capacity"
                            datacy="capacity"
                            value={capacity}
                            setItem={onCountCapacity}
                          />
                        </Grid>
                      </Grid>
                      <div className="address-box" >
                        <Grid container className="select-box">
                          <Grid item sm={6} style={{marginTop: '-2.4px'}}>
                            <CourierCustomMultiSelect
                              label="Assigned State / Province"
                              loadingItem={address_info.himsProvinces ? !address_info.himsProvinces.length : false}
                              itemComparison={address_info.himsProvinces ? address_info.himsProvinces : []}
                              setStateData={itemForProvince}
                              selectLabel="Province"
                              checkList={address_info.himsProvinces}
                              checkListIds={location.provinceIdsChecklist}
                              selectedItem={location.provinces}
                              name="assigned_province"
                              datacy="assigned_province"
                              datacyDropdown="assigned_province_dropdown"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </Grid>
          </form>
        )}
      />
    </>
  );
}

NewCourierComponent.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.any,
};
export default NewCourierComponent;
