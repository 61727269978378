import { createTheme, responsiveFontSizes } from '@mui/material';

const theme = createTheme({
  direction: 'ltr',
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeightBold: 600,
    h1: {
      fontSize: 28,
      lineHeight: '1.7',
    },
    h3: {
      fontSize: 28,
      lineHeight: '1.7',
    },
  },
  palette: {
    common: {
      white: '#fff',
      black: '#2F3542',
    },
    mode: 'light', // 'type' is replaced with 'mode' in v5
    primary: {
      main: '#41B67F',
      light: '#9dd6bd',
      dark: '#33a16e',
      grey: '#55565c',
      contrastText: "#fff"
    },
    secondary: {
      main: '#0d0d12',
    },
    error: {
      light: '#f5cfd1',
      main: '#FA5656',
    },
    warning: {
      main: '#F8B344',
    },
    text: {
      primary: '#2F3542',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '1.25rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error, &.Mui-focused': {
            color: '#2F3542',
          },
        },
      },
    },
    // ... other components
  },
});

export default responsiveFontSizes(theme);
