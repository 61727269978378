/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@mui/material/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Layout from '../components/Layout';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { TablePagination, TableCell } from '@material-ui/core';
import MessengerTable from '../components/common/CommonTable/MessengerTable';
import CourierTable from '../components/common/CommonTable/CourierTable';
import AddIcon from '@material-ui/icons/Add';
import PrimaryButton from '../components/common/Button/PrimaryButton';
import Title from '../components/common/Title';

import EditMessenger from '../components/dds/EditMessenger';
import EditCourier from '../components/dds/EditCourier';
import NewMessenger from '../components/dds/NewMessenger';
import NewCourier from '../components/dds/NewCourier';
import { useDispatch, useSelector } from 'react-redux';
import * as MESSENGER from '../store/actions/MessengerActions';
import * as COURIER from '../store/actions/CourierActions';
import * as ADDRESS from '../store/actions/addressActions';
import Alert from '@material-ui/lab/Alert';
import * as REQ_TYPE from '../utils/Constants';
import CommonSelect from '../components/common/CommonSelect/CommonSelect';
import Loading from '../components/common/Loading';
import { numberWithComma } from '../utils/common';

// @ts-ignore
const useStyles = makeStyles(() => ({
  circle: {
    height: '16px !important',
    color: 'green',
    margin: '4px 40px',
    width: '16px !important',
  },
  circle2: {
    height: '16px !important',
    color: 'green',
    margin: '4px 15px  !important',
    width: '16px !important',
  },
  messengerWrapper: {
    display: 'flex',
    width: '100%',
  },
  alert: {
    padding: '6px 8px 6px 8px',
    width: 'fit-content',
    '& .MuiAlert-message': {
      padding: '0',
    },
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionWrapper: {
    display: 'flex',
  },
  btnHolder: {
    marginTop: ' 24px',
    marginBottom: '16px',
  },
  itemWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E6ED',
    marginBottom: '19px',
    '& button': {
      border: 'none',
      color: '#41B67F',
    },
  },
  header: {
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '1.8',
    marginBottom: '24px',
  },
  itemContainer: {
    border: ' 1px solid #E0E6ED',
    padding: '24px 40px',
    marginBottom: '40px',
  },
  itemHolder: {
    maxWidth: '604px',
  },
  assignHolder: {
    margin: '20px 0',
  },
  selectItem: {
    '&  .MuiSelect-selectMenu': {
      fontSize: '16px',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '16px',
    },
  },
  pass: {
    color: '#2F3542',
    margin: '8px 0',
    fontSize: '16px',
    maxWidth: '165px',
    fontWeight: 600,
    border: 'none',
    pointerEvents: 'none',
    lineHeight: 1.3,
    backgroundColor: '#fff !important',
  },
  title: {
    color: 'rgba(47, 53, 66, 0.8)',
    fontSize: '16px',
    lineHeight: '1.3',
    margin: '8px 0',
  },
  subTitle: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '1.8',
    color: '#2F3542',
  },
  description: {
    maxWidth: '190px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '1.3',
    color: '#2F3542',

    wordBreak: 'break-all',

    margin: '8px 0',
  },
  btnReset: {
    marginTop: '22px',
  },
  boxNo: {
    margin: '10px 0',
    '& .MuiInputLabel-root': {
      color: 'rgba(43, 45, 51, 0.8)',
      margin: '0',
      fontSize: '14px',
      lineHeight: '13px',
      marginBottom: '7px',
    },
  },
  boxNos: {
    margin: '30px 0 0',
    '& .MuiInputLabel-root': {
      color: 'rgba(43, 45, 51, 0.8)',
      margin: '0',
      fontSize: '14px',
      lineHeight: '13px',
      marginBottom: '7px',
    },
  },
  removeAssign: {
    cursor: 'pointer',
  },
  editItemContainer: {
    borderTop: 'none',
    border: '1px solid #E0E6ED',
    padding: '24px 40px',
    background: '#F4F6F9',
  },
  editHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deactivateBtn: {
    width: '100%',
    color: '#FA5656',
    textAlign: 'center',
    padding: '24px',
    border: '1px solid #E0E6ED',
    borderTop: 'none',
    marginBottom: '40px',
  },
  editItemWrapper: {
    padding: '25px 40px 12px',
    width: '100%',
    border: '1px solid #E0E6ED',
    borderBottom: 'none',
    '& button': {
      border: 'none',
      color: '#41B67F',
    },
    MuiList: {
      root: {
        fontSize: '20px',
      },
    },
  },
  btnDivider: {
    marginBottom: '14px',
  },
  btnLeft: {
    marginLeft: '16px',
    minWidth: '86px',
    minHeight: '40px',
    padding: '8px 24px !important',
  },
  addMore: {
    fontWeight: 600,
    marginTop: '15px',
    background: '#fff',
    cursor: 'pointer',
    padding: '10px',
    alignItems: 'center',
    width: '100%',
    border: ' 1px solid #E0E6ED',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    borderRadius: '20px',
    height: '16px',
    width: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(65, 182, 127)',
    border: '1px solid rgb(65, 182, 127)',

    '& svg': {
      fontSize: '18px',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchWrapper: {
    position: 'relative',
    '& input': {
      height: '18px',
      padding: '10px 30px 10px 12px',
    },
  },
  search: {
    cursor: 'pointer',
    right: 0,
    height: '40px',
    minWidth: '36px',
    position: 'absolute',
  },
  loadingWidth: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: 10,
  },
  formControl: {
    minWidth: 250,
  },
  btnRight: {
    textAlign: 'right',
  },
  errorBox: {
    marginTop: '5px',
    color: '#FA5656',
    fontSize: '0.8571428571428571rem',
    display: 'block',
  },
  showPrint: {
    display: 'block',
  },
  hidePrint: {
    display: 'none',
  },
  errorBoxNone: {
    display: 'none',
  },
  reseton: {
    marginTop: '5px',
    color: 'rgba(47, 53, 66, 0.8)',
    fontSize: '0.8571428571428571rem',
    display: 'block',
  },
  disNone: {
    display: 'none',
  },
  resetonNone: {
    display: 'none',
  },
  boxError: {
    position: 'relative',
  },
  limit: {
    color: 'red',
  },
  messengerBtnGroup: {
    '& button': {
      padding: '5px 20px',
    },
  },
  alertStatus: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const Messenger = () => {
  const dispatch = useDispatch();
  const messenger_info = useSelector((state) => state.messenger);
  const address_info = useSelector((state) => state.address);
  const courier_info = useSelector((state) => state.courier);
  const classes = useStyles();
  const [searchMessenger, setSearchMessenger] = useState('');
  const [searchCourier, setSearchCourier] = useState('');
  const [openMessenger, setOpenMessenger] = useState(false);
  const [openCourier, setOpenCourier] = useState(false);
  const [openNewMessenger, setOpenNewMessenger] = useState(false);
  const [openNewCourier, setOpenNewCourier] = useState(false);
  const [buttonActive, setButtonActive] = useState('messenger');
  
  const handleChange = (name) => {
    setButtonActive(name);
    if(name === 'messenger') {
      dispatch(MESSENGER.getFilterRequestMessenger(''));
      dispatch(MESSENGER.setPaginationPageMessenger(1));
      dispatch(MESSENGER.setPageLimitMessenger(10));
      dispatch(MESSENGER.getLocationRequestMessenger(''));
      dispatch(MESSENGER.allMessenger());
      //dispatch(MESSENGER.showAllMessenger());
    } else {
      // 2420 - retrieve courier list and other related values
      if (!address_info.himsProvinces) dispatch(ADDRESS.getHimsAllProvince('courier'));
      // 591
      if (!address_info.himsAllCities) dispatch(ADDRESS.getHimsAllCities());
      if (!address_info.himsAllBarangays) dispatch(ADDRESS.getHimsAllBarangays());

      dispatch(COURIER.getFilterRequestCourier(''));
      dispatch(COURIER.setPaginationPageCourier(1, true));
      dispatch(COURIER.setPageLimitCourier(10, true));

      dispatch(COURIER.getLocationRequestCourier(''));
      dispatch(COURIER.allCourier());
      //dispatch(COURIER.showAllCourier());
      dispatch(COURIER.getAllCouriers());
    }
  };

  useEffect(() => {
    // 2420 - added here the retrieving of the address instead in header component
    dispatch(ADDRESS.getHimsNcrCity());
  }, [dispatch]);

  useEffect(() => {    
    dispatch(MESSENGER.getFilterRequestMessenger(''));
    // 2420 - removed, causing the add new messenger for to re-open multiple times
    //dispatch(MESSENGER.setPaginationPageMessenger(1));
    //dispatch(MESSENGER.setPageLimitMessenger(10));

    dispatch(MESSENGER.getLocationRequestMessenger(''));
    dispatch(MESSENGER.allMessenger());
    //dispatch(MESSENGER.showAllMessenger());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    // 2420 - do not load courier list, this is not needed to load
    // dispatch(COURIER.getFilterRequestCourier(''));
    // dispatch(COURIER.setPaginationPageCourier(1));
    // dispatch(COURIER.setPageLimitCourier(10));

    // dispatch(COURIER.getLocationRequestCourier(''));
    // dispatch(COURIER.allCourier());
    // dispatch(COURIER.showAllCourier());
    //dispatch(COURIER.getAllCouriers());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    // 2420 - this are not needed when loading messenger list, transfer to courier
    // if (!address_info.himsProvinces) dispatch(ADDRESS.getHimsAllProvince('courier'));
    // if (!address_info.himsAllCities) dispatch(ADDRESS.getHimsAllCities());
    // if (!address_info.himsAllBarangays) dispatch(ADDRESS.getHimsAllBarangays());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(MESSENGER.clearMessengerFilter());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(COURIER.clearCourierFilter());
    };
  }, []);

  const messengerColumn = [
    {
      title: 'Messenger ID',
      field: 'messenger_id',
    },
    { title: 'Messenger', field: 'messenger' },
    { title: 'Contact Number', field: 'contact_number' },
    { title: 'Assigned Location', field: 'assigned_locations_labels' },
    {
      title: 'Box No.',
      field: 'box_no',
    },
    {
      title: 'Capacity',
      field: 'capacity',
    },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  const courierColumn = [
    {
      title: 'Courier ID',
      field: 'courier_id',
    },
    {
      title: 'Courier Name',
      field: 'courier_name',
    },
    {
      title: 'Messenger',
      field: 'messenger',
    },
    {
      title: 'Contact Number',
      field: 'contact_number',
    },
    {
      title: 'Assigned Location',
      field: 'assigned_locations_labels',
    },
    {
      title: 'Box No.',
      field: 'box_no',
    },
    {
      title: 'Capacity',
      field: 'capacity',
    },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  const dialogOpenMessenger = (data) => {
    const matched = messenger_info.request.find((item) => item.messenger_id === data.messenger_id);

    dispatch(MESSENGER.getMessengerDetails(matched.id, setOpenMessenger, false, true));
  };

  const dialogEditOpenMessenger = (data) => {
    const matched = messenger_info.request.find((item) => item.messenger_id === data.messenger_id);

    dispatch(MESSENGER.getMessengerDetails(matched.id, setOpenMessenger, true, false));
  };

  const dialogOpenCourier = (data) => {
    const matched = courier_info.request.find((item) => item.courier_id === data.courier_id);

    dispatch(COURIER.getCourierDetails(matched.id, setOpenCourier, false));
    // 590
    if (!address_info.himsAllCities) dispatch(ADDRESS.getHimsAllCities());
  };

  const dialogEditOpenCourier = (data) => {
    const matched = courier_info.request.find((item) => item.courier_id === data.courier_id);

    dispatch(COURIER.getCourierDetails(matched.id, setOpenCourier, true));
    // 590
    if (!address_info.himsAllCities) dispatch(ADDRESS.getHimsAllCities());
  };

  const messengerAction = [
    {
      icon: 'edit',
      tooltip: 'edit',
      onClick: (event, rowData) => dialogEditOpenMessenger(rowData),
    },
  ];

  const courierAction = [
    {
      icon: 'edit',
      tooltip: 'edit',
      onClick: (event, rowData) => dialogEditOpenCourier(rowData),
    },
  ];

  const messengerTable = {
    Cell: (props) => {
      let data = [];
      let capacityLimit = false;

      if (props.columnDef.title === 'Capacity') {
        data = props.value.split('/');
        if (parseInt(data[0].replace(' ', '')) >= parseInt(data[1])) {
          capacityLimit = true;
        }
      }

      return props.value === 1 && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <Alert className={`${classes.alert} ${classes.alertStatus}`}  severity="success">
            {REQ_TYPE.ACTIVE_STATUS[0].text}
          </Alert>{' '}
        </TableCell>
      ) : props.value === 2 && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <div className="custom-alert-info">
            <Alert className={`${classes.alert} ${classes.alertStatus}`}  severity="info">
              {REQ_TYPE.ACTIVE_STATUS[1].text}
            </Alert>
          </div>
        </TableCell>
      ) : props.columnDef.title === 'Capacity' ? (
        <TableCell className={capacityLimit === true && classes.limit}>{numberWithComma(props.value.split('/')[1])}</TableCell>
      ) : props.columnDef.title === 'Box No.' ? (
        <TableCell>{numberWithComma(props.value)}</TableCell>
      ) : (
        <TableCell>{props.value}</TableCell>
      );
    },
    Action: (props) => {
      if (props.action.icon === 'edit') {
        return (
          <Button
            onClick={(event) => {
              props.action.onClick(event, props.data);
              event.stopPropagation();
            }}
            color="primary"
            variant="contained"
            style={{
              textTransform: 'none',
              minWidth: '50px',
              padding: '4px 0',
            }}
            size="medium"
            data-cy="edit_btn"
          >
            Edit
          </Button>
        );
      }
    },
    Pagination: (props) => (
      <>
        <TablePagination {...props} rowsPerPageOptions={[10, 20, 30]} />
      </>
    ),
  };

  const courierTable = {
    Cell: (props) => {
      let data = [];
      let capacityLimit = false;

      if (props.columnDef.title === 'Capacity') {
        data = props.value.split('/');
        if (parseInt(data[0].replace(' ', '')) >= parseInt(data[1])) {
          capacityLimit = true;
        }
      }

      return props.value === 1 && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <Alert className={classes.alert} severity="success">
            {REQ_TYPE.ACTIVE_STATUS[0].text}
          </Alert>{' '}
        </TableCell>
      ) : props.value === 2 && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <div className="custom-alert-info">
            <Alert className={classes.alert} severity="info">
              {REQ_TYPE.ACTIVE_STATUS[1].text}
            </Alert>
          </div>
        </TableCell>
      ) : props.columnDef.title === 'Capacity' ? (
        <TableCell className={capacityLimit === true ? classes.limit : ''}>{props.value.split('/')[1]}</TableCell>
      ) : props.columnDef.title === 'Box No.' ? (
        <TableCell>{props.value}</TableCell>
      ) : (
        <TableCell>{props.value}</TableCell>
      );
    },
    Action: (props) => {
      if (props.action.icon === 'edit') {
        return (
          <Button
            onClick={(event) => {
              props.action.onClick(event, props.data);
              event.stopPropagation();
            }}
            color="primary"
            variant="contained"
            style={{
              textTransform: 'none',
              minWidth: '50px',
              padding: '4px 0',
            }}
            size="medium"
            data-cy="edit_btn"
          >
            Edit
          </Button>
        );
      }
    },
    Pagination: (props) => (
      <>
        <TablePagination {...props} rowsPerPageOptions={[10, 20, 30]} />
      </>
    ),
  };

  const messengerSearchHandler = (filter) => {
    setSearchMessenger(filter);
    if (filter.length <= 0) {
      dispatch(MESSENGER.getFilterRequestMessenger(''));
      dispatch(MESSENGER.allMessenger());
    }
  };

  const courierSearchHandler = (filter) => {
    setSearchCourier(filter);
    if (!filter.length) {
      dispatch(COURIER.getFilterRequestCourier(''));
      dispatch(COURIER.allCourier());
    }
  };

  const onChangeLocationM = (e) => {
    const location = address_info.himsNcrCity.find((item) => item.label === e.target.value);
    if (location) {
      // 3937
      setSearchLocation(location.name);
      dispatch(MESSENGER.getLocationRequestMessenger(location.id));
      dispatch(MESSENGER.setPaginationPageMessenger(1));
      dispatch(MESSENGER.allMessenger());
    } else {
      // 3937
      setSearchLocation("All Locations");
      dispatch(MESSENGER.getLocationRequestMessenger(''));
      dispatch(MESSENGER.allMessenger());
    }
  };

  const onChangeLocationC = (e) => {
    const location = address_info.himsProvinces.find((item) => item.label === e.target.value);

    if (location) {
      setSearchLocation(location.name);
      dispatch(COURIER.getLocationRequestCourier(location.id));
      dispatch(COURIER.setPaginationPageCourier(1, true));
      dispatch(COURIER.allCourier());
    } else {
      setSearchLocation("All Locations");
      dispatch(COURIER.getLocationRequestCourier('', true));
      dispatch(COURIER.allCourier());
    }
  };

  const onChangeCourier = (e) => {
    const courier = courier_info.couriers.find((item) => item.courier_name === e.target.value);

    if (courier) {
      setCourier(courier.courier_name);
      const as = dispatch(COURIER.getFilterRequestCourier(courier.courier_name));
      dispatch(COURIER.setPaginationPageCourier(1, true));
      dispatch(COURIER.allCourier());
    } else {
      setCourier("All Courier");
      dispatch(COURIER.getFilterRequestCourier(''));
      dispatch(COURIER.allCourier());
    }
  };

  const onSearchMessengerClick = (filter) => {
    // 3937
    if (searchMessenger.length) {
      dispatch(MESSENGER.getFilterRequestMessenger(searchMessenger));
      dispatch(MESSENGER.setPaginationPageMessenger(1));
      dispatch(MESSENGER.allMessenger());
    } else if (!searchMessenger.length) {
      dispatch(MESSENGER.getFilterRequestMessenger(''));
      dispatch(MESSENGER.allMessenger());
    }
  };

  const onSearchCourierClick = (filter) => {
    if (searchCourier.length) {
      dispatch(COURIER.getFilterRequestCourier(searchCourier));
      dispatch(COURIER.setPaginationPageCourier(1, true));
      dispatch(COURIER.allCourier());
    } else if (!searchCourier.length) {
      dispatch(COURIER.getFilterRequestCourier(''));
      dispatch(COURIER.allCourier());
    }
  };

  const handleCloseMessenger = (data) => {
    setOpenMessenger(data);
    dispatch(MESSENGER.allMessenger());
  };

  const handleCloseCourier = (data) => {
    setOpenCourier(data);
    dispatch(COURIER.allCourier());
  };

  const handleCloseNewMessenger = (data) => {
    setOpenNewMessenger(data);
    dispatch(MESSENGER.allMessenger());
  };

  const handleCloseNewCourier = (data) => {
    setOpenNewCourier(data);
    dispatch(COURIER.allCourier());
  };

  const onClickNewMessenger = (data) => {
    setOpenNewMessenger(data);
  };

  const onClickNewCourier = (data) => {
    // 590
    if (!address_info.himsAllCities) dispatch(ADDRESS.getHimsAllCities());
    setOpenNewCourier(data);
  };

  const [location, setLocation] = useState([
    {
      city: '',
      barangary: [],
    },
  ]);
  const [searchLocation, setSearchLocation] = useState('');
  const [courier, setCourier] = useState([{ courier: '' }]);

  return (
    <Layout>
      <Grid container className={classes.messengerWrapper}>
        <Grid item sm={12}>
          <Title>{buttonActive === 'messenger' ? 'Messenger' : 'Courier'}</Title>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 30 }}>
        <Grid item sm={6} style={{ display: 'flex', width: '100%' }}>
          <div>
            <ButtonGroup
              aria-label="outlined button group"
              style={{ backgroundColor: 'white' }}
              className={classes.messengerBtnGroup}
            >
              <Button
                name="messenger"
                disabled={buttonActive === 'messenger' ? true : false}
                variant={buttonActive === 'messenger' ? 'contained' : null}
                color="secondary"
                onClick={(event) => handleChange(event.currentTarget.getAttribute('name'))}
                data-cy="messenger"
              >
                Messenger
              </Button>
              <Button
                name="courier"
                disabled={buttonActive === 'courier' ? true : false}
                variant={buttonActive === 'courier' ? 'contained' : null}
                color="secondary"
                onClick={(event) => handleChange(event.currentTarget.getAttribute('name'))}
                data-cy="courier"
              >
                Courier
              </Button>
            </ButtonGroup>
          </div>
        </Grid>
        <Grid className={classes.btnWrapper} item sm={6}>
          {buttonActive === 'messenger' ? (
            /** 2420 - adding validation in button before the user can click it. */
            <PrimaryButton disabled={messenger_info.reqLoading} datacy="new_messenger_btn" onClick={() => onClickNewMessenger(true)}>
              New Messenger<AddIcon style={{ fontSize: '1rem' }} />{' '}
            </PrimaryButton>
          ) : (
            <PrimaryButton disabled={courier_info.reqLoading} datacy="new_courier_btn" onClick={() => onClickNewCourier(true)}>
              New Courier <AddIcon style={{ fontSize: '1rem' }} />{' '}
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {buttonActive === 'messenger' ? (
          <>
          { messenger_info.reqLoading ? (
            <Loading />
          ) : (
            <>
                <Grid container className={classes.btnHolder}>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={6}>
                    <div className={classes.searchWrapper}>
                      <TextField
                        style={{ marginBottom: '0' }}
                        id="outlined-start-adornment"
                        className="search-field"
                        placeholder="Search..."
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            onSearchMessengerClick();
                          }
                        }}
                        onChange={(e) => messengerSearchHandler(e.target.value)}
                        variant="outlined"
                        inputProps={{ 'data-cy': 'search' }}
                        value={searchMessenger}
                      />
                      <Button className={classes.search} color="secondary" onClick={onSearchMessengerClick} />
                    </div>
                    <div className={classes.marginLeft}>
                      <NativeSelect
                        id="allLocation"
                        input={<BootstrapInput />}
                        value={searchLocation}
                        onChange={onChangeLocationM}
                      >
                        {address_info.himsNcrCityLabel.map((res) => (
                          <option name={res} value={res} key={res}>
                            {res}
                          </option>
                        ))}
                      </NativeSelect>
                    </div>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  {messenger_info.request ? (
                    <MessengerTable
                      columns={messengerColumn}
                      data={messenger_info.request}
                      onRowClick={(rowData) => dialogOpenMessenger(rowData)}
                      actions={messengerAction}
                      components={messengerTable}
                    />
                  ) : (
                    <Loading />
                  )}
                </Grid>
              </>
          )
          }
          </>
        ) : (
          <>
            {/* 4629 */}
            { courier_info.reqLoading ? 
             (
              <Loading />
            ) : (
              <>
                <Grid container className={classes.btnHolder}>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={6}>
                    <div className={classes.searchWrapper}>
                      <TextField
                        style={{ marginBottom: '0' }}
                        id="outlined-start-adornment"
                        className="search-field"
                        placeholder="Search..."
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            onSearchCourierClick();
                          }
                        }}
                        onChange={(e) => courierSearchHandler(e.target.value)}
                        variant="outlined"
                        inputProps={{ 'data-cy': 'search' }}
                        value={searchCourier}
                      />
                      <Button className={classes.search} onClick={onSearchCourierClick} />
                    </div>
                    <div className={classes.marginLeft}>
                      <NativeSelect
                          id="allLocation"
                          input={<BootstrapInput />}
                          value={searchLocation}
                          onChange={onChangeLocationC}
                        >
                          {
                            address_info.courierAddress.initialProvinceLabels ? (
                              address_info.courierAddress.initialProvinceLabels.map((res, index) => (
                                <option name={res} value={res} key={index}>
                                  {res}
                                </option>
                              ))
                            ) : (
                              <option value={'All Locations'}>All Locations</option>
                            )
                          }
                      </NativeSelect>
                    </div>
                    <div className={classes.marginLeft}>
                      <NativeSelect
                          id="allCourier"
                          input={<BootstrapInput />}
                          value={courier}
                          onChange={onChangeCourier}
                        >
                          {
                            courier_info.couriersLabel ? (
                              courier_info.couriersLabel.map((res, index) => (
                                <option name={res} value={res} key={index}>
                                  {res}
                                </option>
                              ))
                            ) : (
                              <option value={'All Couriers'}>All Couriers</option>
                            )
                          }
                      </NativeSelect>
                    </div>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  {courier_info.request ? (
                    <CourierTable
                      columns={courierColumn}
                      data={courier_info.request}
                      onRowClick={(rowData) => dialogOpenCourier(rowData)}
                      actions={courierAction}
                      components={courierTable}
                    />
                  ) : (
                    <Loading />
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      {openMessenger && <EditMessenger close={handleCloseMessenger} open={openMessenger} classes={classes} />}
      {openCourier && <EditCourier close={handleCloseCourier} open={openCourier} classes={classes} />}

      { openNewMessenger ?
        <NewMessenger
          close={handleCloseNewMessenger}
          open={openNewMessenger}
          location={location}
          classes={classes}
          setLocation={setLocation}
        /> : null
      }
      
      {openNewCourier && (
        <NewCourier
          close={handleCloseNewCourier}
          open={openNewCourier}
          location={location}
          classes={classes}
          setLocation={setLocation}
        />
      )}
    </Layout>
  );
};

Messenger.propTypes = {
  value: PropTypes.any,
  columnDef: PropTypes.any,
  action: PropTypes.any,
  data: PropTypes.any,
  onOrderChange: PropTypes.any,
};

export default Messenger;
