/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Layout from '../components/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PropTypes from 'prop-types';
import { TablePagination, TableCell } from '@material-ui/core';
import SystemAdminTable from '../components/common/CommonTable/SystemAdminTable';
import AddIcon from '@material-ui/icons/Add';
import PrimaryButton from '../components/common/Button/PrimaryButton';
import Title from '../components/common/Title';
import EditSystemAdmin from '../components/dds/EditSystemAdmin';
import NewSystemAdmin  from '../components/dds/NewSystemAdmin';
import { useDispatch, useSelector } from 'react-redux';
import * as ADMIN from '../store/actions/SystemAdminActions';
import * as ACTION_TYPES from '../store/actions/action_types';
import Alert from '@material-ui/lab/Alert';
import * as REQ_TYPE from '../utils/Constants';
import CommonSelect from '../components/common/CommonSelect/CommonSelect';
import Loading from '../components/common/Loading';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  submit: {
    height: '40px',
    width: '165px',
    margin: theme.spacing(1),
    marginTop: '20px',
  },
  circle: {
    height: '16px !important',
    color: 'green',
    margin: '4px 40px',
    width: '16px !important',
  },
  circle2: {
    height: '16px !important',
    color: 'green',
    margin: '4px 15px  !important',
    width: '16px !important',
  },
  messengerWrapper: {
    display: 'flex',
  },
  alert: {
    padding: '6px 8px 6px 8px',
    width: 'fit-content',
    '& .MuiAlert-message': {
      padding: '0',
    },
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionWrapper: {
    display: 'flex',
  },
  btnHolder: {
    marginTop: ' 24px',
    marginBottom: '16px',
  },
  itemWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E6ED',
    marginBottom: '19px',
    '& button': {
      border: 'none',
      color: '#41B67F',
    },
  },
  header: {
    fontWeight: '900',
    fontSize: '28px',
    lineHeight: '1.8',
    marginBottom: '24px',
  },
  itemContainer: {
    border: ' 1px solid #E0E6ED',
    padding: '24px 40px',
    marginBottom: '40px',
  },
  itemHolder: {
    maxWidth: '75%',
  },
  assignHolder: {
    margin: '20px 0',
  },
  selectItem: {
    '&  .MuiSelect-selectMenu': {
      fontSize: '16px',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '16px',
    },
  },
  pass: {
    color: '#2F3542',
    margin: '8px 0',
    fontSize: '16px',
    maxWidth: '165px',
    fontWeight: 600,
    border: 'none',
    pointerEvents: 'none',
    lineHeight: 1.3,
    backgroundColor: '#fff !important',
  },
  title: {
    color: 'rgba(47, 53, 66, 0.8)',
    fontSize: '16px',
    lineHeight: '1.3',
    margin: '8px 0',
  },
  subTitle: {
    fontWeight: '800',
    fontSize: '18px',
    lineHeight: '1.8',
    color: '#2F3542',
  },
  description: {
    maxWidth: '250px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '1.3',
    color: '#2F3542',

    wordBreak: 'break-all',

    margin: '8px 0',
  },
  btnReset: {
    marginTop: '22px',
  },
  boxNo: {
    margin: '10px 0',
    '& .MuiInputLabel-root': {
      color: 'rgba(43, 45, 51, 0.8)',
      margin: '0',
      fontSize: '14px',
      lineHeight: '13px',
      marginBottom: '7px',
    },
  },
  boxNos: {
    margin: '30px 0 0',
    '& .MuiInputLabel-root': {
      color: 'rgba(43, 45, 51, 0.8)',
      margin: '0',
      fontSize: '14px',
      lineHeight: '13px',
      marginBottom: '7px',
    },
  },
  disabledInput: {
    background: 'gray !important',
    color: 'black',
  },
  removeAssign: {
    cursor: 'pointer',
  },
  editItemContainer: {
    borderTop: 'none',
    border: '1px solid #E0E6ED',
    padding: '24px 40px',
    background: '#F4F6F9',
  },
  editHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deactivateBtn: {
    width: '100%',
    color: '#FA5656',
    textAlign: 'center',
    padding: '24px',
    border: '1px solid #E0E6ED',
    borderTop: 'none',
    marginBottom: '40px',
  },
  editItemWrapper: {
    padding: '25px 40px 12px',
    width: '100%',
    border: '1px solid #E0E6ED',
    borderBottom: 'none',
    lineHeight: '1.8',
    '& button': {
      border: 'none',
      color: '#41B67F',
    },
    MuiList: {
      root: {
        fontSize: '20px',
      },
    },
  },
  btnDivider: {
    marginBottom: '14px',
  },
  btnLeft: {
    marginLeft: '16px',
    minWidth: '86px',
    minHeight: '40px',
    padding: '8px 24px !important',
  },
  addMore: {
    fontWeight: 600,
    marginTop: '15px',
    background: '#fff',
    cursor: 'pointer',
    padding: '10px',
    alignItems: 'center',
    width: '100%',
    border: ' 1px solid #E0E6ED',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    borderRadius: '20px',
    height: '16px',
    width: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(65, 182, 127)',
    border: '1px solid rgb(65, 182, 127)',

    '& svg': {
      fontSize: '18px',
    },
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchWrapper: {
    position: 'relative',
    '& input': {
      height: '18px',
      padding: '10px 30px 10px 12px',
    },
  },
  search: {
    cursor: 'pointer',
    right: 0,
    height: '40px',
    minWidth: '36px',
    position: 'absolute',
  },
  loadingWidth: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: 10,
  },
  formControl: {
    minWidth: 250,
  },
  btnRight: {
    textAlign: 'right',
  },
  errorBox: {
    marginTop: '5px',
    color: '#FA5656',
    fontSize: '0.8571428571428571rem',
    display: 'block',
  },
  showPrint: {
    display: 'block',
  },
  hidePrint: {
    display: 'none',
  },
  errorBoxNone: {
    display: 'none',
  },
  reseton: {
    marginTop: '5px',
    color: 'rgba(47, 53, 66, 0.8)',
    fontSize: '0.8571428571428571rem',
    display: 'block',
  },
  disNone: {
    display: 'none',
  },
  resetonNone: {
    display: 'none',
  },
  boxError: {
    position: 'relative',
  },
  limit: {
    color: 'red',
  },
  input: {
    maxWidth: '250px',
    '& label': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    },
    '& .MuiInput-underline::before': {
      border: 'none',
    },
    '& .MuiInput-underline::after': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '22px',
    },
    '& input': {
      background: '#fff',
      width: '100%',
      height: '40px',
      padding: '0 16px',
      border: '1px solid #A5B0BE',
      borderRadius: '4px',
    },
  },
  lockText: {
    fontWeight: 'bold',
    color: '#41B67F',
    cursor: 'pointer',
  },
  lockModalContent: {
    width: '500px',
    padding: '50px',
    '&:first-child': {
      paddingTop: '24px',
    },
  },
  lockModalClose: {
    border: '1px solid rgba(47, 53, 66, 0.4)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#2f3542',
    },
  },
  lockModalTitle: {
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 'bold',
  },
  lockModalText: {
    margin: '26px 0 42px',
    fontSize: '15px',
    lineHeight: '22px',
  },
  lockModalBtn: {
    textAlign: 'right',
  },
}));

const SystemAdmin = () => {
  const dispatch = useDispatch();
  const admin_info = useSelector((state) => state.sysadmin);

  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showUnlockName, setShowUnlockName] = useState({});

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.SYSTEMADMIN_STATUS,
      payload: '',
    });
    
    // 3434
    dispatch(ADMIN.clearFilterSystemAdmin());
    dispatch(ADMIN.setPaginationPageAdmin(1, false));
    dispatch(ADMIN.setPageLimitAdmin(10));
    dispatch(ADMIN.allSystemAdmin());
    dispatch(ADMIN.showAllSystemAdmin());
    dispatch(ADMIN.getHimsAuth())
    // eslint-disable-next-line
  }, [dispatch]);



  const tableColumn = [
    {
      title: 'Name',
      field: 'name',
    },
    { title: 'Username', field: 'username' },
    { title: 'Email', field: 'email' },
    {
      title: 'Status',
      field: 'active',
    }
  ];


  const activeStatus = [
    'All',
    'Active',
    'Deactivated',
  ];

  const lockModal = (data) => {
    setShowUnlockName(data);
    setShowUnlockModal(!showUnlockModal);
  };

  const dialogOpen = (data) => {
    dispatch(ADMIN.getSystemAdminDetails(data.id, setOpen, false));
  };

  const dialogEditOpen = (data) => {
    dispatch(ADMIN.getSystemAdminDetails(data.id, setOpen, true));
  };

  const tableAction = [
    {
      icon: 'edit',
      tooltip: 'edit',
      onClick: (event, rowData) => dialogEditOpen(rowData),
    },
    {
      icon: 'lock',
      tooltip: 'lock',
      onClick: (event, rowData) => {
        // 205 - removed
        //onSubmitUnlockAdmin(rowData)
        lockModal(rowData) 
      },
    },
  ];


  const componentsTable = {
    Cell: (props) => {
      let data = [];
      let capacityLimit = false;

      return props.value === true && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <div className="custom-alert-info">
            <Alert className={classes.alert} severity="success">
              {REQ_TYPE.ACTIVE_STATUS[0].text}
            </Alert>
          </div>
        </TableCell>
      ): props.value === false && props.columnDef.title === 'Status' ? (
        <TableCell>
          {' '}
          <div className="custom-alert-info">
            <Alert className={classes.alert} severity="info">
              {REQ_TYPE.ACTIVE_STATUS[2].text}
            </Alert>
          </div>
        </TableCell>
      ) : (
        <TableCell>{props.value}</TableCell>
      );
    },
    Action: (props) => {
      if (props.action.icon === 'edit') {
        return (
          <Button
            onClick={(event) => {
              props.action.onClick(event, props.data);
              event.stopPropagation();
            }}
            color="primary"
            variant="contained"
            style={{
              textTransform: 'none',
              minWidth: '50px',
              padding: '4px 0',
            }}
            size="medium"
            data-cy="edit_btn"
          >
            Edit
          </Button>
        );
      }
      if(props.data.status === true){
        if (props.action.icon === 'lock') {
          return (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
              }}
              color="primary"
              variant="contained"
              style={{
                textTransform: 'none',
                minWidth: '50px',
                padding: '4px 0',
              }}
              size="medium"
              data-cy="edit_btn"
            >
              <LockOpenIcon />   
            </IconButton>
          );
        }
      }else{
        if (props.action.icon === 'lock') {
          return (
            <IconButton
              onClick={(event) => {
              props.action.onClick(event, props.data);
              event.stopPropagation();
              }}
              color="primary"
              variant="contained"
              style={{
                textTransform: 'none',
                minWidth: '50px',
                padding: '4px 0',
              }}
              size="medium"
              data-cy="edit_btn"
            >
              <LockIcon />
            </IconButton>
          );
        }
      }
      
    },
    Pagination: (props) => (
      <>
        <TablePagination {...props} rowsPerPageOptions={[10, 25, 100]} />
      </>
    ),
  };


  const searchHandler = (filter) => {
    setSearch(filter);
    if (!filter.length) {
      dispatch(ADMIN.getFilterRequestAdmin(''));
      dispatch(ADMIN.showAllSystemAdmin());
    }
  };

  const onChangeStatus = (e) => {
    let status = ''
    if(e.target.value === 'All'){
      status = ''
      dispatch(ADMIN.getStatusRequestAdmin(status));
      dispatch(ADMIN.setPaginationPageAdmin(1, false));
      dispatch(ADMIN.showAllSystemAdmin());
    }else if(e.target.value === 'Active'){
      status = true
      dispatch(ADMIN.getStatusRequestAdmin(status));
      dispatch(ADMIN.setPaginationPageAdmin(1, false));
      dispatch(ADMIN.showAllSystemAdmin());
    }else if (e.target.value === 'Deactivated'){
      status = false
      dispatch(ADMIN.getStatusRequestAdmin(status));
      dispatch(ADMIN.setPaginationPageAdmin(1, false));
      dispatch(ADMIN.showAllSystemAdmin());
    }else{
      status = ''
      dispatch(ADMIN.getStatusRequestAdmin(''));
      dispatch(ADMIN.showAllSystemAdmin());
    }
  };

  const onSearchClick = (filter) => {
    if (search.length) {
      dispatch(ADMIN.getFilterRequestAdmin(search));
      dispatch(ADMIN.setPaginationPageAdmin(1, false));
      dispatch(ADMIN.showAllSystemAdmin());
    } else if (!search.length) {
      dispatch(ADMIN.getFilterRequestAdmin(''));
      dispatch(ADMIN.showAllSystemAdmin());
    }
  };

  const onSubmitUnlockAdmin = (filter) => {
      if(showUnlockName){
        dispatch(ADMIN.updateSystemAdminUnlock(showUnlockName));
        setShowUnlockModal(!showUnlockModal);
       // ticket 521
       // dispatch(ADMIN.showAllSystemAdmin());
       }
    }

  const handleClose = (data, resid, open) => {
    if(!open){
      //dispatch(ADMIN.getSystemAdminDetails(resid, setOpen, false));
      dispatch(ADMIN.showAllSystemAdmin());
    }else if(resid){
      dispatch(ADMIN.getSystemAdminDetails(resid, setOpen, true));
    }
    setOpen(data);
  };

  const handleCloseNew = (data, resid) => {
    if(resid){
      dispatch(ADMIN.getSystemAdminDetails(resid, setOpen, false));
    }
    dispatch(ADMIN.showAllSystemAdmin());
    setOpenNew(data);
  };

  const onClickNew = (data) => {
    setOpenNew(data);
  };

  return (
    <Layout>
      <Grid item className={classes.messengerWrapper} sm={12}>
        <Grid item sm={6}>
          <Title>System Administrator</Title>
        </Grid>
        <Grid className={classes.btnWrapper} item sm={6}>
          <PrimaryButton datacy="new_adminassist_btn" onClick={() => onClickNew(true)}>
            Add System Admin<AddIcon style={{ fontSize: '1rem' }} />{' '}
          </PrimaryButton>
        </Grid>
      </Grid>
      <Grid container className={classes.btnHolder}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={6}>
          <div className={classes.searchWrapper}>
            <TextField
              style={{ marginBottom: '0' }}
              id="outlined-start-adornment"
              className="search-field"
              placeholder="Search..."
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  onSearchClick();
                }
              }}
              onChange={(e) => searchHandler(e.target.value)}
              variant="outlined"
              inputProps={{ 'data-cy': 'search' }}
            />
            <Button className={classes.search} onClick={onSearchClick} />
          </div>
          <div className={classes.marginLeft}>
            <CommonSelect
              id="status"
              item={activeStatus}
              onChange={onChangeStatus}
              datacy="status_filter"
            />
          </div>
        </Grid>
      </Grid>
      {!admin_info.reqLoading ? (
        <SystemAdminTable
          columns={tableColumn}
          data={admin_info.request}
          onRowClick={(rowData) => dialogOpen(rowData)}
          actions={tableAction}
          components={componentsTable}
        />
      ) : (
        <Loading />
      )}
      {open && <EditSystemAdmin close={handleClose} open={open} classes={classes} />}
      {openNew && (
        <NewSystemAdmin
          close={handleCloseNew}
          open={openNew}
          classes={classes}
        />
      )}
       <Dialog
        open={showUnlockModal}
        onClose={lockModal}
        aria-labelledby="unlock-user-modal"
        disableBackdropClick
        data-cy="unlock-user-modal"
      >
        <DialogContent className={classes.lockModalContent}>
          <div className={classes.lockModalBtn}>
            <IconButton
              aria-label="close-forgot-pass-modal"
              className={classes.lockModalClose}
              onClick={lockModal}
            >
              <ClearSharpIcon fontSize="small" />
            </IconButton>
          </div>
          <Typography style={{ fontSize: '28px', fontWeight: 'bold' }} component="h1" variant="h1">
            Unlock Account
          </Typography>
          <div style={{ marginTop: '20px' }}></div>
  
          <Typography style={{ fontSize: '13px', fontWeight: 'italic' }}>
                Are you sure you want to unlock {showUnlockName.name}'s account? Click confirm to proceed.
          </Typography>
          <div style={{ marginTop: '50px' }}>
          </div>
          <form className={classes.form} onSubmit={(e) => onSubmitUnlockAdmin(e)}>
          <Button
                variant="contained"
                color="primary"
                onClick={lockModal}
                className={classes.submit}
                variant="outlined"
                data-cy="cancel_unlock"
                >
                Cancel
                </Button>

                <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                data-cy="submit_unlock"
                >
                Confirm
                </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

SystemAdmin.propTypes = {
  value: PropTypes.any,
  columnDef: PropTypes.any,
  action: PropTypes.any,
  data: PropTypes.any,
  onOrderChange: PropTypes.any,
};

export default SystemAdmin;
