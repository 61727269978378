import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../containers/Login';
import PropTypes from 'prop-types';
import { rolesConfig } from '../config/roles';
import * as ConfigRoutes from './index';
import { uniqBy } from 'lodash';

const RouteBlock = (props) => {
  const userInfo = useSelector((state) => state.auth);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      
      let roles = JSON.parse(localStorage.getItem('roles'));
      if (roles) {
        roles = [...roles];
        
        let allowedRoutes = roles.reduce((acc, role) => {
          return [...acc, ...rolesConfig[role].routes];
        }, []);
        // For removing duplicate entries, compare with 'url'.
        allowedRoutes = uniqBy(allowedRoutes, 'url');
        setAllowedRoutes(allowedRoutes);
      // console.log(allowedRoutes);
      } else {
        navigate('/');
      }
    },[userInfo]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      
      {allowedRoutes.map((route) => {
        return (
        <Route
          key={route.url}
          path={`${route.url}`}  // Use route.url directly
          Component={ConfigRoutes[route.component]}
        />
      )})}
    </Routes>
  );
};

RouteBlock.propTypes = {
  match: PropTypes.object,
};

export default RouteBlock;