import React from 'react';
import PropTypes from 'prop-types';
import CommonDialog from '../../components/common/CommonDialog';
import CourierUpdateContent from './CourierUpdateContent.jsx';

const UpdateCourier = ({ openUpdateCourier, close, reassign, buttonActive, closeOnCourier }) => {
  return (
    <CommonDialog
      reassign={true}
      dialogType='Update Status'
      close={close}
      open={openUpdateCourier}
      datacy="reassign_to_modal"
    >
      <CourierUpdateContent
        buttonActive={buttonActive}
        reassign={reassign}
        close={close}
        closeOnCourier={closeOnCourier}
      />
    </CommonDialog>
  );
};

UpdateCourier.propTypes = {
  openUpdateCourier: PropTypes.any,
  close: PropTypes.any,
  reassign: PropTypes.any,
  buttonActive: PropTypes.any,
};

export default UpdateCourier;
