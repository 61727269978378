import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../containers/Login';
import ForgotPassword from '../components/home/ForgotPassword/ForgotPassword';

const PublicRoutes = ({ match }) => (
  <Routes>
    {/* <Route path="/register" element={<Register />} /> */}
    <Route path="/forgot-password/:token" element={<ForgotPassword />} />
    <Route path="/update-password/:token" element={<ForgotPassword />} />
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<Login />} />
    {/* <Route path="*" element={<NotFound />} /> */}
  </Routes>
);

PublicRoutes.propTypes = {
  match: PropTypes.any,
};

export default PublicRoutes;
