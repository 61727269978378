import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Form } from 'react-final-form'
import { makeValidate, TextField } from 'mui-rff'
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import Layout from '../components/Layout';
import Title from '../components/common/Title';
import * as ACTION from '../store/actions/authActions';
import { clearErrors } from '../store/actions/errorActions';
import PropTypes from 'prop-types'
import DialogConfirm from '../components/common/DialogConfirm/DialogAccountConfirm';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
  },
  paperHeader: {
    padding: theme.spacing(0.5),
    textAlign: 'left',
    color: theme.palette.text.primary,
    paddingLeft: '4%',
    borderWidth: '0px 0px 1px 0px !important',
    borderRadius: '4px 4px 0px 0px',
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'left',
    color: theme.palette.text.primary,
    border: '0px !important',
  },
  topMargin: {
    marginTop: '25px',
  },
  rowItem: {
    padding: theme.spacing(2),
    textAlign: 'left',
    marginLeft: '5%',
  },
  updateEmailButton : {
    marginBottom: '40px',
  },
  ModalClose: {
    float: 'right',
    marginRight: '5%',
    border: '1px solid rgba(47, 53, 66, 0.4)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#2f3542',
    },
  },
  GridPadding: {
     paddingLeft: '5%',
  },
  GridManageAccount: {
    margin: '0',
    marginTop: '3%',
    border: '1px solid #e0e6ed',
    borderRadius: '4px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginLeft: theme.spacing(5),
  },
  submit: {
    height: '40px',
    width: '150px',
    marginTop: '20px',
    marginRight: theme.spacing(2),
  },
  passwordField: {
    position: 'relative',
    '& input': {
      paddingRight: 40,
    },
  },
  icon: {
    position: 'absolute',
    top: 41,
    right: 14,
    transform: 'translateY(-50%)',
    lineHeight: 1,
    display: 'inline-block',
    cursor: 'pointer',
    height: 22,
    '& svg': {
      fontSize: 22,
      color: '#2F3542',
    },
  },
}));

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const formSchemaEmailUpdate = Yup.object().shape({
  current_email: Yup.string().test('validateCurrentEmail', 'Invalid email format', function(value) {
    return value && !value.match(emailRegex) ? false : true;
  }),
  new_email: Yup.string().test('validateNewEmail', 'Invalid email format', function(value) {
    return value && !value.match(emailRegex) ? false : true;
  }),
});

const initialValuesEmail = {
  current_email: '',
  new_email: '',
  current_password: '',
};

const initialValuesPassword = {
  current_password: '',
  new_password: '',
  confirm_new_password: '',
};

function Account({ close }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);
  const auth = useSelector((state) => state.auth);

  const [updateUI, setupdateUI] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [isPassword, setIsPassword] = useState(true);
  const [isCurrentPassHidden, setIsCurrentPassHidden] = useState(true);
  const [isNewPassHidden, setIsNewPassHidden] = useState(true);
  const [isConfirmPassHidden, setIsConfirmPassHidden] = useState(true);
  const [isMismatchPassword, setIsMismatchPassword] = useState(false);
  // 220 - display modal when there is changes on the fields.
  const [onChange, setonChange] = useState(false);
  const [open, setOpen] = useState(false);

  const togglePassword = () => {
    setIsPassword(!isPassword);
  };
 
  const toggleCurrentPassword = () => {
    setIsCurrentPassHidden(!isCurrentPassHidden);
  };
  
  const toggleNewPassword = () => {
    setIsNewPassHidden(!isNewPassHidden);
  };

  const toggleConfirmPassword = () => {
    setIsConfirmPassHidden(!isConfirmPassHidden);
  };

  const onButtonEmailClick = (e) => {
    // 4792
    dispatch(ACTION.pageCheck());
    // 220 
    setonChange(false);
    e.preventDefault();
    setupdateUI('Email');
  };

  const onButtonPasswordClick = (e) => {
    // 4792
    dispatch(ACTION.pageCheck());
    // 220 
    setonChange(false);
    e.preventDefault();
    setupdateUI('Password');
  };

  const resetForms = () => {
    dispatch(ACTION.setCurrentEmail(''));
    dispatch(ACTION.setNewEmail(''));
    dispatch(ACTION.setCurrentPasswordValue(''));
    setIsPassword(true);
    setIsCurrentPassHidden(true);
    setIsNewPassHidden(true);
    setIsConfirmPassHidden(true);
    setIsMismatchPassword(false);
    dispatch(ACTION.setCurrentPassword(null));
    dispatch(ACTION.setNewPassword(null));
    dispatch(ACTION.setConfirmPassword(null));
  };

  const handleClose = (e) => {
    // ticket 220
    if (onChange) {
      setOpen(true);
    } else {
      e.preventDefault();
      setupdateUI('');
      resetForms();
      dispatch(clearErrors());
    }
  };

  const handleLeave = () => {
    e.preventDefault();
    setupdateUI('');
    resetForms();
    dispatch(clearErrors());
  }

  const onSubmitEmail = (values) => {
    dispatch(ACTION.updateEmail({
        current_email: values.current_email,
        new_email: values.new_email,
        current_password: values.current_password
      }));
    dispatch(clearErrors());
  };

  const handleChangeCurrentEmail = (value) => {
    dispatch(ACTION.setCurrentEmail(value));
  }

  const handleChangeNewEmail = (value) => {
    dispatch(ACTION.setNewEmail(value));
  }

  const handleChangeCurrentPasswordValue = (value) => {
    dispatch(ACTION.setCurrentPasswordValue(value));
  }

  const handleChangeCurrentPassword = (value) => {
    dispatch(ACTION.setCurrentPassword(value));
  };

  const handleChangeNewPassword = (value) => {
    dispatch(ACTION.setNewPassword(value));
  };

  const handleChangeConfirmPassword = (value) => {
    dispatch(ACTION.setConfirmPassword(value));
  };
  
  const onSubmitPassword = (values) => {
    const user_id = localStorage.getItem('user_id');
    const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])(?=.{8,})/);
		const isOk = re.test(values.new_password);

    if (isOk) {
      const resetpassUser = {
        password: values.new_password,
        currentpassword: values.current_password,
        user_id,
      };
   
      dispatch(ACTION.changepassword(resetpassUser));
      dispatch(clearErrors());
    } else {
      if (!values.new_password.match(/^(?=.{8,})/)) {
        toast.error('New password must be at least 8 characters.');
      }
      if (!values.new_password.match(/^(?=.*[A-Z])/)) {
        toast.error('New password must contain at least one (1) uppercase.');
      }
      if (!values.new_password.match(/^(?=.*[a-z])/)) {
        toast.error('New password must contain at least one (1) lowercase.');
      }
      if (!values.new_password.match(/^(?=.*[0-9])/)) {
        toast.error('New password must contain at least one (1) numeric.');
      }
      if (!values.new_password.match(/^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])/)) {
        toast.error('New password must contain at least one (1) special character.');
      }
    }
  };

  useEffect(() => {
    if (auth.user && auth.user.email) {
      const emailAdd = auth.user.email.split('@');
      setEmailDomain(emailAdd[1] || '');
    }
  }, [auth]);

  useEffect(() => {
    if (auth.changePassword.newPassword && auth.changePassword.confirmPassword) {
      if (auth.changePassword.newPassword !== auth.changePassword.confirmPassword) {
        setIsMismatchPassword(true);
      } else {
        setIsMismatchPassword(false);
      }
    }
  }, [auth.changePassword.newPassword, auth.changePassword.confirmPassword]);

  useEffect(() => {
    if (auth.changePassword.isChangePwdSuccess || auth.changeEmail.isChangeEmailSuccess) {
      setupdateUI('');
      resetForms();
      dispatch(clearErrors());
      dispatch(ACTION.setChangePwdSuccess(false));
      dispatch(ACTION.setChangeEmailSuccess(false));
    }
  }, [auth.changePassword.isChangePwdSuccess, auth.changeEmail.isChangeEmailSuccess])

  useEffect(() => {
    if (error && error.id === 'EMAIL_UPDATE_FAIL') {
      toast.error(error.msg.message);
    } else if (error && error.id === 'PASSWORD_RESET_FAIL') {
      toast.error(error.msg.message);
    }
  }, [error]);

  return (
    <Layout>
      { updateUI === 'Email' || updateUI === 'Password' ? (            
        <IconButton
          aria-label="close-forgot-pass-modal"
          className={classes.ModalClose}
          onClick={handleClose}
          >
          <ClearSharpIcon fontSize="small" />
        </IconButton>
        ) : (
          <NavLink to="/">
            <IconButton
                aria-label="close-forgot-pass-modal"
                className={classes.ModalClose}
                >
                <ClearSharpIcon fontSize="small" />
            </IconButton>
          </NavLink>
        )
      }      
      <div className={classes.topMargin}>
        <Title>Manage Account</Title>
      </div>
      {!updateUI ?
        <Grid container className={classes.GridPadding} spacing={0}>
          <Grid item xs={10} className={classes.GridManageAccount}>
            <Paper className={classes.paperHeader}>
              <span><b>Email and Password Manager</b></span>  
            </Paper>
            <Paper className={classes.paper}>
              <Grid item xs={4} className={classes.rowItem}>
                <span>Email Address</span>
              </Grid>
              <Grid item xs={3} className={classes.rowItem}>
                <span>****************{`@${emailDomain}`}</span>
              </Grid>
              <Grid item xs={3} className={`${classes.rowItem} ${classes.updateEmailButton}`}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  data-cy="submit_login"
                  onClick={onButtonEmailClick}
                >
                  Update Email
                </Button>
              </Grid>
              <Grid item xs={4} className={classes.rowItem}>
                <span>Current Password</span>
              </Grid>
              <Grid item xs={3} className={classes.rowItem}>
                <span>****************</span>
              </Grid>
              <Grid item xs={3} className={classes.rowItem}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  data-cy="submit_login"
                  onClick={onButtonPasswordClick}
                >
                  Update Password
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        : updateUI === 'Email' ?
        <Grid container className={classes.GridPadding}>
          <Grid item xs={10} className={classes.GridManageAccount}>
            <Paper className={classes.paperHeader}>
              <span><b>Update Email Address</b></span>
            </Paper>
            <Paper className={classes.paper}>
              <Grid item xs={5}>
                <Form
                  onSubmit={onSubmitEmail}
                  initialValues={initialValuesEmail}
                  validate={makeValidate(formSchemaEmailUpdate)}
                  render={ ({ handleSubmit, invalid, form }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item xs={10}>
                          <TextField
                            autoComplete='off'
                            label="Current Email Address"
                            variant="outlined"
                            fullWidth
                            name="current_email"
                            onChange={(e) => {
                              handleChangeCurrentEmail(e.target.value);
                              form.change('current_email', e.target.value);
                            }}
                            inputProps={{ 'data-cy': 'current_email' }}
                            />
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={10}>
                          <TextField
                            autoComplete='off'
                            label="New Email Address"
                            variant="outlined"
                            fullWidth
                            name="new_email"
                            onChange={(e) => {
                              setonChange(true)
                              handleChangeNewEmail(e.target.value);
                              form.change('new_email', e.target.value);
                            }}
                            inputProps={{ 'data-cy': 'new_email' }}
                          />
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={10}>
                          <div className={classes.passwordField}>
                            <TextField
                              autoComplete='off'
                              label="Password"
                              type={isPassword ? 'password' : 'text'} 
                              name="current_password"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                handleChangeCurrentPasswordValue(e.target.value);
                                form.change('current_password', e.target.value);
                              }}
                              inputProps={{
                                'data-cy': 'current_password',
                                autocomplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                            />
                            <span onClick={togglePassword} className={classes.icon}>
                              {/* 4729 */}
                              {!isPassword ? (
                                <VisibilityIcon data-cy="show_current_password" />
                              ) : (
                                <VisibilityOffIcon data-cy="hide_current_password" />
                              )}
                            </span>
                          </div>
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            color="primary"
                            className={classes.submit}
                            onClick={handleClose}
                            variant="outlined"
                            data-cy="submit_login"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={invalid
                              || !auth.changeEmail.currentEmail
                              || !auth.changeEmail.newEmail
                              || !auth.changeEmail.currentPasswordValue
                            }
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            data-cy="submit_login"
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                          </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container className={classes.GridPadding}>
          <Grid item xs={10} className={classes.GridManageAccount}>
            <Paper className={classes.paperHeader}>
              <span><b>Update Password</b></span>
            </Paper>
            <Paper className={classes.paper}>
              <Grid item xs={5}>
                <Form
                  onSubmit={onSubmitPassword}
                  initialValues={initialValuesPassword}
                  render={ ({ handleSubmit, form }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item xs={10}>
                          <div className={classes.passwordField}>
                            <TextField
                              autoComplete='off'
                              label="Current Password"
                              type={isCurrentPassHidden ? 'password' : 'text'} 
                              name="current_password"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                handleChangeCurrentPassword(e.target.value);
                                form.change('current_password', e.target.value);
                              }}
                              inputProps={{
                                'data-cy': 'current_password',
                                autocomplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                            />
                            <span onClick={toggleCurrentPassword} className={classes.icon}>
                              {/* 4729 */}
                              {!isCurrentPassHidden ? (
                                <VisibilityIcon data-cy="show_current_password" />
                              ) : (
                                <VisibilityOffIcon data-cy="hide_current_password" />
                              )}
                            </span>
                          </div>
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={10}>
                          <div className={classes.passwordField}>
                            <TextField
                              autoComplete='off'
                              label="New Password"
                              type={isNewPassHidden ? 'password' : 'text'} 
                              name="new_password"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                setonChange(true)
                                handleChangeNewPassword(e.target.value);
                                form.change('new_password', e.target.value);
                              }}
                              inputProps={{
                                'data-cy': 'new_password',
                                autocomplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                            />
                            <span onClick={toggleNewPassword} className={classes.icon}>
                              {/* 4729 */}
                              {!isNewPassHidden ? (
                                <VisibilityIcon data-cy="show_new_password" />
                              ) : (
                                <VisibilityOffIcon data-cy="hide_new_password" />
                              )}
                            </span>
                          </div>
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={10}>
                          <div className={classes.passwordField}>
                            <TextField
                              autoComplete='off'
                              error={isMismatchPassword}
                              helperText={isMismatchPassword ? 'New Password and Confirm New Password must match' : ''}
                              label="Confirm New Password"
                              type={isConfirmPassHidden ? 'password' : 'text'} 
                              name="confirm_new_password"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                setonChange(true)
                                handleChangeConfirmPassword(e.target.value);
                                form.change('confirm_new_password', e.target.value);
                              }}               
                              inputProps={{
                                'data-cy': 'confirm_new_password',
                                autocomplete: 'new-password',
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                            />
                            <span onClick={toggleConfirmPassword} className={classes.icon}>
                              {/* 4729 */}
                              {!isConfirmPassHidden ? (
                                <VisibilityIcon data-cy="show_confirm_new_password" />
                              ) : (
                                <VisibilityOffIcon data-cy="hide_confirm_new_password" />
                              )}
                            </span>
                          </div>
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={10}>
                          <Typography style={{ fontSize: '13px', fontWeight: 'italic' }}>
                            <i>Note: Password must be at least 8 characters in length, and must contain uppercase, lowercase, numeric and special characters.</i>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: '10px'}}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleClose}
                            variant="outlined"
                            data-cy="submit_login"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={!auth.changePassword.currentPassword
                              || !auth.changePassword.newPassword
                              || !auth.changePassword.confirmPassword
                              || isMismatchPassword}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            data-cy="submit_login"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>                            
                    </form>
                  )}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      }

      {/* ticket 220 */}
      <DialogConfirm
        setOpen={setOpen}
        open={open}
        close={close}
        setupdateUI={setupdateUI}
      />
    </Layout>
  );
};

Account.propTypes = {
  close: PropTypes.bool,
}

export default Account;