import React, { useState } from 'react';
import CommonDialog from '../common/CommonDialog';
import ViewAuditLog from '../dds/ViewAuditLog';
import PropTypes from 'prop-types';


const ViewAuditLogDialog = ({ classes, close, open,  }) => {
  const onClose = () => {
    close(false);
  };
    
  return (
    <>
      <CommonDialog close={onClose} classesPad="print-pad-none" open={open}>
        <ViewAuditLog close={close} classes={classes} />
      </CommonDialog>
    </>
  );
};

ViewAuditLogDialog.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
  open: PropTypes.bool,
};

export default React.memo(ViewAuditLogDialog);
