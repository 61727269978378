/* eslint-disable no-case-declarations */
/* eslint-disable no-extra-boolean-cast */
import * as ACTION from '../actions/action_types';

const initialState = {
  settingsDetails: null,
  changedSettings: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION.SETTINGS_DETAILS:
      return {
        ...state,
        settingsDetails: action.payload,
      };
    case ACTION.CHANGED_SETTINGS:
      return {
        ...state,
        changedSettings: action.payload,
      };
    default:
      return state;
  }
}
