import React, { useState } from 'react';
import CommonDialog from '../common/CommonDialog';
import EditSystemAdminComponent from './EditSystemAdminComponent';
import * as ADMIN from '../../store/actions/SystemAdminActions';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'


const EditSystemAdmin = ({ classes, close, open,  }) => {
  const dispatch = useDispatch();
  const admin_info = useSelector((state) => state.sysadmin);
  const onEdit = () => {
    if(!admin_info.onShowEdit){
        close(false)
      }else{
        dispatch(ADMIN.getSystemAdminDetails(admin_info.modal.id, false, false));
      }
      
  };
    
  return (
    <>
      <CommonDialog close={onEdit} classesPad="print-pad-none" open={open}>
        <EditSystemAdminComponent close={close} classes={classes} />
      </CommonDialog>
    </>
  );
};

EditSystemAdmin.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
  open: PropTypes.bool,
};

export default React.memo(EditSystemAdmin);
