/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { toast } from 'react-toastify';

import Check from '../assets/img/icons/check.png';
import Layout from '../components/Layout';
import Loading from '../components/common/Loading';
import PrimaryButton from '../components/common/Button/PrimaryButton';
import SecondaryButton from '../components/common/Button/SecondaryButton';
import Title from '../components/common/Title';
import SaveSettings from '../components/common/DialogSmall/SaveSettings';
import * as KPI from '../store/actions/kpiActions';
import * as API from '../utils/Constants';
import * as ACTION from '../store/actions/settingsActions';
import { axiosApiInstance } from '../utils/apiConfig';

import './styles/Settings.scss';

const useStyles = makeStyles((theme) => ({
  settingsWrapper: {
    marginTop: '10px',
  },
  messengerBtnGroup: {
    '& button': {
      padding: '5px 20px',
    },
    backgroundColor: 'white',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  input: {
    maxWidth: '250px',
    marginTop: '7px',
    '& label': {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      top: '10px',
      left: '90px',
      '&.Mui-disabled': {
        color: '#2F3542',
      },
    },
    '& .MuiInput-underline::before': {
      border: 'none',
    },
    '& .MuiInput-underline::after': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '0px',
    },
    '& input': {
      background: '#fff',
      width: '45px',
      height: '40px',
      padding: '0 16px',
      border: '1px solid rgba(16,22,26)',
      borderRadius: '4px',
      '&.Mui-disabled': {
        color: '#2F3542',
        border: '1px solid #A5B0BE',
        background: 'transparent',
      },
      fontWeight: 'bold',
    },
  },
  icon: {
    marginLeft: '2px',
    borderRadius: 4,
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input ~ &': {
      border: '1px solid rgba(16,22,26)',
      boxShadow: 'none',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: '1px solid #A5B0BE',
    },
  },
  checkedIcon: {
    backgroundColor: '#41B67F',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${Check})`,
      borderRadius: 4,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#41B67F',
    },
  },
  requestCreatedAction: {
    padding: '4px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&.Mui-disabled': {
      color: '#2F3542',
    },
  },
  characterHeader: {
    '&.MuiCardHeader-root': {
      padding: '10px 16px',
      backgroundColor: '#787878',
    },
  },
  cardMargin: {
    marginBottom: '40px',
    marginTop: '40px',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerBG: {
    backgroundColor: '#F4F6F9',
  },
  btnLeft: {
    marginLeft: '16px',
    minWidth: '150px',
    minHeight: '42px',
    padding: '8px 24px !important',
  },
}));

const SettingsContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const kpi = useSelector((state) => state.kpi);
  const settings = useSelector((state) => state.settings);

  const [buttonActive, setButtonActive] = useState('password');  
  const [initialValues, setInitialValues] = useState({});
  const [editPasswordSettings, setEditPasswordSettings] = useState(false);
  const [editSystemSettings, setEditSystemSettings] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [settingValues, setSettingValues] = useState({});
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const [uppercaseLetterChange, setUppercaseLetterChange] = useState(false);
  const [lowercaseLetterChange, setLowercaseLetterChange] = useState(false);
  const [numberChange, setNumberChange] = useState(false);
  const [specialCharacterChange, setSpecialCharacterChange] = useState(false);
  const [settingsId, setSettingsId] = useState('');
  const [initialValuesPassword, setInitialValuesPassword] = useState({
    password_validity: 0,
    force_change_password: false,
    password_history: 0,
    change_frequency: 0,
    notification_frequency: 0,
    days_in_advance_to_send: 0,
    attemps_before_lock: 0,
    locked_time_limit: 0,
    min_characters: 0,
    max_characters: 0,
    uppercase_letter: false,
    lowercase_letter: false,
    number: false,
    special_character: false,
  });
  const [initialValuesSystem, setInitialValuesSystem] = useState({
    inactivity_timeout: 0,
  });

  const handleChange = (name) => {
    setButtonActive(name);
    if (name === 'password') {
      setInitialValues(initialValuesPassword);
    } else {      
      setInitialValues(initialValuesSystem);
    }
  };

  const editPassword = (data) => {
    setEditPasswordSettings(data);
  };

  const editInactivityTimeout = (data) => {
    setEditSystemSettings(data);
  };
  
  const handleForceChangingOfPassword = (event) => {
    setForcePasswordChange(event.target.checked);
  };

  const handleUppercaseLetterChange = (event) => {
    setUppercaseLetterChange(event.target.checked);
  };

  const handleLowercaseLetterChange = (event) => {
    setLowercaseLetterChange(event.target.checked);
  };

  const handleNumberChange = (event) => {
    setNumberChange(event.target.checked);
  };

  const handleSpecialCharChange = (event) => {
    setSpecialCharacterChange(event.target.checked);
  };

  const handleFormChange = (event) => {
    event.preventDefault();
    if (!settings.changedSettings) dispatch(ACTION.setChangedSettings(true));
  };

  const closeEditView = () => {
    setEditPasswordSettings(false);    
    setEditSystemSettings(false);
    dispatch(ACTION.setChangedSettings(false));
  };

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const resetCheckboxes = (values) => {
    if (values.force_change_password !== initialValues.force_change_password)
      handleForceChangingOfPassword({target: {checked: initialValues.force_change_password}});
    if (values.uppercase_letter !== initialValues.uppercase_letter)
      handleUppercaseLetterChange({target: {checked: initialValues.uppercase_letter}});
    if (values.lowercase_letter !== initialValues.lowercase_letter)
      handleLowercaseLetterChange({target: {checked: initialValues.lowercase_letter}});
    if (values.number !== initialValues.number)
      handleNumberChange({target: {checked: initialValues.number}});
    if (values.special_character !== initialValues.special_character)
      handleSpecialCharChange({target: {checked: initialValues.special_character}});
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const onSubmit = (values) => {
    let data = {};

    if (typeof values.inactivity_timeout !== 'undefined') {
      data = {
        inactivity_timeout: parseInt(values.inactivity_timeout),
      };
      setInitialValuesSystem(data);
    } else {
      data = {
        ...values,
        password_validity: parseInt(values.password_validity),
        password_history: parseInt(values.password_history),
        change_frequency: parseInt(values.change_frequency),
        notification_frequency: parseInt(values.notification_frequency),
        days_in_advance_to_send: parseInt(values.days_in_advance_to_send),
        attemps_before_lock: parseInt(values.attemps_before_lock),
        locked_time_limit: parseInt(values.locked_time_limit),
        min_characters: parseInt(values.min_characters),
        max_characters:  parseInt(values.max_characters),
      };
      setInitialValuesPassword(data);
    }
    
    setSettingValues(data);
    setOpenConfirmDialog(true);
  };

  const onConfirmSave = async () => {
    setOpenConfirmDialog(false);
    dispatch(ACTION.updateSettings(settingsId, settingValues));
    closeEditView();
  };

  useEffect(() => {
    if (settings.settingsDetails) {
      const settingsData = settings.settingsDetails;
      setSettingsId(settingsData.id);
      const initPasswordDetails = {
        password_validity: settingsData.password_validity,
        force_change_password: settingsData.force_change_password,
        password_history: settingsData.password_history,
        change_frequency: settingsData.change_frequency,
        notification_frequency: settingsData.notification_frequency,
        days_in_advance_to_send: settingsData.days_in_advance_to_send,
        attemps_before_lock: settingsData.attemps_before_lock,
        locked_time_limit: settingsData.locked_time_limit,
        min_characters: settingsData.min_characters,
        max_characters: settingsData.max_characters,
        uppercase_letter: settingsData.uppercase_letter,
        lowercase_letter: settingsData.lowercase_letter,
        number: settingsData.number,
        special_character: settingsData.special_character,
      };
      setInitialValuesPassword(initPasswordDetails);
      setForcePasswordChange(settingsData.force_change_password);
      setUppercaseLetterChange(settingsData.uppercase_letter);
      setLowercaseLetterChange(settingsData.lowercase_letter);
      setNumberChange(settingsData.number);
      setSpecialCharacterChange(settingsData.special_character);
      setInitialValuesSystem({
        inactivity_timeout: settingsData.inactivity_timeout,
      });

      if (buttonActive === 'password') {
        setInitialValues(initPasswordDetails);
      } else {
        setInitialValues(initialValuesSystem);
      }
    }      
    // eslint-disable-next-line
  }, [settings.settingsDetails]);

  useEffect(() => {
    setSettingsId('initial');
    setInitialValues(initialValuesPassword);
    dispatch(ACTION.initializeSettings());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Grid container>
          <Grid item xs={6}>
            <Title>Settings</Title>
          </Grid>
        </Grid>          
          {initialValues ? (
            <Form
              ref={form => this.form = form}
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                form,
                values,
                reset,
              }) => (
                <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} noValidate>
                  <Grid container className="main-container">
                    <Grid item sm={6} className="tabs">
                      <div>
                        <ButtonGroup
                          aria-label="outlined button group"
                          className={classes.messengerBtnGroup}
                        >
                          <Button
                            className="button-font"
                            name="password"
                            disabled={buttonActive === 'password' || editSystemSettings ? true : false}
                            variant={buttonActive === 'password' ? 'contained' : null}
                            onClick={(event) => handleChange(event.currentTarget.getAttribute('name'))}
                            data-cy="password"
                          >
                            Password
                          </Button>
                          <Button
                            className="button-font"
                            name="system"
                            disabled={buttonActive === 'system' || editPasswordSettings ? true : false}
                            variant={buttonActive === 'system' ? 'contained' : null}
                            onClick={(event) => handleChange(event.currentTarget.getAttribute('name'))}
                            data-cy="system"
                          >
                            System
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Grid>
                    <Grid className={classes.btnWrapper} item sm={6}>
                      { editPasswordSettings || editSystemSettings ? (
                        <>
                          <SecondaryButton
                            style={{minWidth: '150px'}}
                            data-cy="cancel_btn" 
                            onClick={() => {
                              form.reset(initialValues);
                              resetCheckboxes(values);
                              closeEditView();
                            }
                           }
                          >
                            Cancel
                          </SecondaryButton>
                          <PrimaryButton
                          
                          disabled={
                            buttonActive === 'password' ? (
                              (values.password_validity > 0
                              && values.password_history > 0
                              && values.change_frequency > 0
                              && values.notification_frequency > 0
                              && values.days_in_advance_to_send > 0
                              && values.attemps_before_lock > 0
                              && values.locked_time_limit > 0
                              && values.min_characters > 0
                              && values.max_characters > 0) ? false : true
                            ) : (
                              values.inactivity_timeout > 0 ? false : true
                            )
                          }
                            className={classes.btnLeft}
                            datacy="save_btn"
                            onClick={handleSubmit}
                          >
                            Save
                          </PrimaryButton>
                        </>
                      ) : 
                        buttonActive === 'password' ? (
                          <PrimaryButton datacy="edit_password_btn" onClick={() => editPassword(true)}>
                            Edit Password Settings
                          </PrimaryButton>
                        ) : (
                          <PrimaryButton datacy="edit_inactivity_btn" onClick={() => editInactivityTimeout(true)}>
                            Edit Inactivity Timeout
                          </PrimaryButton>
                        )
                      }
                      
                    </Grid>
                    <Grid item sm={12}>
                      <Grid container spacing={5} className={classes.settingsWrapper}>
                        <Grid item sm={12}>
                          <div className="settings-box">
                            <Grid container>
                              { buttonActive === 'password' ? (
                                <>
                                  <Grid item sm={6}>
                                    <h2 className="sub-title">PASSWORD VALIDITY</h2>
                                    <Grid container>
                                      <Grid item sm={12}>
                                        <span>Validity of temporary password (validation link) after registration</span>
                                      </Grid>
                                      <Grid item sm={2}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "password_validity" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="HRS"
                                          name="password_validity"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('password_validity', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={10}></Grid>
                                      <Grid item sm={12} className="heading-margins">
                                        <div className="checkbox-margin">
                                          <FormControlLabel
                                            disabled={!editPasswordSettings}
                                            checked={forcePasswordChange}
                                            control={
                                              <Checkbox
                                                name="force_change_password"
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                                onChange={(e) => {
                                                  handleForceChangingOfPassword(e);
                                                  form.change('force_change_password', e.target.checked);
                                                }}
                                                color="primary"
                                                inputProps={{ 'data-cy': 'force_change_password' }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            }
                                            label="FORCE CHANGING OF PASSWORD"
                                            classes={{ label: classes.requestCreatedAction }}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12}>
                                        <span>Password History</span>
                                      </Grid>
                                      <Grid item sm={2}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "password_history" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="PW"
                                          name="password_history"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('password_history', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={10}></Grid>
                                      <Grid item sm={12} className="margin-bottom-medium">
                                        <span>User may not reuse their {<b>{values.password_history}</b>} last {parseInt(values.password_history) === 1 ? `password` : `passwords`}</span>
                                      </Grid>
                                      <Grid item sm={4}>
                                        <span>Frequency to Change</span>
                                      </Grid>
                                      <Grid item sm={4}>
                                        <span>Frequency of Notification</span>
                                      </Grid>
                                      <Grid item sm={4}>
                                        <span>Days in Advance to Send</span>
                                      </Grid>
                                      <Grid item sm={4}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "change_frequency" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="DAYS"
                                          name="change_frequency"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('change_frequency', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={4}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "notification_frequency" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="DAYS"
                                          name="notification_frequency"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('notification_frequency', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={4}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "days_in_advance_to_send" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="DAYS"
                                          name="days_in_advance_to_send"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('days_in_advance_to_send', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={12} className="margin-bottom-tall">
                                        <span>User should change their passwords every {<b>{values.change_frequency}</b>} {parseInt(values.change_frequency) === 1 ? `day` : `days`}. 
                                        On {<b>{values.days_in_advance_to_send}</b>} {parseInt(values.days_in_advance_to_send) === 1 ? `day` : `days`} before the expiry, the user will be given a notification 
                                        to change their password every {<b>{values.notification_frequency}</b>} {parseInt(values.notification_frequency) === 1 ? `day` : `days`}.</span>
                                      </Grid>
                                      <Grid item sm={12} className="heading-margins">
                                        <span className="heading-style">NUMBER OF ATTEMPTS BEFORE ACCOUNT GETS LOCKED</span>
                                      </Grid>
                                      <Grid item sm={12}>
                                        <span>A user account will be locked after {<b>{values.attemps_before_lock}</b>} number of failed attempts</span>
                                      </Grid>
                                      <Grid item sm={2}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "attemps_before_lock" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="ATTEMPTS"
                                          name="attemps_before_lock"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('attemps_before_lock', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={10}></Grid>
                                      <Grid item sm={12} className="heading-margins">
                                        <span className="heading-style">LOCKED TIME LIMIT</span>
                                      </Grid>
                                      <Grid item sm={12}>
                                        <span>A user account will be unlocked after {<b>{values.locked_time_limit}</b>} {parseInt(values.locked_time_limit) === 1 ? `hour` : `hours`}</span>
                                      </Grid>
                                      <Grid item sm={2}>
                                        <TextField
                                          disabled={!editPasswordSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "locked_time_limit" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="HRS"
                                          name="locked_time_limit"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('locked_time_limit', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={10}></Grid>
                                    </Grid>
                                  </Grid>
                                  
                                  <Grid item sm={6}>
                                    <h2 className="sub-title">PASSWORD REQUIREMENTS</h2>
                                    <Grid container>
                                      <Grid item sm={6}>
                                        <Grid container>
                                          <Grid item sm={12}>
                                            <span>MINIMUM NO. OF CHARACTERS</span>
                                          </Grid>
                                          <Grid item sm={4}>
                                            <TextField
                                              disabled={!editPasswordSettings}
                                              className={classes.input}
                                              inputProps={{ "data-cy": "min_characters" }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              name="min_characters"
                                              onChange={(e) => {
                                                handleFormChange(e);
                                                form.change('min_characters', e.target.value);
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item sm={6}>                                
                                        <Grid container>
                                          <Grid item sm={12}>
                                            <span>MAXIMUM NO. OF CHARACTERS</span>
                                          </Grid>
                                          <Grid item sm={4}>
                                            <TextField
                                              disabled={!editPasswordSettings}
                                              className={classes.input}
                                              inputProps={{ "data-cy": "max_characters" }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              name="max_characters"
                                              onChange={(e) => {
                                                handleFormChange(e);
                                                form.change('max_characters', e.target.value);
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item sm={12}>
                                        <div>
                                          <Card className={classes.cardMargin}>
                                            <CardHeader
                                              className={classes.characterHeader}
                                              title={
                                                <Typography className={classes.cardTitle}>
                                                  <span data-cy="characters">CHARACTER</span>
                                                </Typography>
                                              }
                                            />
                                            <CardContent className={classes.headerBG}>
                                              <Grid container>
                                                <Grid item sm={6}>
                                                  <div className="checkbox-margin">
                                                    <FormControlLabel
                                                      disabled={!editPasswordSettings}
                                                      checked={uppercaseLetterChange}
                                                      control={
                                                        <Checkbox
                                                          name="uppercase_letter"
                                                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                          icon={<span className={classes.icon} />}
                                                          onChange={(e) => {
                                                            handleUppercaseLetterChange(e);
                                                            form.change('uppercase_letter', e.target.checked);
                                                          }}
                                                          color="primary"
                                                          inputProps={{ 'data-cy': 'uppercase_letter' }}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                        />
                                                      }
                                                      label="Uppercase Letter"
                                                      classes={{ label: classes.requestCreatedAction }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item sm={6}>
                                                  <div className="checkbox-margin">
                                                    <FormControlLabel
                                                      disabled={!editPasswordSettings}
                                                      checked={numberChange}
                                                      control={
                                                        <Checkbox
                                                          name="number"
                                                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                          icon={<span className={classes.icon} />}
                                                          onChange={(e) => {
                                                            handleNumberChange(e);
                                                            form.change('number', e.target.checked);
                                                          }}
                                                          color="primary"
                                                          inputProps={{ 'data-cy': 'number' }}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                        />
                                                      }
                                                      label="Number"
                                                      classes={{ label: classes.requestCreatedAction }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item sm={6}>
                                                  <div className="checkbox-margin">
                                                    <FormControlLabel
                                                      disabled={!editPasswordSettings}
                                                      checked={lowercaseLetterChange}
                                                      control={
                                                        <Checkbox
                                                          name="lowercase_letter"
                                                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                          icon={<span className={classes.icon} />}
                                                          onChange={(e) => {
                                                            handleLowercaseLetterChange(e);
                                                            form.change('lowercase_letter', e.target.checked);
                                                          }}
                                                          color="primary"
                                                          inputProps={{ 'data-cy': 'lowercase_letter' }}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                        />
                                                      }
                                                      label="Lowercase Letter"
                                                      classes={{ label: classes.requestCreatedAction }}
                                                    />
                                                  </div>
                                                </Grid>
                                                <Grid item sm={6}>
                                                  <div className="checkbox-margin">
                                                    <FormControlLabel
                                                      disabled={!editPasswordSettings}
                                                      checked={specialCharacterChange}
                                                      control={
                                                        <Checkbox
                                                          name="special_character"
                                                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                          icon={<span className={classes.icon} />}
                                                          onChange={(e) => {
                                                            handleSpecialCharChange(e);
                                                            form.change('special_character', e.target.checked);
                                                          }}
                                                          color="primary"
                                                          inputProps={{ 'data-cy': 'special_character' }}
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                        />
                                                      }
                                                      label="Special Character"
                                                      classes={{ label: classes.requestCreatedAction }}
                                                    />
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </CardContent>
                                          </Card>
                                        </div>

                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : 
                                <>
                                  <Grid item sm={6}>
                                    <h2 className="sub-title">INACTIVITY TIMEOUT</h2>
                                    <Grid container>
                                      <Grid item sm={12}>
                                        <span>MINUTES ALLOWED FOR IDLE TIME BEFORE AUTOMATIC LOGOUT</span>
                                      </Grid>
                                      <Grid item sm={2}>
                                        <TextField
                                          disabled={!editSystemSettings}
                                          className={classes.input}
                                          inputProps={{ "data-cy": "inactivity_timeout" }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          label="MINS"
                                          name="inactivity_timeout"
                                          onChange={(e) => {
                                            handleFormChange(e);
                                            form.change('inactivity_timeout', e.target.value);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item sm={10}></Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              }                              
                            </Grid>
                          </div>
                        </Grid>
                      </Grid> 
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          ) : null }        
      </Layout>
      <SaveSettings
        open={openConfirmDialog}
        handleClose={closeConfirmDialog}
        handleConfirm={onConfirmSave}
      />
    </>
  );
};

export default SettingsContainer;
