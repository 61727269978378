import React from 'react';
import CommonDialog from '../common/CommonDialog';
import NewAdminAssistantComponent from './NewAdminAssistantComponent';
import PropTypes from 'prop-types';
import DialogConfirm from '../common/DialogConfirm/DialogConfirm';
import { useEffect, useState } from 'react'

const NewAdminAssistant = ({ classes, close, open, onChange }) => {
  const [dialogopen, setOpen] = useState(false);
  const [dialogChange, setdialogChange] = useState(false);
  const onEdit = () => {
    if(dialogChange){
      setOpen(true)
      }else{
        close(false)  
      }
      
  };
  return (
    <>
      <CommonDialog close={onEdit} open={open}>
        <NewAdminAssistantComponent close={close} dialogChange={dialogChange} setdialogChange={setdialogChange} classes={classes} />
      </CommonDialog>
      <DialogConfirm
        setOpen={setOpen}
        open={dialogopen}
        close={close}
      /> 
    </>
  );
};

NewAdminAssistant.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
  open: PropTypes.bool,
  dialogChange: PropTypes.bool,
  setdialogChange: PropTypes.any,
};

export default React.memo(NewAdminAssistant);
