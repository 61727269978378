import * as ACTION from '../actions/action_types';

const initialState = {
  request: [],
  offices: [],
  request_filter: [],
  request_info: [],
  reqLoading: false,
  searchParams: {
    keyword: '',
    pageLimit: 10,
    pageNum: 1,
    sortBy: '',
    orderBy: '',
    active: '',
  },
  table: null,
  modal: null,
  onShowEdit: false,
  order: false,
  adminDetails: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION.SHOW_ADMINASSIST:
      return {
        ...state,
        request: action.payload.data,
        table: action.payload.pagination,
        reqLoading: false,
      };
      case ACTION.ADMINASSIST_OFFICES:
      return {
        ...state,
        offices: action.payload,
        reqLoading: false,
      };
      case ACTION.ADMINASSIST_DETAILS:
      return {
        ...state,
        modal: action.payload,
        onShowEdit: action.onShowEdit,
        reqLoading: false,
      };
      case ACTION.SHOW_EDIT:
      return {
        ...state,
        onShowEdit: action.payload,
        reqLoading: false,
      };
      case ACTION.ADMINASSIST_STATUS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          active: action.payload,
        },
        reqLoading: false,
      };
      case ACTION.FILTER_ADMINASSIST:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          keyword: action.payload,
        },
        reqLoading: false,
      };
      // 3434
      case ACTION.CLEAR_ADMINASSIST_FILTER:
        return {
          searchParams: {
            keyword: '',
            pageLimit: 10,
            pageNum: 1,
            sortBy: '',
            orderBy: '',
            active: '',
          },
          reqLoading: false,
        };
      case ACTION.SORT_FILTER:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            sortBy: action.payload,
            orderBy: action.orderBy,
          },
          order: action.newOrder,
          reqLoading: false,
        };
      case ACTION.PAGE_LIMIT_ADMIN:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            pageLimit: action.payload,
          },
          reqLoading: false,
        };
      case ACTION.PAGE_NO_ADMIN:
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            pageNum: action.payload,
          },
          reqLoading: false,
        };
        case ACTION.REQUEST_LOADING:
        return {
        ...state,
        reqLoading: true,
      };
    default:
      return state;
  }
}
