/* eslint-disable no-extra-boolean-cast */
import axios from 'axios';
import * as ACTION from './action_types';
import { returnErrors } from './errorActions';
import * as SETTING from './settingsActions';

import * as API from '../../utils/Constants';
import { toast } from 'react-toastify';
import { axiosApiInstance } from '../../utils/apiConfig';
const CryptoJS = require('crypto-js');

// 4320 - DEV2
const key = CryptoJS.SHA256(process.env.HASHING_PASSWORD)
  .toString(CryptoJS.enc.Base64)
  .substring(0, 32);

// Check token and Load User
export const loadUser = () => async (dispatch, getState) => {
  // User Loading
  dispatch({ type: ACTION.USER_LOADING });
  await axiosApiInstance
    .get(API.GET_USER, tokenConfig(getState))
    .then((res) => {
      
      if(res === undefined) {
        dispatch({
          type: ACTION.AUTH_ERROR,
        });
        return;
      }

      dispatch({
        type: ACTION.USER_LOADED,
        payload: res.data,
      })
      //dispatch(SETTING.initializeSettings())
      }
    )
    .catch((err) => {
      if (!!err.response) {
        dispatch(returnErrors(err.response.message, err.response.status));
        // 4842
        localStorage.removeItem("logout");
        localStorage.setItem("logout", "false");
        // 4792
        dispatch({
          type: ACTION.AUTH_TOKEN,
        });
        dispatch({
          type: ACTION.AUTH_ERROR,
        });
      }
    });
};

// Register User
export const registerUser = ({ name, email, username, password }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };


  // Request Body
  const body = JSON.stringify({ name, email, username, password });

  axios
    .post(API.REGISTER_USER, body, config)
    .then((res) => {
      // dispatch(returnErrors({}, 200, 'REGISTER_SUCCESS'));
      dispatch({
        type: ACTION.REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
      dispatch({
        type: ACTION.REGISTER_FAIL,
      });
    });
};


// Login User
export const login = ({ username, password }) => (dispatch) => {
  dispatch({ type: ACTION.USER_LOADING });

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // 4320
  username = encryptCredentials(username);
  password = encryptCredentials(password);
  
  // Request Body
  const body = JSON.stringify({ username, password });
  
  axios
    .post(API.LOGIN_USER, body, config)
    .then((res) => {
      dispatch({
        type: ACTION.LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
      // 4842
      localStorage.setItem("logout", "true");
    })
    .catch((err) => {
      if (!!err.response) {
        const errordata = err.response.data
        if(err.response == undefined){
          let username_failed = parseInt(sessionStorage.getItem('logincount'));
          if(!username_failed){
            errordata['message'] = "Invalid username or password. You have (9) attempts left."
            sessionStorage.setItem('logincount', 9);
            dispatch(returnErrors(errordata, err.response.status, 'LOGIN_FAIL'));
            dispatch({
              type: ACTION.LOGIN_FAIL,
            });
          }else if(username_failed <= 1){
            errordata['message'] = "You have exceeded your login attempts.\n Your account is temporarily suspended. Please contact support for assistance."
            dispatch(returnErrors(errordata, err.response.status, 'LOGIN_FAIL'));
            dispatch({
              type: ACTION.LOGIN_FAIL,
            });
          }else{
            let username_remain_count = username_failed - 1
            sessionStorage.setItem('logincount', username_remain_count);
            const loginerror = "Invalid username or password. You have ("+ username_remain_count +") attempts left."
            errordata['message'] = loginerror
            dispatch(returnErrors(errordata, err.response.status, 'LOGIN_FAIL'));
            dispatch({
            type: ACTION.LOGIN_FAIL,
            });
          }
        }else{
          dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
          // turned off toast notif any response is received from the server issue #736
          //toast.error('Internal server error occurred. Please contact system administrator.');
          dispatch({
            type: ACTION.LOGIN_FAIL,
          });
        }
       
      } else {
        toast.error('Internal server error occurred. Please contact system administrator.');
      }
    });
};


// Change Password User
export const changepassword = ({ password, currentpassword, user_id }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // 4592
  password = encryptCredentials(password);
  currentpassword = encryptCredentials(currentpassword);

  // Request Body
  const body = JSON.stringify({ password, currentpassword, user_id });

  axios
    .post(API.CHANGE_PASSWORD, body, config)
    .then((res) => {
      toast.success('You have successfully changed your password.');
      dispatch({
        type: ACTION.CHANGEPWD_SUCCESS,
        payload: true,
      });
    })
    .catch((err) => {
      if (!!err.response) {
        dispatch(returnErrors(err.response.data, err.response.status, 'PASSWORD_RESET_FAIL'));
        // toast.error('Internal server error occurred. Please contact system administrator.');
      } else {
        toast.error('Internal server error occurred. Please contact system administrator.');
      }
      dispatch({
        type: ACTION.CHANGEPWD_SUCCESS,
        payload: false,
      });
    });
};

// Reset Forgot Password User
export const resetpassword = ({ password, token }) => (dispatch) => {
  
  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // Request Body
  const body = JSON.stringify({ password, token });

  axios
    .post(API.RESET_PASSWORD, body, config)
    .then((res) => {
      
      const data = res.data.data
      const resetuser = {
        username: data.username,
        password: data.newpassword,
      };
      dispatch(login(resetuser));
      
      toast.success('You have successfully changed your password');
      
    })
    .catch((err) => {
      if (!!err.response) {
        dispatch(returnErrors(err.response.data, err.response.status, 'PASSWORD_RESET_FAIL'));

        dispatch({
          type: ACTION.FGTPASSWORD_FAIL,
        });
      } else {
        toast.error('Internal server error occurred. Please contact system administrator.');
      }
    });
};

// Forgot Password User
export const forgotpassword = ({ username, email }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  username = encryptCredentials(username);
  email = encryptCredentials(email);

  // Request Body
  const body = JSON.stringify({ username, email });

  axios
    .post(API.FORGOT_PASSWORD, body, config)
    .then((res) => {
      dispatch(returnErrors('Your password reset link has been sent to your email address. Please note that the password reset link will expire in one (1) hour.'
      , res.data.status, 'PASSWORD_LINK_SUCCESS'));
    })
    .catch((err) => {
      if (!!err.response) {
        dispatch(returnErrors(err.response.data, err.response.status, 'PASSWORD_LINK_FAIL'));

        dispatch({
          type: ACTION.FGTPASSWORD_FAIL,
        });
      } else {
        toast.error('Internal server error occurred. Please contact system administrator.');
      }
    });
};

// Logout User
export const logout = (navigate) => async (dispatch, getState) => {
  const emptyBody = {};

  await axiosApiInstance
    .post(API.LOGOUT_USER, emptyBody, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTION.LOGOUT_SUCCESS,
      });
      // 4842
      localStorage.removeItem("logout");
      localStorage.setItem("logout", "true");
      navigate('/login');
    })
    .catch((err) => {
      console.log(err);
    });
};

// Page Checking Authorization
export const pageCheck = () => {
  return {
    type: ACTION.AUTH_TOKEN
  };
};

// Setup config/Headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = localStorage.getItem('token');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
};

// Update Email
export const updateEmail = ({ current_email, new_email, current_password }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // 4592
  current_password = encryptCredentials(current_password);
  current_email = encryptCredentials(current_email);
  new_email = encryptCredentials(new_email);

  // Request Body
  const body = JSON.stringify({ current_email, new_email, current_password });

  axios
    .post(API.UPDATE_EMAIL, body, config)
    .then((res) => {
      toast.success('You have successfully updated your email address, please check your email for instructions.');
      dispatch({
        type: ACTION.CHANGEEMAIL_SUCCESS,
        payload: true,
      });
      dispatch(loadUser());
    })
    .catch((err) => {
      if (!!err.response) {
        dispatch(returnErrors(err.response.data, err.response.status, 'EMAIL_UPDATE_FAIL'));
      } else {
        toast.error('Internal server error occurred. Please contact system administrator.');
      }
      dispatch({
        type: ACTION.CHANGEEMAIL_SUCCESS,
        payload: false,
      });
    });
};

export const setFgtPwdNewPassword = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.FGTPASSWORD_NEW_PASSWORD,
    payload: data,
  });
};

export const setFgtPwdConfirmPassword = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.FGTPASSWORD_CONFIRM_PASSWORD,
    payload: data,
  });
};

export const setCurrentPassword = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEPWD_CURRENT_PASSWORD,
    payload: data,
  });
};

export const setNewPassword = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEPWD_NEW_PASSWORD,
    payload: data,
  });
};

export const setConfirmPassword = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEPWD_CONFIRM_PASSWORD,
    payload: data,
  });
};

export const setChangePwdSuccess = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEPWD_SUCCESS,
    payload: data,
  });
};

export const setChangeEmailSuccess = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEEMAIL_SUCCESS,
    payload: data,
  });
};

export const setCurrentEmail = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEEMAIL_CURRENT_EMAIL,
    payload: data,
  });
};

export const setNewEmail = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEEMAIL_NEW_EMAIL,
    payload: data,
  });
};

export const setCurrentPasswordValue = (data) => async (dispatch) => {
  dispatch({
    type: ACTION.CHANGEEMAIL_CURRENT_PASSWORD,
    payload: data,
  });
};

// 4320
// export const encryptCredentials = (text) => {
// 	const initVector = crypto.randomBytes(16)
//     const initVectorHex = initVector.toString('hex')
//     const cipher = crypto.createCipheriv(cipherAlgorithm, key, initVector)
//     const encoded = cipher.update(text, 'utf-8', 'hex') + cipher.final('hex')
//     const authTag = cipher.getAuthTag().toString('hex')
//     const metaAndEncoded = [authTag, initVectorHex, encoded].join('|')
//     return metaAndEncoded
// };

export const encryptCredentials = (text) => {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random initialization vector
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Base64.parse(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString(); // Return IV and encrypted text
}