/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
// @ts-nocheck
import axios from 'axios';
import * as ACTION_TYPES from './action_types';
import * as API from '../../utils/Constants';
import { toast } from 'react-toastify';
import { axiosApiInstance } from '../../utils/apiConfig';
import { encryptCredentials, loadUser } from './authActions';

export const showAllSystemAdmin = (close) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const requestTableData = getState().sysadmin.searchParams;
    if (close) {
        close(false);
    }
    // eslint-disable-next-line no-undef
    const paramsQuery = new URLSearchParams(requestTableData).toString();
    await axiosApiInstance
      .get(API.ALL_SYSTEMADMIN + '?' + paramsQuery, tokenConfig(getState))
      .then((res) => {
        let admin_data = [];
        let pagination = {};
        let response = [];
        res.data.data.map((res) => {
          if (res.user_type === 4) {
           admin_data.push({
           name: `${res.first_name} ${res.middle_name || ""} ${res.last_name} ${res.suffix || ""}`,
           username: res.username,
           id: res.id,
           email: res.email,
           user_type: res.user_type,
           status: res.status,
           active: res.active,
           });
          }

        });
    
        pagination = res.data.pagination;
        response = { data: admin_data, pagination: pagination };

        dispatch({
          type: ACTION_TYPES.SHOW_SYSTEMADMIN,
          payload: response,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        console.log(err);
      });
  };


  export const allSystemAdmin = (close) => async (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
    // eslint-disable-next-line no-undef
  
    await axiosApiInstance
      .get(API.ALL_SYSTEMADMIN + '?pageLimit=10&pageNum=1&showAll=true', tokenConfig(getState))
      .then((res) => {
        let admin_data = [];
        res.data.data.map((res) => {
          if (res.user_type === 4) {
           admin_data.push({
           name: `${res.first_name} ${res.middle_name || ""} ${res.last_name} ${res.suffix || ""}`,
           username: res.username,
           id: res.id,
           email: res.email,
           user_type: res.user_type,
           status: res.status,
           active: res.active,
           });
          }

        });

        dispatch({
          type: ACTION_TYPES.SHOW_SYSTEMADMIN,
          payload: admin_data,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        dispatch(loadUser);
        console.log(err);
      });
  };

export const getSystemAdminDetails = (id, setOpen, showEdit) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
  .get(API.SYSTEMADMIN_DETAILS + '?' + `${id}`, tokenConfig(getState))
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.SYSTEMADMIN_DETAILS,
          payload: response.data.data,
          onShowEdit: showEdit,
        });
        setOpen(true);
      })
      .catch((err) => {
        toast.error(err);
    });
};

export const addNewSystemAdmin = (data, close, setOnLoadCircular) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // Request Body
  // 4592
  data.username = encryptCredentials(data.username);
  data.password = encryptCredentials(data.password);
  data.first_name = encryptCredentials(data.first_name);
  data.last_name = encryptCredentials(data.last_name);
  data.middle_name = encryptCredentials(data.middle_name);
  data.email = encryptCredentials(data.email);
  
  await axiosApiInstance
    .post(API.SYSTEMADMIN_REGISTER, data, tokenConfig(getState))
    .then((res) => {
      close(false, res.data.data._id)
      toast.success("System Administrator account successfully added.");
    })
    .catch((err) => {
      setOnLoadCircular(false)
      toast.error(err.response.data.message);
    });
};

export const editSystemAdmin = (data, close, setOnLoadCircular) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // Request Body
  // 4592
  data.first_name = encryptCredentials(data.first_name);
  data.last_name = encryptCredentials(data.last_name);
  data.middle_name = encryptCredentials(data.middle_name);
  data.email = encryptCredentials(data.email);

  await axiosApiInstance
    .post(API.SYSTEMADMIN_EDIT, data, tokenConfig(getState))
    .then((res) => {
      close(false)
      dispatch(showAllSystemAdmin());
      toast.success("Successfully updated System Administrator account.");
    })
    .catch((err) => {
      setOnLoadCircular(false)
      toast.error(err.response.data.message);
    });
};

export const updateSystemAdminUnlock = ({ name, email, username, id, user_type, }) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // Request Body
  const body = JSON.stringify({ name, email, username, id, user_type, status: true,  });
  await axiosApiInstance
    .post(API.SYSTEMADMIN_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      toast.success("System Administrator account successfully unlocked");
      dispatch(showAllSystemAdmin());
    })
    .catch((err) => {
      //error response
    });
};

export const updateSystemAdminActive = ({ name, email, username, id, user_type, }) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // Request Body
  const body = JSON.stringify({ name, email, username, id, user_type, active: true, });
  await axiosApiInstance
    .post(API.SYSTEMADMIN_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      toast.success("System Administrator account successfully activated.");
      dispatch({
        type: ACTION_TYPES.SHOW_SYSTEMADMIN,
      });
    })
    .catch((err) => {
      //error response
    });
};

export const updateSystemAdminDeactivate = ({ name, email, username, id, user_type }) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // Request Body
  const body = JSON.stringify({ name, email, username, id, user_type, active: false, });
  await axiosApiInstance
    .post(API.SYSTEMADMIN_UPDATE, body, tokenConfig(getState))
    .then((res) => {
      toast.success("System Administrator account successfully deactivated.");
      dispatch({
        type: ACTION_TYPES.SHOW_SYSTEMADMIN,
      });
     
    })
    .catch((err) => {
      //error response
    });
};

// Get offices
export const getHimsAuth = () => async (dispatch, getState) => {
  // Request Body
  const username = process.env.HIMS_USERNAME
  const password = process.env.HIMS_PASSWORD
  const body = JSON.stringify({ username, password,});
  axios
  .post(API.SYSTEMADMIN_LOGIN, body, tokenConfig(getState))
    .then(async(res) => {
      const token = res.data.login.access_token
      const config = {
        headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
    await axios
    .get(API.SYSTEMADMIN_OFFICES, config)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.SYSTEMADMIN_OFFICES,
        payload: res.data.body,
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });

    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};
  


export const onSortFilter = (orderBy, request, order) => (dispatch, getState) => {
  dispatch({ type: ACTION_TYPES.REQUEST_LOADING });
  const newData = {
    first_name: 'Leonardo',
    username: 'test test',
    email: '9879 87',
    status: true,
  };

  const data = Object.keys(newData)[orderBy];

  let newOrder;
  let currOrder = order;

  if (getState().admin.searchParams.sortBy.length === 0) {
    currOrder = false;
    newOrder = 'asc';
  } else {
    if (data === getState().admin.searchParams.sortBy) {
      if (currOrder === true) {
        newOrder = 'asc';
      } else {
        newOrder = 'desc';
      }
    } else {
      currOrder = true;
      newOrder = 'asc';
    }
  }

  dispatch({
    type: ACTION_TYPES.SORT_FILTER,
    payload: data,
    orderBy: newOrder,
    newOrder: currOrder,
  });
  dispatch(showAllSystemAdmin());
};

export const showEdit = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_EDIT,
    payload: true,
  });
};


export const getStatusRequestAdmin = (request) => {
  return {
    type: ACTION_TYPES.SYSTEMADMIN_STATUS,
    payload: request,
  };
};

export const getFilterRequestAdmin = (request) => {
  return {
    type: ACTION_TYPES.FILTER_SYSTEMADMIN,
    payload: request,
  };
};

// 3434
export const clearFilterSystemAdmin = (request) => {
  return {
    type: ACTION_TYPES.CLEAR_SYSADMIN_FILTER,
    payload: request,
  };
};

export const setPaginationPageAdmin = (pageNumber, refresh) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_NO_ADMIN,
    payload: pageNumber,
  });
  if(refresh) dispatch(showAllSystemAdmin());
};

export const setPageLimitAdmin = (pageLimit, refresh) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_LIMIT_ADMIN,
    payload: pageLimit,
  });
  if(refresh) dispatch(showAllSystemAdmin());
};

// export const clearAdmin= () => (dispatch) => {
//   dispatch({
//     type: ACTION_TYPES.CLEAR_NEWMESSENGER,
//   });
// };

// export const AdminLoading = (bool) => {
//   return {
//     type: ACTION_TYPES.MESSENGER_LOADING,
//     payload: bool,
//   };
// };

// export const clearAdminFilter = () => (dispatch) => {
//   dispatch({ type: ACTION_TYPES.CLEAR_MESSENGER_FILTER });
// };

// Setup config/Headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = localStorage.getItem('token');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
};
