export const ALL_USERS = 'ALL_USERS';
export const GET_USER = 'GET_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const PROCESS_LOADING = 'PROCESS_LOADING';
export const SORT_FILTER_TABLE_VIEW = 'SORT_FILTER_TABLE_VIEW';
export const ALL_DEVELOPERS = 'ALL_DEVELOPERS';
export const ADD_DEVELOPER = 'ADD_DEVELOPER';
export const DELETE_DEVELOPER = 'DELETE_DEVELOPER';
export const GET_DEVELOPER = 'GET_DEVELOPER';
export const CLEAR_NEWMESSENGER = 'CLEAR_NEWMESSENGER';
export const CLEAR_NEWCOURIER = 'CLEAR_NEWCOURIER';
export const DEVELOPER_LOADING = 'DEVELOPER_LOADING';
export const FILTER_VIEW = 'FILTER_VIEW';
export const NEW_MESSENGER_INFO = 'NEW_MESSENGER_INFO';
export const NEW_COURIER_INFO = 'NEW_COURIER_INFO';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FGTPASSWORD_SUCCESS = 'FGTPASSWORD_SUCCESS';
export const FGTPASSWORD_FAIL = 'FGTPASSWORD_FAIL';
export const FGTPASSWORD_NEW_PASSWORD = 'FGTPASSWORD_NEW_PASSWORD';
export const FGTPASSWORD_CONFIRM_PASSWORD = 'FGTPASSWORD_CONFIRM_PASSWORD';
export const CHANGEPWD_CURRENT_PASSWORD = 'CHANGEPWD_CURRENT_PASSWORD';
export const CHANGEPWD_NEW_PASSWORD = 'CHANGEPWD_NEW_PASSWORD';
export const CHANGEPWD_CONFIRM_PASSWORD = 'CHANGEPWD_CONFIRM_PASSWORD';
export const CHANGEPWD_SUCCESS = 'CHANGEPWD_SUCCESS';
export const CHANGEEMAIL_CURRENT_EMAIL = 'CHANGEEMAIL_CURRENT_EMAIL';
export const CHANGEEMAIL_NEW_EMAIL = 'CHANGEEMAIL_NEW_EMAIL';
export const CHANGEEMAIL_CURRENT_PASSWORD = 'CHANGEEMAIL_CURRENT_PASSWORD';
export const CHANGEEMAIL_SUCCESS = 'CHANGEEMAIL_SUCCESS';
// 4792
export const AUTH_TOKEN = 'AUTH_TOKEN';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const RESET = 'RESET';
export const RANGE_DATE_VIEW = 'RANGE_DATE_VIEW';
export const ALL_REQUEST = 'ALL_REQUEST';
export const ADD_REQUEST = 'ADD_REQUEST';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const CANCEL_REQUEST_FAIL = 'CANCEL_REQUEST_FAIL';
export const GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
export const ADD_REQUEST_FAIL = 'ADD_REQUEST_FAIL';
export const GET_REQUEST = 'GET_REQUEST';
export const GET_PRINT_PREVIEW = 'GET_PRINT_PREVIEW';
export const REQUEST_LOADING = 'REQUEST_LOADING';
export const CLEAR_NEW_INFO = 'CLEAR_NEW_INFO';
export const GET_ALL_COUNT_REQUEST = 'GET_ALL_COUNT_REQUEST';
export const FILTER_REQUEST = 'FILTER_REQUEST';
export const RANGE_DATE = 'RANGE_DATE';
export const PAGE_LIMIT = 'PAGE_LIMIT';
export const PAGE_NO = 'PAGE_NO';
export const SORT_BY = 'SORT_BY';
export const ORDER_BY = 'ORDER_BY';
export const FILTER_TYPE = 'FILTER_TYPE';
export const FILTER_STATUS = 'FILTER_STATUS';
export const GET_BOX_CONTENT = 'GET_BOX_CONTENT';
export const GET_BOX_CONTENT_SUMMARY = 'GET_BOX_CONTENT_SUMMARY';
export const REMOVE_BOX_DATA = 'REMOVE_BOX_DATA';
export const CLEAR_FILTER_ACTION = 'CLEAR_FILTER_ACTION';
export const CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER';
export const SET_PREV_PAGE = 'SET_PREV_PAGE';
export const SCAN_LOADING = 'SCAN_LOADING';

//System Admin
export const SHOW_ADMINASSIST = 'SHOW_ADMINASSIST';
export const SHOW_SYSTEMADMIN = 'SHOW_SYSTEMADMIN';
export const PAGE_NO_ADMIN = 'PAGE_NO_ADMIN';
export const PAGE_LIMIT_ADMIN = 'PAGE_LIMIT_ADMIN';
export const ALL_ADMINASSIST = 'ALL_ADMINASSIST';
export const ADMINASSIST_DETAILS = 'ADMINASSIST_DETAILS';
export const ADMINASSIST_STATUS = 'ADMINASSIST_STATUS';
export const ADMINASSIST_OFFICES = 'ADMINASSIST_OFFICES';
export const FILTER_ADMINASSIST = 'FILTER_ADMINASSIST';
// 3434
export const CLEAR_ADMINASSIST_FILTER = 'CLEAR_ADMINASSIST_FILTER';
export const CLEAR_SYSADMIN_FILTER = 'CLEAR_SYSADMIN_FILTER';

export const SYSTEMADMIN_DETAILS = 'SYSTEMADMIN_DETAILS';
export const SYSTEMADMIN_STATUS = 'SYSTEMADMIN_STATUS';
export const SYSTEMADMIN_OFFICES = 'SYSTEMADMIN_OFFICES';
export const FILTER_SYSTEMADMIN = 'FILTER_SYSTEMADMIN';

// Address Use Cases
export const ALL_PROVINCES = 'ALL_PROVINCES';
export const GET_CITY = 'GET_CITY';
export const GET_BARANGAY = 'GET_BARANGAY';
export const ADDRESS_LOADING = 'ADDRESS_LOADING';
export const GET_NCR_PROVINCE = 'GET_NCR_PROVINCE';

// HIMS Address

export const GET_HIMS_NCR_CITY = 'GET_HIMS_NCR_CITY';
export const GET_HIMS_ALL_PROVINCE = 'GET_HIMS_ALL_PROVINCE';
export const GET_HIMS_ALL_CITIES = 'GET_HIMS_ALL_CITIES';
export const GET_HIMS_ALL_BARANGAYS = 'GET_HIMS_ALL_BARANGAYS';
export const COURIER_INIT_PROVINCE_IDS = 'COURIER_INIT_PROVINCE_IDS';
export const COURIER_INIT_PROVINCE_LABELS = 'COURIER_INIT_PROVINCE_LABELS';
export const GET_HIMS_CITY = 'GET_HIMS_CITY';
export const GET_HIMS_BARANGAY = 'GET_HIMS_BARANGAY';
export const CLEAR_HIMS_ADDRESS = 'CLEAR_HIMS_ADDRESS';
export const LOADING_HIMS_PROVINCE = 'LOADING_HIMS_PROVINCE';
export const LOADING_HIMS_CITY = 'LOADING_HIMS_CITY';
export const LOADING_HIMS_BARANGAY = 'LOADING_HIMS_BARANGAY';

// Notifications
export const ALL_NOTIFICATIONS = 'ALL_NOTIFICATIONS';
export const UNREAD_NOTIFICATIONS = 'UNREAD_NOTIFICATIONS';
export const MARK_AS_READ = 'MARK_AS_READ';
export const NOTIFICATION_LOADING = 'NOTIFICATION_LOADING';
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';
export const FRESH_NOTIF = 'FRESH_NOTIF';
export const VIEWED_NOTIF = 'VIEWED_NOTIF';

// Messenger
export const TABLE_QUERY = 'TABLE_QUERY';
export const ADD_NEW_MESSENGER = 'ADD_NEW_MESSENGER';
export const INITIAL_BARANGAY = 'INITIAL_BARANGAY';
export const SKIP_ITEM_DEFAULT = 'SKIP_ITEM_DEFAULT';
export const VIEW_OLDER_LOAD = 'VIEW_OLDER_LOAD';
export const VIEW_OLDER_NOTIF = 'VIEW_OLDER_NOTIF';
export const ALL_MESSENGER = 'ALL_MESSENGER';
export const FILTER_MESSENGER = 'FILTER_MESSENGER';
export const PAGE_NO_MESSENGER = 'PAGE_NO_MESSENGER';
export const PAGE_LIMIT_MESSENGER = 'PAGE_LIMIT_MESSENGER';
export const PAGE_LIMIT_VIEW = 'PAGE_LIMIT_VIEW';
export const MESSENGER_DETAILS = 'MESSENGER_DETAILS';
export const LOCATION_MESSENGER = 'LOCATION_MESSENGER';
export const SHOW_EDIT = 'SHOW_EDIT';
export const PAGE_NO_VIEW = 'PAGE_NO_VIEW';
export const SORT_FILTER = 'SORT_FILTER';
export const SORT_FILTER_TABLE = 'SORT_FILTER_TABLE';
export const SCAN_FORM = 'SCAN_FORM';
export const SCAN_DATA = 'SCAN_DATA';
export const SCAN_NO_MESSENGER = 'SCAN_NO_MESSENGER';
export const SHOW_MESSENGER = 'SHOW_MESSENGER';
export const FILTER_TYPE_TABLE_VIEW = 'FILTER_TYPE_TABLE_VIEW';
export const SET_MESSENGER_MONITORING = 'SET_MESSENGER_MONITORING';
export const NEW_MESSENGER_INFO_RESET = 'NEW_MESSENGER_INFO_RESET';
export const MESSENGER_LOADING = 'MESSENGER_LOADING';
export const CLEAR_MESSENGER_FILTER = 'CLEAR_MESSENGER_FILTER';

// Courier
export const ADD_NEW_COURIER = 'ADD_NEW_COURIER';
export const INITIAL_COURIER = 'INITIAL_COURIER';
export const COURIER = 'COURIER';
export const ALL_COURIER = 'ALL_COURIER';
export const FILTER_COURIER = 'FILTER_COURIER';
export const PAGE_NO_COURIER = 'PAGE_NO_COURIER';
export const PAGE_LIMIT_COURIER = 'PAGE_LIMIT_COURIER';
export const COURIER_DETAILS = 'COURIER_DETAILS';
export const LOCATION_COURIER = 'LOCATION_COURIER';
export const SHOW_COURIER = 'SHOW_COURIER';
export const SET_COURIER_MONITORING = 'SET_COURIER_MONITORING';
export const NEW_COURIER_INFO_RESET = 'NEW_COURIER_INFO_RESET';
export const COURIER_LOADING = 'COURIER_LOADING';
export const CLEAR_COURIER_FILTER = 'CLEAR_COURIER_FILTER';
export const GET_ALL_COURIERS = 'GET_ALL_COURIERS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const CLIENT_SEARCHING = 'CLIENT_SEARCHING';

// KPI
export const GET_PERCENTAGE_PROCESSED = 'GET_PERCENTAGE_PROCESSED';
export const GET_NOT_DELIVERED = 'GET_NOT_DELIVERED';
export const GET_INDICATOR = 'GET_INDICATOR';
export const GET_MONTHLY_PRODUCTIVITY = 'GET_MONTHLY_PRODUCTIVITY';
export const LOADING_KPI = 'LOADING_KPI';

// DEPARTMENT
export const GET_ALL_DEPARTMENT = 'GET_ALL_DEPARTMENT';

// REPORTS
export const SET_TRANSMITTAL_NO = 'SET_TRANSMITTAL_NO';
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_PDF = 'GET_REPORT_PDF';
export const REQUEST_TYPE = 'REQUEST_TYPE';
export const SET_MESSENGER = 'SET_MESSENGER';
export const SET_COURIER = 'SET_COURIER';
export const GET_ADMIN = 'GET_ADMIN';
export const REQUEST_STATUS = 'REQUEST_STATUS';
export const SET_REQUESTOR = 'SET_REQUESTOR';
export const REPORT_SORTING = 'REPORT_SORTING';
export const SET_DATE_SCANNED = 'SET_DATE_SCANNED';
export const FILTERED_DATE = 'FILTERED_DATE';
export const PAGE_LIMIT_TRANS = 'PAGE_LIMIT_TRANS';
export const PAGE_NUM = 'PAGE_NUM';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const EXPORT_EXCEL_TRANSMITTED = 'EXPORT_EXCEL_TRANSMITTED';
export const SET_LOST_REQUESTOR = 'SET_LOST_REQUESTOR';
export const SET_LOST_DEPARTMENT = 'SET_LOST_DEPARTMENT';
export const SET_HMO_LOST = 'SET_HMO_LOST';
export const SET_DATE_CREATE_LOST = 'SET_DATE_CREATE_LOST';
export const SET_MESSENGER_LOST = 'SET_MESSENGER_LOST';
export const SET_LOST_DATE = 'SET_LOST_DATE';
export const AREA_TYPE = 'AREA_TYPE';
export const SET_DEPARTMENT_TRANSMITTED = 'SET_DEPARTMENT_TRANSMITTED';
export const SET_HMO_TRANSMITTED = 'SET_HMO_TRANSMITTED';
export const SET_DATE_UPLOAD = 'SET_DATE_UPLOAD';
export const REPORT_LOADING = 'REPORT_LOADING';
export const EXCEL_REPORT_LOADING = 'EXCEL_REPORT_LOADING';
export const SET_URGENT_REQUESTOR = 'SET_URGENT_REQUESTOR';
export const SET_URGENT_DEPARTMENT = 'SET_URGENT_DEPARTMENT';
export const SET_HMO_URGENT = 'SET_HMO_URGENT';
export const SET_URGENT_DATE = 'SET_URGENT_DATE';
export const SET_CANCELLED_REQUESTOR = 'SET_CANCELLED_REQUESTOR';
export const SET_DATE_SCANNED_CANCEL = 'SET_DATE_SCANNED_CANCEL';
export const SET_DATE_CANCELLED = 'SET_DATE_CANCELLED';
export const SET_CANCELLED_DEPARTMENT = 'SET_CANCELLED_DEPARTMENT';
export const SET_HMO_CANCELLED = 'SET_HMO_CANCELLED';
export const SET_HMO_INTELLICARE_AVEGA = 'SET_HMO_INTELLICARE_AVEGA';
export const SET_HMO_SCHEDULED = 'SET_HMO_SCHEDULED';
export const SET_INTELLICARE_AVEGA_DEPARTMENT = 'SET_INTELLICARE_AVEGA_DEPARTMENT';
export const SET_SCHEDULED_DEPARTMENT = 'SET_SCHEDULED_DEPARTMENT';
export const SET_INTELLICARE_AVEGA_REQUESTOR = 'SET_INTELLICARE_AVEGA_REQUESTOR';
export const SET_SCHEDULED_REQUESTOR = 'SET_SCHEDULED_REQUESTOR';
export const SET_DATE_SCANNED_INTELLICARE_AVEGA = 'SET_DATE_SCANNED_INTELLICARE_AVEGA';
export const SET_DATE_CREATED_SCHEDULED = 'SET_DATE_CREATED_SCHEDULED';
export const SET_DATE_SCHEDULED = 'SET_DATE_SCHEDULED';
export const SET_TRANSMITTAL_NO_INTELLICARE_AVEGA = 'SET_TRANSMITTAL_NO_INTELLICARE_AVEGA';
export const SET_INTELLICARE_AVEGA_REQUEST_TYPE = 'SET_INTELLICARE_AVEGA_REQUEST_TYPE';

//SCHEDULE
export const NEW_SCHEDULE = 'NEW_SCHEDULE';
export const LIST_REQUESTOR = 'LIST_REQUESTOR';
export const REQUESTOR_DATA_SCHEDULE = 'REQUESTOR_DATA_SCHEDULE';
export const FILTER_REQUESTOR_DETAILS = 'FILTER_REQUESTOR_DETAILS';
export const NEW_SCHEDULE_DATA = 'NEW_SCHEDULE_DATA';
export const PAGE_NO_SCHEDULE = 'PAGE_NO_SCHEDULE';
export const PAGE_LIMIT_SCHEDULE = 'PAGE_LIMIT_SCHEDULE';
export const SORT_FILTER_SCHEDULE = 'SORT_FILTER_SCHEDULE';
export const REQUEST_LOADING_SCHEDULE = 'REQUEST_LOADING_SCHEDULE';
export const FILTER_TYPE_SCHEDULE = 'FILTER_TYPE_SCHEDULE';
export const FILTER_REPEATS_SCHEDULE = 'FILTER_REPEATS_SCHEDULE';
export const SEARCH_SCHEDULE_REQUEST = 'SEARCH_SCHEDULE_REQUEST';
export const FILTER_DATE_RANGE_SCHEDULE = 'FILTER_DATE_RANGE_SCHEDULE';
export const VIEW_SCHEDULED_REQUEST = 'VIEW_SCHEDULED_REQUEST';
export const ALL_SCHEDULED_REQUEST = 'ALL_SCHEDULED_REQUEST';
export const CLEAR_SCHEDULE_REQUEST_SEARCH = 'CLEAR_SCHEDULE_REQUEST_SEARCH';
export const SET_STATISTICS_DEPARTMENT = 'SET_STATISTICS_DEPARTMENT';
export const SET_HMO_STATISTICS = 'SET_HMO_STATISTICS';
export const HISTORY_SCHEDULED_REQUEST = 'HISTORY_SCHEDULED_REQUEST';
export const TABLE_COUNT_SCHEDULE = 'TABLE_COUNT_SCHEDULE';
export const OPEN_TRANSMITTAL_REQUEST = 'OPEN_TRANSMITTAL_REQUEST';
export const OPEN_SCHEDULED_REQUEST = 'OPEN_SCHEDULED_REQUEST';
export const CLOSE_VIEW_REQUEST = 'CLOSE_VIEW_REQUEST';
export const LOADING_REQUESTOR = 'LOADING_REQUESTOR';
export const LOADING_PARTNER = 'LOADING_PARTNER';
export const IN_SCHEDULED_REQUEST_MENU = 'IN_SCHEDULED_REQUEST_MENU';
export const SCHEDULED_REQUEST_ACTIVE_TAB = 'SCHEDULED_REQUEST_ACTIVE_TAB';

// CONTACT
export const LOADING_CONTACT = 'LOADING_CONTACT';
export const CLEAR_CONTACT_SEARCH = 'CLEAR_CONTACT_SEARCH';
export const SEARCH_CONTACT = 'SEARCH_CONTACT';
export const FILTER_CONTACT_STATUS = 'FILTER_CONTACT_STATUS';
export const FILTER_CONTACT_TYPE = 'FILTER_CONTACT_TYPE';
export const SORT_CONTACT_TABLE = 'SORT_CONTACT_TABLE';
export const PAGE_NO_CONTACT = 'PAGE_NO_CONTACT';
export const PAGE_LIMIT_CONTACT = 'PAGE_LIMIT_CONTACT';
export const VIEW_CONTACT_DETAILS = 'VIEW_CONTACT_DETAILS';
export const CLEAR_CONTACT_DETAILS = 'CLEAR_CONTACT_DETAILS';
export const ALL_CONTACT = 'ALL_CONTACT';
export const GET_CODE = 'GET_CODE';
export const FILTER_CONTACT_TYPE_BROKER = 'FILTER_CONTACT_TYPE_BROKER';

// SETTINGS
export const SETTINGS_DETAILS = 'SETTINGS_DETAILS';
export const CHANGED_SETTINGS = 'CHANGED_SETTINGS';

// AUDIT LOGS
export const GET_AUDIT_LOGS_DDS = 'GET_AUDIT_LOGS_DDS';
export const AUDIT_LOG_DETAILS = 'AUDIT_LOG_DETAILS';
export const GET_AUDIT_LOGS_DDS_REPORT = 'GET_AUDIT_LOGS_DDS_REPORT';
export const FILTER_AUDIT = 'FILTER_AUDIT';
export const FILTER_AUDIT_NAME = 'FILTER_AUDIT_NAME'; 
export const PAGE_NO_AUDIT = 'PAGE_NO_AUDIT';
export const PAGE_LIMIT_AUDIT = 'PAGE_LIMIT_AUDIT';
export const FILTER_DATE_RANGE_SCHEDULE_AUDIT = 'FILTER_DATE_RANGE_SCHEDULE_AUDIT'
export const FILTER_AUDIT_USER = 'FILTER_AUDIT_USER';
export const CLEAR_AUDIT_SEARCH = 'CLEAR_AUDIT_SEARCH';