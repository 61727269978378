import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import axios from 'axios';
import * as ACTION from '../../store/actions/requestActions';

import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from "@material-ui/core/Avatar";

import { API_URL, ALLOWED_FILE_TYPE, FILE_SIZE_LIMIT } from '../../utils/Constants';

import PrimaryButton from '../common/Button/PrimaryButton';
import SecondaryButton from '../common/Button/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    zIndex: '10000 !important',
  },
  dialogTitleGroup: {
    marginTop: '-16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    color: '#2F3542',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  dialogContent: {
    padding: '10px 14px',
    borderTop: '1px solid #e0e6ed',
    borderBottom: '1px solid #e0e6ed',
  },
  dialogRequirement: {
    padding: '15px 30px',
    lineHeight: '16px',
    borderRadius: '4px',
    marginTop: '15px',
    backgroundColor: '#e0e6ed',
    '& span': {
      fontWeight: 'bold',
      fontSize: '16px',
    },
    '& p': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '16px',
    },
  },
  uploadButton: {
    width: '100%',
    marginTop: '8px',
    padding: '14px 0',
  },
  proofGroup: {
    margin: '10px 0',
    fontsize: '14px',
  },
  proofTitle: {
    fontWeight: 'bold',
    color: '#2f3542cc',
    fontSize: '14px',
    marginBottom: '5px',
  },
  proofContainer: {
    display: 'flex',
    minHeight: '200px',
    justifyContent: 'center',
  },
  proofContainerTitle: {
    margin: 'auto 0 auto 0',
    textAlign: 'center',
    color: '#aaaaaa90',
  },
  hiddenInput: {
    display: 'none',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  marginRight: {
    marginRight: '30px',
  },
  proofList: {
    listStyleType: 'none',
    padding: '0',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    '& li:not(:last-child)': {
      marginBottom: '15px',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      background: 'rgba(47, 53, 66, 0.4)',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  keyItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    border: '1px #41B67F solid',
    borderRadius: '5px',
    marginRight: '5px',
    '& img': {
      maxWidth: '50px',
      maxHeight: '50px',
      objectFit: 'cover',
    },
  },
  isNotAllowedBorder: {
    border: '1px #FA5656 solid',
  },
  errorMessage: {
    margin: 'auto 0 auto 0',
    textAlign: 'center',
    color: '#FA5656',
  },
  progressMessage: {
    color: '#0000008A',
    margin: '0 auto 0 0',
    alignSelf: 'center',
    fontStyle: 'italic',
  },
}));

const ProofDialog = ({ open, setOpenDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, setisOpen] = useState(open);
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  const [progressloaded, setProgressloaded] = useState(0);
  const [progresspercent, setProgressPercent] = useState(0);
  const [progresstotal, setProgresstotal] = useState(0);

  const [error, setError] = useState(null);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const inputEl = useRef(null);

  const request = useSelector((state) => state.request);

  useEffect(() => {
    if (!!request.request_info.proof_of_delivery) {
      let fileSize = 0;
      request.request_info.proof_of_delivery.forEach((item) => {
        fileSize += item.size;
      });
      setTotalFileSize(fileSize);
    }
  }, [request]);

  useEffect(() => {
    const uploadedFileCount = !!request.request_info.proof_of_delivery
      ? request.request_info.proof_of_delivery.length
      : 0;
    let totalFSize = totalFileSize;
    let totalFCount = files.length + uploadedFileCount;

    if (!!request.request_info.proof_of_delivery) {
    }
    if (totalFCount > 0) {
      if (totalFCount > 10) {
        setError('Maximum number of image files (total of 10 images) are reached.');
      } else if (totalFileSize >= FILE_SIZE_LIMIT) {
        setError('Maximum file size of all images (total of 50 MB) are reached.');
      } else {
        setError(null);
      }
    }
  }, [files, totalFileSize]);

  const handleClickOpen = () => {
    setisOpen(true);
  };

  const handleClose = () => {
    if (!saving) {
      setisOpen(false);
      setOpenDialog(false);
    }
  };

  const addDocuments = () => {
    inputEl.current.click();
  };

  const convertImageBase64 = (file) => {
    return new Promise((res) => {  
      const reader = new FileReader();
      reader.onload = () => {
        res(reader.result);
      }
      reader.readAsDataURL(file);
    })
  }

  const handleChange = (e) => {
    let currentFileArray = files;
    let totalSize = totalFileSize;
    const fileArray = Array.from(e.target.files);
    fileArray.map(
      async (f) => (
        (f['id'] = Math.random() * Math.pow(10, 16)),
        (f['imageUrl'] = URL.createObjectURL(f)),
        (f['isAllowed'] = ALLOWED_FILE_TYPE.includes(f.type)),
        (f['imageBase64'] = await convertImageBase64(f)),
        (totalSize = totalSize + f['size'])
      )
    );

    const newArray = currentFileArray.concat(fileArray);
    setFiles(newArray);
    setTotalFileSize(totalSize);
  };

  const handleRemoveImage = (unique) => {
    if (!saving) {
      let fileCopy = [...files];
      let fileSize = totalFileSize - unique.size;
      const index = fileCopy.findIndex((x) => x.id === unique.id);
      fileCopy.splice(index, 1);
      setTotalFileSize(fileSize);
      setFiles(fileCopy);
      return;
    }
    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const data = [];
      
      for (let i = 0; i < files.length; i++) {
        // data.append(`file[${i}]`, files[i]);
        const file = {
          id: files[i].id,
          name: files[i].name,
          imageBase64: files[i].imageBase64,
          type: files[i].type,
          size: files[i].size,
        }
        data.push(file);
      }
      // data.append('request', request.request_info);
      // console.log(request.request_info, "SDF");
      const token = `Bearer ${localStorage.getItem('token')}`;
      const options = {
        headers: {
          Authorization: token,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent <= 100) {
            setProgressloaded(loaded);
            setProgressPercent(percent);
            setProgresstotal(total);
            
          }
        },
      };
      const postData = await axios.post(
        `${API_URL}/requestor/requests/${request.request_info._id}/proof?transmittalnumber=${request.request_info.transmittal_no}`,
        {data},
        options
      );
      toast.success('Proof of delivery images are successfully saved.');
    } catch (error) {
      toast.error('Proof of delivery images are not saved. Please try again.');
    }
    setProgressloaded(0);
    setProgressPercent(0);
    setProgresstotal(0);
    setSaving(false);
    dispatch(ACTION.getRequest(request.request_info._id));
    dispatch(ACTION.getAuditLogs(request.request_info._id));
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog fullWidth open={isOpen} onClose={handleClose} disableBackdropClick disableEscapeKeyDown className={classes.dialogContainer}>
        {/* <DialogTitle className={classes.dialogTitle}>Upload the Proof of Delivery Documents</DialogTitle> */}
        <DialogContent>
          <div className={classes.dialogTitleGroup}>
            <p className={classes.dialogTitle}>Upload the Proof of Delivery Documents</p>
            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
          </div>
          <div className={classes.dialogContent}>
            <div className={classes.dialogRequirement}>
              <span>Image File Requirement</span>
              <p>
                Total file size (including existing saved files) must not exceed 50MB.
                <br />
                Total number of files (including existing saved files) must not exceed 10.
                <br />
                Valid file formats: BNP, PNG, JPG, JPEG
              </p>
            </div>
            <SecondaryButton disabled={saving} customClass={classes.uploadButton} onClick={addDocuments}>
              Upload Files
            </SecondaryButton>
            <div className={classes.proofGroup}>
              <p className={classes.proofTitle}>Proof of Delivery</p>
              <div className={classes.proofContainer}>
                {files.length > 0 ? (
                  <ul className={classes.proofList}>
                    {files.map((file) => (
                      // <p key={file.id}>f</p>
                      <li
                        key={file.id}
                        className={clsx(classes.keyItem, !file.isAllowed && classes.isNotAllowedBorder)}
                      >
                        {/* <img src={file.imageUrl} alt="No image available" /> */}
                        
                        <Avatar
                          src={file.imageBase64}
                          alt="No image available"
                        />
                        <p>{file.name}</p>
                        <p className={classes.closeIcon} onClick={() => handleRemoveImage(file)}>
                          X
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={classes.proofContainerTitle}>No Image available</p>
                )}
              </div>
            </div>
            <form>
              <input
                type="file"
                name="files"
                id="files"
                className={classes.hiddenInput}
                ref={inputEl}
                onChange={handleChange}
                accept="image/bmp,image/png,image/jpg,image/jpeg"
                multiple
              />
            </form>
          </div>
          <div className={classes.actionButton}>
            {!!error && <p className={classes.errorMessage}>{error}</p>}
            {progressloaded > 0 && (
              <p
                className={classes.progressMessage}
              >{`Uploading ${progressloaded}kb of ${progresstotal}kb | ${progresspercent}%`}</p>
            )}
            <SecondaryButton customClass={classes.marginRight} onClick={handleClose} disabled={saving}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit} disabled={files.length < 1 || !!error || !!saving}>
              Save
            </PrimaryButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ProofDialog.propTypes = {
  open: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};

export default ProofDialog;
