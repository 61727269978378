import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, Flip } from 'react-toastify';

import RouteBlock from './routes/RouteBlock';
import PublicRoutes from './routes/PublicRoutes';

import * as ACTION from './store/actions/authActions';
import * as CONSTANTS from './utils/Constants';

import IdleTimeContainer from './components/dds/IdleTimeContainer';
import DialogSession from './components/common/DialogConfirm/DialogSession';

import './App.css';
import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

const Authentication = () => {
  const userRoles = JSON.parse(localStorage.getItem('roles'));
  const userRoleRequestor = userRoles !== null && userRoles.some((role) => CONSTANTS.REQUESTOR.includes(role));
  const userRoleAdminAssistant = userRoles !== null && userRoles.some((role) => CONSTANTS.ADMIN_ASSISTANT.includes(role));
  const userRoleSystemAdmin = userRoles !== null && userRoles.some((role) => CONSTANTS.SYSTEM_ADMIN.includes(role));

  return userRoleRequestor ? (
    <Navigate to="/app/requests" />
  ) : userRoleAdminAssistant ? (
    <Navigate to="/app/statistics" />
  ) : userRoleSystemAdmin ? (
    <Navigate to="/app/adminassistant" />
  ) : (
    <PublicRoutes />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth);
  // 4790
  const location = useLocation();
  const [modal, setModal] = useState(false);

  const getSession = () => {
    const token = localStorage.getItem("token");
    const isLogout = localStorage.getItem("logout");

    // Add logic to handle session and modal state
    // Check session status here and set modal state accordingly
    // 3119
    if (!userInfo.isAuthenticated && 
      (location.pathname != "/" && location.pathname != "/login") &&
      !location.pathname.includes("forgot-password") &&
      token == null && isLogout == "false" ) {
      setModal(true);
    }
  };

  useEffect(() => {
    if (userInfo.isAuthenticated) dispatch(ACTION.loadUser());
    getSession();
  }, [userInfo.isAuthenticated, userInfo.isCheck]);

  return (
    <>
      <Routes>
        <Route path="/app/*" element={<RouteBlock />} />
        <Route path="*" element={<Authentication />} />
      </Routes>
      {userInfo.isAuthenticated && <IdleTimeContainer />}
      {/* 205 - changing all toast notification autoClose to 5seconds. */}
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={5000}
        hideProgressBar
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />
      <DialogSession
        setOpen={setModal}
        open={modal}
        close={() => setModal(false)}
      />
    </>
  );
};

export default App;
