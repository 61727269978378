import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';

import * as ACTION from '../../store/actions/authActions';
import * as NOTIF from '../../store/actions/notificationActions';

import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import PrimaryButton from '../common/Button/PrimaryButton';
import SecondaryButton from '../common/Button/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    zIndex: '10000 !important',
  },
  dialogButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '30px',
    marginBottom: '12px',
  },
  dialogText: {
    fontSize: '20px',
  },
  dialogCountDown: {
    color: '#f44336',
    fontWeight: 'bold',
  },
  dialogContinue: {
    marginLeft: '10px',
  },
}));

const IdleTimeContainer = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const id = useRef(null);

  const settings = useSelector((state) => state.settings);

  const [inactivityTime, setInactivityTime] = useState(18000_00);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(1800);
  const [modalRemainingTimer, setModalRemainingTime] = useState(60);

  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    if (!isOpenModal) {
      id.current = window.setInterval(() => {
        setRemainingTime((remainingTime) => remainingTime - 1);
      }, 1000);
    } else {
      setModalRemainingTime(60);            
    }
    return () => clear();
  }, [isOpenModal]);

  useEffect(() => {
    if (remainingTime === 0) {
      clear();
      setIsOpenModal(true);
    } 

    if(modalRemainingTimer <= 0) {
      clear();
      continueLogout();
    }

    if(isOpenModal) {
      setTimeout(() => {
        setModalRemainingTime((modalRemainingTimer) => modalRemainingTimer - 1);
      }, 1000)
    }
  }, [isOpenModal, remainingTime, modalRemainingTimer]);

  // useEffect(() => {
  //   if (!!settings.settingsDetails) {
  //     setInactivityTime(
  //       (((settings.settingsDetails.inactivity_timeout * 1000) * 60) - 60000)
  //     );
  //   }
  // }, [settings]);

  const onIdle = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    clear();
    setRemainingTime(1800);
    setModalRemainingTime(60);
  };

  const continueLogout = () => {
    clear();
    setRemainingTime(1800);
    setModalRemainingTime(60);
    dispatch(ACTION.logout(history));
    localStorage.removeItem('roles');
    history('/');
  };

  // useIdleTimer({
  //   ref: idleTimerRef,
  //   timeout: inactivityTime,
  //   onIdle,
  //   crossTab: true,
  // });

  return (
    <div>
      {/* modal for timeout notification */}
      <Dialog open={isOpenModal} disableBackdropClick disableEscapeKeyDown className={classes.dialogContainer}>
        <DialogContent>
          <p className={classes.dialogText}>
            Your session is about to expire. You will automatically be signed out in{' '}
            <span className={classes.dialogCountDown}>{modalRemainingTimer}</span> seconds. To continue your session,
            select Continue Session.
          </p>
          <div className={classes.dialogButton}>
            <SecondaryButton onClick={continueLogout}>Logout Now</SecondaryButton>
            <PrimaryButton onClick={closeModal} className={classes.dialogContinue}>
              Continue Session
            </PrimaryButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IdleTimeContainer;
