/* eslint-disable camelcase */
import * as ACTION_TYPES from './action_types';
import { toast } from 'react-toastify';
import * as API from '../../utils/Constants';
import { axiosApiInstance } from '../../utils/apiConfig';
import { loadUser } from './authActions';

export const initializeSettings = () => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .get(API.SETTINGS, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.SETTINGS_DETAILS,
        payload: res.data.data[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateSettings = (id, data) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .put(API.SETTINGS + '/' + id, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.SETTINGS_DETAILS,
        payload: res.data.data,
      });
      toast.success('Changes have been saved.');
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        toast.error(err.response.data.message);
      }
    });
};

// Setup config/Headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = localStorage.getItem('token');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
};

export const setChangedSettings = (data) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.CHANGED_SETTINGS,
    payload: data,
  });
};
