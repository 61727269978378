/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_URL } from '../../../utils/Constants';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { CircularProgress, Grid } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import * as ACTION from '../../../store/actions/requestActions';
const useStyles = makeStyles((theme) => ({
  backDrop: {
        backgroundColor:'rgba(0,0,0,0.0)'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  textAreaCustom: {
    padding: '8px 16px',
    width: '100%',
    maxWidth: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '4px',
    border: '1px solid #a5b0be',
  },
  btnMargin: {
    marginLeft: '15px',
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  submit: {
    height: '40px',
    width: '165px',
    margin: theme.spacing(1),
    marginTop: '20px',
  },
}));

const DeleteAttachment = ({ setOpen, setOpenDelete, openDelete, close, imageArray, imageIndex, transmittalId, transmittalNo }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleLeave = () => {
    close(false);
  };

  const handleSubmit = async (e) => {
    try {
      // Request Body
        const filename = imageArray[imageIndex - 1].name;
        const path = imageArray[imageIndex - 1].id;
        const body = JSON.stringify({ filename, path });

      const token = `Bearer ${localStorage.getItem('token')}`;
      const options = {
        headers: {
          'Content-type': 'application/json',
          'Authorization': token,
        },
      };
      const postData = await axios.post(
        `${API_URL}/requestor/requests/${transmittalId}/proof/delete?transmittalnumber=${transmittalNo}`,
        body,
        options
      );
      dispatch(ACTION.getRequest(transmittalId));
      dispatch(ACTION.getAuditLogs(transmittalId));
      toast.success('Proof of Delivery image has been successfully deleted');
      setOpen(false)
      setOpenDelete(false);
    } catch (error) {
      setOpenDelete(true);
      toast.error('Proof of Delivery image deletion failed');
    }
  };


  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      open={openDelete}
      className="dialog-width"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
    >
      <DialogContent style={{ padding: '65px' }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton aria-label="close" className="btn-cancel-custom" onClick={handleClose}>
                  <ClearSharpIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '-5px' }}>
              <Grid item>
                <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>Delete Attachment</Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '24px' }}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: '14px', marginBottom: '2px' }}>
                  Are you sure you want to delete the Proof of Delivery image?
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '24px' }}>
              <div className={classes.btnWrapper}>
                <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                className={classes.submit}
                variant="outlined"
                data-cy="cancel_unlock"
                >
                Cancel
                </Button>

                <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
                data-cy="submit_unlock"
                >
                Delete
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
DeleteAttachment.propTypes = {
  setOpen: PropTypes.any,
  open: PropTypes.bool,
  close: PropTypes.any,
  name: PropTypes.any,
  reset: PropTypes.any,
  transmittalNo: PropTypes.any,
  transmittalId: PropTypes.any,
  imageArray: PropTypes.array,
  imageIndex: PropTypes.number,
  modalId: PropTypes.any,
  pass: PropTypes.any,
};
export default DeleteAttachment;
