/* eslint-disable camelcase */
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem';
import { makeValidate, Select } from 'mui-rff'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../common/Button/PrimaryButton'
import SecondaryButton from '../common/Button/SecondaryButton'
import TextFieldNewAdmin from '../common/TextFieldNewAdmin'
import PropTypes from 'prop-types'
import CustomMultiSelect from '../common/CustomMultiSelect'
import * as ADMIN from '../../store/actions/SystemAdminActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogConfirm from '../common/DialogConfirm/DialogConfirm';
import DialogNewSystemAdmin from '../common/DialogConfirm/DialogNewSystemAdmin';

function NewSystemAdminComponent({ classes, close , dialogChange, setdialogChange }) {
  const dispatch = useDispatch()

  const formSchema = Yup.object().shape({
    first_name: Yup.string().required('Required.'),
    last_name: Yup.string().required('Required.'),
    email_address: Yup.string().email('Please enter a valid email').max(255).required('Email is required'),
  });
  const admin_info = useSelector((state) => state.sysadmin);
  const [location, setLocation] = useState([
    {
      officeItem: admin_info.offices ? admin_info.offices : [],
      office: [],
      officeLabel: [],
    },
  ])
  const [role, setRole] = React.useState('dds_sysadmin');
  const [messagePrompt, setmessagePrompt] = useState(false);
  const [officeLength, setOfficeLength] = useState(true)
  const [open, setOpen] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [onLoadCircular, setOnLoadCircular] = useState(false)
  const [body, setOnbody] = useState({});

  const closeMessagePrompt = () => {
    setmessagePrompt(!messagePrompt);
  };



  const handleClose = () => {
    if (dialogChange) {
      setOpen(true);
    } else {
      close(false);
    }

  }

  useEffect(() => {
    // ////////////
    // eslint-disable-next-line
  }, []);

  
  const itemForOffice = (idx, value) => {
    const info = []
    const infoLabel = []
    const infoInitial = []
    location[idx].officeItem.forEach((item) => {
      for (const data of value) {
        if (item.name === data) {
          info.push(item._id)
          infoLabel.push(item.name)
          infoInitial.push(item)
        }
      }
    })


      if (value.length < 1) {
      setOfficeLength(true)
      } else {
      setOfficeLength(false)
      }

    const itemData = location
    itemData[idx].office = info
    itemData[idx].officeLabel = infoLabel
    setLocation([...itemData])
  }


  const onSave = (values) => {
    const newData = []
    for (const data of location) {
      newData.push({ office: data.office, label: data.officeLabel })
    }
    const username = (values.first_name[0].toLowerCase() + "." + values.last_name.toLowerCase())
    const body = {
      account_type: "dds_sysadmin",
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name || "",
      suffix: values.suffix || "",
      username: username,
      password: "Passw0rdo!",
      assigned_locations: newData,
      email: values.email_address,
      api_token: process.env.API_TOKEN,
      hims_employee_id: "123456789",
      hims_department_id: 89,
      hims_department_name: "FPAD",
      hims_office_loc_id: 1,
      hims_office_loc_name: "Head Office",
      hims_hmo_partner_id: 2,
      company_id: values.company_id || "",
      }
      setOnbody(body)
      setOpenAccess(true)
  }

  return (
    <>
      <Form
        onSubmit={onSave}
        validate={makeValidate(formSchema)}
        render={({ handleSubmit, hasValidationErrors, }) => (
          <form
            data-cy="new_dds_systemadmin_form"
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
            onChange={() => {
              setdialogChange(true)
              }
            }
            noValidate
          >
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            // spacing={3}
            >
              <div className={classes.itemHolder}>
                <div className={classes.headerWrapper}>
                  <h2 className={classes.header}>New System Administrator</h2>
                  <div className={classes.btnDivider}>
                    <SecondaryButton
                      onClick={handleClose}
                      customClass={classes.btnLeft}
                      datacy="cancel_btn"
                    >
                      Cancel
                      </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={
                        officeLength ||
                        hasValidationErrors
                      }
                      onClick={handleSubmit}
                      className={classes.btnLeft}
                      datacy="save_btn"
                    >
                      {onLoadCircular ?   <CircularProgress classes={{ root: classes.circle2 }} /> :   
                        'Add System Admin'}
                    </PrimaryButton>

                  </div>
                </div>

                <Grid container>
                  <div className={classes.editItemWrapper}>
                    <div className={classes.editHolder}>
                      <h2 className={classes.subTitle}>System Administrator</h2>
                    </div>
                  </div>
                  <div className={classes.editItemContainer}>
                    <Grid container>

                      <Grid item sm={3}>
                        <TextFieldNewAdmin
                          disabled
                          label="Username"
                          name="username"
                          datacy="username"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.input}
                        />

                      </Grid>
                     

                      <Grid container className={classes.boxNo}>

                        <Grid item sm={3}>
                          <TextFieldNewAdmin
                            label="First Name"
                            name="first_name"
                            className={classes.input}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldNewAdmin
                            label="Middle Name (Optional)"
                            name="middle_name"
                            className={classes.input}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldNewAdmin
                            className={classes.input}
                            label="Last Name"
                            name="last_name"
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <TextFieldNewAdmin
                            className={classes.input}
                            label="Suffix (Optional)"
                            name="suffix"
                          />
                        </Grid>

                      </Grid>



                      <Grid container className={classes.boxNo}>

                        <Grid item sm={3} variant="filled">
                          <TextFieldNewAdmin
                            label="Email Address"
                            name="email_address"
                            className={classes.input}
                          />
                        </Grid>

                        <Grid item sm={3}>
                          <FormControl className={classes.formControl}>
                            <Select
                              disabled
                              label="Department"
                              name="department"
                              variant="outlined"
                              value={'FPAD'}
                              onChange={(event) =>
                                setDepartment(idx, event.target.value)
                              }
                            >
                              <MenuItem value={'FPAD'}>FPAD</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item sm={3}>
                          <FormControl className={classes.formControl}>
                            <Select
                              disabled
                              label="Job Role"
                              name="job_role"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={role}
                              onChange={(event) =>
                                setRole(event.target.value)
                              }
                            >
                              <MenuItem value={'dds_sysadmin'}>DDS System Administrator</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item sm={3}>
                          {location.map((item, idx) => {
                            const info = [];

                            // 200
                            if(item.officeItem) {
                              for (const data of item.officeItem) {
                                info.push(data.name)
                              }
                            }
                            
                            return (
                              <div
                                key={idx}
                                style={{
                                  width: '100%',
                                }}
                              >

                                <CustomMultiSelect
                                  name="office_locations"
                                  label="Office Locations"
                                  idx={idx}
                                  itemLength={location[idx].office}
                                  itemComparison={location[idx].officeItem}
                                  setStateData={itemForOffice}
                                  selectLabel="Locations Selected"
                                  checkList={info}
                                  initLabel={item.officeLabel}
                                  id={idx}
                                  selectProp={true}
                                />

                              </div>
                            )
                          })}

                        </Grid>

                      </Grid>

                      <Grid container className={classes.boxNo}>

                        <Grid item sm={3}>
                          <TextFieldNewAdmin
                            label="Company ID (Optional)"
                            name="company_id"
                            datacy="company_id"
                          />

                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

              </div>
            </Grid>

          </form>
        )}
      />

      <DialogConfirm
        setOpen={setOpen}
        open={open}
        close={close}
      />

      <DialogNewSystemAdmin
        setOpenAccess={setOpenAccess}
        setOnLoadCircular={setOnLoadCircular}
        open={openAccess}
        body={body}
        close={close}
      />

    </>
  )
}

NewSystemAdminComponent.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.bool,
}
export default NewSystemAdminComponent
