/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
// @ts-nocheck
// import axios from 'axios';
import * as ACTION_TYPES from './action_types';
import * as API from '../../utils/Constants';
import { toast } from 'react-toastify';
import { axiosApiInstance } from '../../utils/apiConfig';
import { loadUser } from './authActions';

export const resetNewCourier = () => async (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.NEW_COURIER_INFO_RESET,
  });
};

export const showAllCourier = () => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  // 4629
  dispatch({ type: ACTION_TYPES.COURIER_LOADING });
  // eslint-disable-next-line no-undef

  await axiosApiInstance
    .get(API.ALL_COURIER + '?pageLimit=10&pageNum=1&showAll=true', tokenConfig(getState))
    .then((res) => {
      let response_data = [];
      let courier_data = [];
      res.data.data.map((res) => response_data.push(res.box_no));
      res.data.data.map((res) => {
        if (res.status === 1) {
          // 3182
          courier_data.push({
            courier_id: res.courier_id,
            name: res.courier_name,
            box_no: res.box_no,
            id: res.id,
          });
        }
      });
      //console.log('courier_data', courier_data);
      dispatch({
        type: ACTION_TYPES.SHOW_COURIER,
        payload: response_data,
        courier: courier_data,
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-undef
      console.log(err);
    });
};


export const getCourier = (request) => {
  // 4790
  dispatch(loadUser());
  return {
    type: ACTION_TYPES.FILTER_COURIER,
    payload: request,
  };
};

export const getAllCouriers = () => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .get(API.ALL_COURIER + '?showAll=true', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.GET_ALL_COURIERS,
        payload: res.data.data,
      });
      // console.log('Courier Data Fetched', res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const allCourier = (close) => async (dispatch, getState) => {
  const requestTableData = getState().courier.searchParams;
  if (close) {
    close(false);
  }
  // 4629
  dispatch({ type: ACTION_TYPES.COURIER_LOADING });
  // eslint-disable-next-line no-undef
  const paramsQuery = new URLSearchParams(requestTableData).toString();
  // console.log(API.ALL_COURIER + '?' + paramsQuery, 'API COURIER');
  await axiosApiInstance
    .get(API.ALL_COURIER + '?' + paramsQuery, tokenConfig(getState))
    .then((res) => {
      let response_data = [];
      let pagination = {};
      let response = [];
      let boxes = [];
      // console.log('res', res);

      res.data.data.map((res) => {
        let loc = '';

        for (const item of res.assigned_locations_labels) {
          if (item.provinces) {
            for (const [i, data] of item.provinces.entries()){
              let comma = ', ';
              if (i === item.provinces.length-1) comma = '';
              if (i === 3) {
                loc = loc + ' [...]'
                break;
              } else {
                loc = loc + data + comma;
              }
            }
          }
        }
        response_data = [
          ...response_data,
          {
            id: res.id,
            courier_id: res.courier_id,
            courier_name: res.courier_name,
            messenger: res.first_name + ' ' + res.last_name,
            contact_number: res.contact_number
              .replace(/[^\dA-Z]/g, '')
              .replace(/(.{4})/g, '$1 ')
              .trim(),
            assigned_locations_labels: loc,
            box_no: res.box_no,
            capacity: res.content_count + '/' + res.capacity,
            status: res.status,
          },
        ];

        // 4567
        boxes.push({
          box_no: res.box_no,
          id: res.courier_id,
        });
      });

      pagination = res.data.pagination;
      response = { data: response_data, boxes, pagination: pagination };

      dispatch({
        type: ACTION_TYPES.ALL_COURIER,
        payload: response,
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-undef
      console.log(err);
    });
};

export const getFilterRequestCourier = (request) => {
  // console.log('request', request);
  return {
    type: ACTION_TYPES.FILTER_COURIER,
    payload: request,
  };
};

export const onSortFilter = (orderBy, request, order) => (dispatch, getState) => {
  // 4629
  dispatch({ type: ACTION_TYPES.COURIER_LOADING });
  const newData = {
    courier_id: '39162902',
    courier_name: 'admin test',
    first_name: 'admin test',
    contact_number: '9879 87',
    assigned_locations_labels: ['Port Area,  ', 'Quiapo '],
    box_no: 2,
    capacity: 80,
    status: 1,
  };

  const data = Object.keys(newData)[orderBy];

  let searchParams = getState().courier.searchParams;
  let newOrder;
  let currOrder = order;

  if (!searchParams.sortBy) {
    currOrder = false;
    newOrder = 'asc';
  } else {
    if (data === searchParams.sortBy) {
      if (searchParams.orderBy === 'desc') {
        newOrder = 'asc';
      } else {
        newOrder = 'desc';
      }
    } else {
      currOrder = true;
      newOrder = 'asc';
    }
  }

  dispatch({
    type: ACTION_TYPES.SORT_FILTER,
    payload: data,
    orderBy: newOrder,
    newOrder: currOrder,
  });
  dispatch(allCourier());
};

export const getLocationRequestCourier = (request) => {
  return {
    type: ACTION_TYPES.LOCATION_COURIER,
    payload: request,
  };
};

export const setPaginationPageCourier = (pageNumber, retrieve) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.PAGE_NO_COURIER,
    payload: pageNumber,
  });
  if(!retrieve) dispatch(allCourier());  
};

export const setPageLimitCourier = (pageLimit, retrieve) => (dispatch) => {
  // 4242 
  dispatch({
    type: ACTION_TYPES.PAGE_NO_COURIER,
    payload: 1,
  });
  
  dispatch({
    type: ACTION_TYPES.PAGE_LIMIT_COURIER,
    payload: pageLimit,
  });
  if(!retrieve) dispatch(allCourier());
};

export const showEdit = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SHOW_EDIT,
    payload: true,
  });
};

export const getCourierDetailsMy = (id, btn, openReassign, data) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .get(API.ALL_COURIER + `/${id}`, tokenConfig(getState))
    .then((response) => {
      openReassign(
        true,
        `${response.data.data.first_name} ${' '} ${response.data.data.last_name}`,
        response.data.data.box_no,
        response.data.data.id,
        data
      );
    })
    .catch((err) => {
      toast.error(err);
    });
};

export const clearCourier = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.CLEAR_NEWCOURIER,
  });
};

export const getCourierDetails = (id, setOpen, showEdit) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .get(API.ALL_COURIER + `/${id}`, tokenConfig(getState))
    .then((response) => {
      dispatch({
        type: ACTION_TYPES.COURIER_DETAILS,
        payload: response.data.data,
        onShowEdit: showEdit,
      });

      setOpen(true);
    })
    .catch((err) => {
      toast.error(err);
    });
};

export const addNewCourier = (data, handleClose) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .post(API.ADD_NEW_COURIER, data, tokenConfig(getState))
    .then((response) => {
      dispatch(createNewCourier(response));
      dispatch(allCourier(handleClose));
      toast.success(response.data.message);
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const editCourier = (data, id, test, test2, handleClose) => async (dispatch, getState) => {
  // 4790
  dispatch(loadUser());
  await axiosApiInstance
    .put(API.ADD_NEW_COURIER + `/${id}`, data, tokenConfig(getState))
    .then((response) => {
      dispatch(createNewCourier(response));
      dispatch(allCourier(handleClose));

      toast.success(response.data.message);
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

const createNewCourier = () => {
  // handleClose(false)
  return {
    type: ACTION_TYPES.ADD_NEW_COURIER,
  };
};

export const courierLoading = (bool) => {
  return {
    type: ACTION_TYPES.COURIER_LOADING,
    payload: bool,
  };
};

export const clearCourierFilter = () => (dispatch) => {
  dispatch({ type: ACTION_TYPES.CLEAR_COURIER_FILTER });
};

// Setup config/Headers and token
export const tokenConfig = (getState) => {
  // Get token from local storage
  const token = localStorage.getItem('token');

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
};

export const resetPasswordCourier = (data, id, pass) => async (dispatch, getState) => {
  await axiosApiInstance
    .put(API.ADD_NEW_COURIER + `/${id}/reset-password`, data, tokenConfig(getState))
    .then((response) => {
      toast.success('Password has been successfully reset.');
      pass(response.data.data.password);
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};
