/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import PrimaryButton from '../Button/PrimaryButton';
import SecondaryButton from '../Button/SecondaryButton';

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: '24px 40px 50px',
  },
  headerContainer: {
    marginTop: '-5px',
  },
  contentContainer: {
    marginTop: '24px',
  },
  mainHeader: {
    fontWeight: 'bold',
    fontSize: '36px',
  },
  contentMessage: {
    fontSize: '14px',
    marginBottom: '2px',
  },
  btnCancel: {
    width: '170px !important',
  },
  btnLeave: {
    marginLeft: '15px',
    width: '170px',
    height: '42px',
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: '30px',
  },
}));

const ConfirmNavigation = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      className="dialog-width"
      aria-labelledby="form-dialog-title"
      disableBackdropClick
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton aria-label="close" className="btn-cancel-custom" onClick={handleClose}>
                  <ClearSharpIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container className={classes.headerContainer}>
              <Grid item>
                <Typography variant={'h1'} className={classes.mainHeader}>Confirm Navigation</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.contentContainer}>
              <Grid item xs={12}>
                <Typography variant={'h1'} className={classes.contentMessage}>
                  Are you sure you want to leave? Changes made may not be saved.
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.contentContainer}>
              <div className={classes.btnWrapper}>
                <SecondaryButton onClick={handleClose} customClass={classes.btnCancel}>Cancel</SecondaryButton>{' '}
                <PrimaryButton onClick={handleConfirm} className={classes.btnLeave} datacy="confirm_navigation">
                  Leave
                </PrimaryButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
ConfirmNavigation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.any,
  handleConfirm: PropTypes.any,
};
export default ConfirmNavigation;