import React from 'react';
import CommonDialog from '../common/CommonDialog';
import NewCourierComponent from './NewCourierComponent';
import PropTypes from 'prop-types';

const NewCourier = ({ classes, close, open }) => {
  return (
    <>
      <CommonDialog close={close} open={open}>
        <NewCourierComponent close={close} classes={classes} />
      </CommonDialog>
    </>
  );
};

NewCourier.propTypes = {
  classes: PropTypes.any,
  close: PropTypes.any,
  open: PropTypes.any,
};

export default NewCourier;
