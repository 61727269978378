import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  updateTransmittalCourier,
} from '../../store/actions/MessengerActions';
import * as ACTION from '../../store/actions/requestActions';
import { Select, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryButton from '../common/Button/PrimaryButton';
import SecondaryButton from '../common/Button/SecondaryButton';
import { useSelector, useDispatch } from 'react-redux';
import { numberWithComma } from '../../utils/common';

import DialogConfirm from '../../components/common/DialogConfirm/DialogUpdateCourierStatus';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(43, 45, 51, 0.8)',
  },
  updateWrapper: {
    padding: '16px 24px',
    height: '82vh',
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
  messengerItem: {
    borderTop: '1px solid #d8d8d8',
    paddingTop: '24px',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '0',
    marginBottom: '10px',
  },
  select: {
    marginBottom: '5px',
  },
  reassignBtn: {
    padding: ' 16px 24px',
    display: 'flex',
    borderTop: '1px solid #d8d8d8',
    alignItems: 'center',
    justifyContent: ' space-between',
  },
}));

const UpdateCourierContent = ({ reassign, close, buttonActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [amount, setRiderAmount] = useState(null);
  const [rider_name, setRiderName] = useState(null);
  const [tracking_no, setTrackingNo] = useState(null);
  const [courierStatus, setcourierStatus] = useState(null);
  
  const onCourierStatus = (data) => setcourierStatus(data);
  const onRiderAmount = (data) => setRiderAmount(data);
  const onRiderName = (data) => setRiderName(data);
  const onTrackingNo = (data) => setTrackingNo(data);

  const handleClose = () => {
    setOpen(true);
  };

  const onUpdateCourier = () => {
    const data = {
      status: courierStatus,
      tracking_no,
      rider_name,
      amount,
      messengerId: reassign.messengerId,
    };

    dispatch(updateTransmittalCourier(reassign.courier_id, data));
    dispatch(ACTION.getBoxView(reassign.id, "assigne_to_me"));
    close();
  };

  return (
    <>
    <div>
      <div className={classes.updateWrapper}>
        <div className={classes.messengerItem}>
          <p className={classes.title}>Tracking No. / Waybill No.</p>
          <p className={classes.text} data-cy="rirder_name">
            <TextField
              variant="outlined"
              required
              fullWidth
              id="tracking_no"
              name="tracking_no"
              autoComplete="tracking_no"
              onChange={(e) => onTrackingNo(e.target.value)}
              inputProps={{ 'data-cy': 'tracking_no' }}
            />
          </p>
          <p className={classes.title}>Rider / Representative's Name</p>
          <p className={classes.text} data-cy="rirder_name">
            <TextField
              variant="outlined"
              required
              fullWidth
              id="rider_name"
              name="rider_name"
              autoComplete="rider_name"
              onChange={(e) => onRiderName(e.target.value)}
              inputProps={{ 'data-cy': 'rider_name' }}
            />
          </p>
          <p className={classes.title}>Amount of Service</p>
          <p className={classes.text} data-cy="rider_amount">
          <TextField
              variant="outlined"
              required
              fullWidth
              id="amount"
              name="amount"
              autoComplete="amount"
              type="number"
              onChange={(e) => onRiderAmount(e.target.value)}
              inputProps={{ 'data-cy': 'rider_amount' }}
            />
          </p>
        </div>
        <p className={classes.title}>Status</p>
        <Select
          onChange={(e) => onCourierStatus(e.target.value)}
          name="courier_status"
          classes={{ root: classes.select }}
          variant="outlined"
          data-cy="courier_to"
          MenuProps={{ MenuListProps: { 'data-cy': 'courier_to_dropdown' } }}
        >
          <MenuItem value={"Delivered"}>Delivered</MenuItem>
          <MenuItem value={"PickedUp"}>Picked Up</MenuItem>
        </Select>
      </div>
      <div className={classes.reassignBtn}>
        <SecondaryButton datacy="cancel_btn" onClick={handleClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton datacy="courier_btn" disabled={amount == null || rider_name == null || courierStatus == null || tracking_no == null } onClick={onUpdateCourier}>
          Update Status
        </PrimaryButton>
      </div>
    </div>

    <DialogConfirm
      setOpen={setOpen}
      open={open}
      close={close}
    />
    </>
  );
};

UpdateCourierContent.propTypes = {
  reassign: PropTypes.any,
  close: PropTypes.any,
  buttonActive: PropTypes.any,
};
export default UpdateCourierContent;
