/* eslint-disable no-undef */
import { thunk } from 'redux-thunk';
import rootReducer from './store/reducers';
import { legacy_createStore  as createStore, applyMiddleware, compose } from 'redux';

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
