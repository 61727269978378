import * as ACTION from '../actions/action_types';

const initialState = {
  request: [],
  request_filter: [],
  request_info: [],
  reqLoading: false,
  searchParams: {
    keyword: '',
    pageLimit: 10,
    pageNum: 1,
    sortBy: '',
    orderBy: '',
    location: '',
    courier: '',
  },
  couriers: null,
  couriersLabel: [],
  table: null,
  modal: null,
  onShowEdit: false,
  order: false,
  box_no: [],
  newCourier: {},
  courierDetails: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION.SHOW_COURIER:
      return {
        ...state,
        box_no: action.payload,
        courierDetails: action.courier,
        reqLoading: false,
      };
    case ACTION.ALL_COURIER:
      return {
        ...state,
        box_no: action.payload.boxes,
        request: action.payload.data,
        table: action.payload.pagination,
        reqLoading: false,
      };
    case ACTION.COURIER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          courier: action.payload,
        },
        reqLoading: false,
      };
    case ACTION.GET_ALL_COURIERS:
      const copyCourierArr = [];

      for (const data of action.payload) {
        if (!copyCourierArr.includes(data.courier_name)) {
          copyCourierArr.push(data.courier_name);
        }
      }
      copyCourierArr.unshift('All Couriers');

      // console.log('courier list', copyCourierArr);

      return {
        ...state,
        couriers: action.payload,
        couriersLabel: copyCourierArr,
      };
    case ACTION.SORT_FILTER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          sortBy: action.payload,
          orderBy: action.orderBy,
        },
        order: action.newOrder,
        reqLoading: false,
      };
    case ACTION.NEW_COURIER_INFO:
      return {
        ...state,
        newCourier: action.payload,
      };
    case ACTION.NEW_COURIER_INFO_RESET:
      return {
        ...state,
        newCourier: {},
      };
    case ACTION.COURIER_DETAILS:
      return {
        ...state,
        modal: action.payload,
        onShowEdit: action.onShowEdit,
        reqLoading: false,
      };
    case ACTION.SHOW_EDIT:
      return {
        ...state,
        onShowEdit: action.payload,
        reqLoading: false,
      };
    case ACTION.LOCATION_COURIER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          location: action.payload,
        },
        reqLoading: false,
      };
    case ACTION.COURIER_LOADING:
      // 4629
      return {
        ...state,
        reqLoading: true,
      };
    case ACTION.PAGE_LIMIT_COURIER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          pageLimit: action.payload,
        },
        reqLoading: false,
      };
    case ACTION.PAGE_NO_COURIER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          pageNum: action.payload,
        },
        reqLoading: false,
      };
    case ACTION.CLEAR_NEWCOURIER:
      return {
        ...state,
        newCourier: {},
        reqLoading: false,
      };
    case ACTION.FILTER_COURIER:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          keyword: action.payload,
        },
        reqLoading: false,
      };
    case ACTION.COURIER_LOADING:
      return {
        ...state,
        reqLoading: action.payload,
      };
    case ACTION.CLEAR_COURIER_FILTER:
      return {
        ...state,
        request: [],
        request_filter: [],
        request_info: [],
        reqLoading: false,
        searchParams: {
          keyword: '',
          pageLimit: 10,
          pageNum: 1,
          sortBy: '',
          orderBy: '',
          location: '',
        },
        table: null,
        modal: null,
        onShowEdit: false,
        order: false,
        box_no: [],
        newCourier: {},
        courierDetails: '',
      };
    default:
      return state;
  }
}
